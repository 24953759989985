import React, { useState } from 'react';
import { Container, Grid, Chip, Backdrop } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Paper } from '@mui/material';
import { kinkEmojis } from './kinks';
import WhatshotIcon from '@mui/icons-material/Whatshot';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'transparent',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: 'white',
  width: '100%',
}));

const RowContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center', // Center the content horizontally
  gap: '10px'
});

const ChipWrapper = styled(Chip)(({ theme }) => ({
  backgroundColor: '#005b80',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: 'white',
  cursor: 'pointer',
  height: "5vh",
  width: "100%"
}));

const KinkChoices = ({ user, selectableKinks, handleAddKink, handleDeleteKink }) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleComponentClick = () => {
    setIsEditing(true);
  };

  const handleBackdropClick = () => {
    setIsEditing(false);
  };

  return (
    <Item elevation={0}>
      <RowContainer>
        <ChipWrapper
          icon={<WhatshotIcon style={{ color: 'white' }} />}
          label="Kinks"
          onClick={handleComponentClick}
        />
      </RowContainer>
      {isEditing && (
        <Backdrop open={isEditing} onClick={handleBackdropClick} style={{ pointerEvents: 'auto', backgroundColor: "rgba(0,0,0,0.8)", zIndex: "999" }}>
          <Container maxWidth="sm">
          <Grid container spacing={1} style={{ padding: '1em' }}>
            {selectableKinks.map((kink) => {
              return !user.kinks.includes(kink) ? (
                <Grid item key={kink}>
                  <Chip
                    label={`${kinkEmojis[kink]} ${kink.toLowerCase()}`}
                    onClick={(event) => handleAddKink(kink)(event)} 
                    size="small"
                    style={{
                      marginBottom: '1em',
                      marginLeft: '0',
                      marginRight: '8px',
                      backgroundColor: 'rgba(255, 255, 255, 0.9)', // Transparent white background
                      color: 'black' // Black text
                    }}
                  />
                </Grid>
              ) : (
                <Grid item key={kink}>
                  <Chip
                    label={`${kinkEmojis[kink]} ${kink.toLowerCase()}`}
                    size="small"
                    onDelete={handleDeleteKink(kink)} // Add the onDelete prop here
                    style={{
                      marginBottom: '1em',
                      marginLeft: '0',
                      marginRight: '8px',
                      backgroundColor: 'rgba(255, 255, 255, 0.9)', // Transparent white background
                      color: 'black' // Black text
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
          </Container>
          
        </Backdrop>
      )}
    </Item>
  );
};

export default KinkChoices;
