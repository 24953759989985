import React, { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import { useQuery, useMutation, useSubscription } from "@apollo/client";
import { QUERY_ME, QUERY_USERS, QUERY_MESSAGES } from "../../utils/queries";
import { ADD_FRIEND, DECLINE_FRIEND_REQUEST } from "../../utils/mutations";
import { GET_MESSAGES } from "../../utils/subscriptions";
import './UnmatchedUsersList.css';
import RequestsList from './RequestsList';
import RequestSubscriptions from "./RequestSubscriptions";

const UnmatchedUsersList = () => {
  const [friendRequests, setFriendRequests] = useState([]);
  const [subscriptionFriendRequests, setSubscriptionFriendRequests] = useState([]);
  const [acceptedFriendRequests, setAcceptedFriendRequests] = useState([]);
  const [addFriendMutation] = useMutation(ADD_FRIEND, {
    refetchQueries: [{ query: QUERY_ME }],
  });
  const [declineFriendRequest] = useMutation(DECLINE_FRIEND_REQUEST, {
    refetchQueries: [{ query: QUERY_ME }],
  });
  const { loading: meLoading, error: meError, data: meData, refetch: refetchMe } = useQuery(QUERY_ME);
  const { loading: usersLoading, error: usersError, data: usersData } = useQuery(QUERY_USERS);
  const { loading: messagesLoading, error: messagesError, data: messagesData } = useQuery(QUERY_MESSAGES, {
    variables: { currentUser: meData?.me?.username },
  });
  const { data: newMessageData } = useSubscription(GET_MESSAGES);
  const { refetch: refetchMessages } = useQuery(QUERY_MESSAGES, {
    variables: { currentUser: meData?.me?.username },
  });

  useEffect(() => {
    if (meData && usersData && messagesData && friendRequests) {
      const currentUser = meData.me;
      const messages = messagesData.messages;
      const users = usersData.users;
      const friendRequests = meData.me.friendRequests || []; // Add a check for friendRequests array
      // Remove the code related to unmatchedUsers
    }
  }, [meData, usersData, messagesData, friendRequests]);

  ////messaging section for unmatched///
  useEffect(() => {
    if (newMessageData && meData) {
      const currentUser = meData.me;
      const newMessage = newMessageData.messagePosted;
      if (
        newMessage.recipient === currentUser.username &&
        newMessage.username !== currentUser.username &&
        !currentUser.friends.some((friend) => friend.friendId.username === newMessage.username)
      ) {
        // Remove the code related to unmatchedUsers
      }
      refetchMessages();
    }
  }, [newMessageData, meData, usersData, refetchMessages]);


  useEffect(() => {
    if (meData && usersData) {
      const filteredFriendRequests = meData.me.friendRequests.filter(request =>
        request.from || request.friendId
      );
      let enrichedFriendRequests = filteredFriendRequests.map(request => {
        if (request.from) {
          return {
            _id: request.from._id,
            username: request.from.username,
            photoURL: request.from.photoURL,
            createdAt: request.createdAt
          };
        } else if (request.friendId) {
          const sender = usersData.users.find(user => user._id === request.friendId._id);
          return sender ? { ...request, friendId: { ...request.friendId, photoURL: sender.photoURL } } : request;
        } else {
          console.error('Request has no "from" field or "friendId" field:', request);
          return request;
        }
      });
      enrichedFriendRequests = enrichedFriendRequests.reverse();
      setFriendRequests(enrichedFriendRequests);
    }
  }, [meData, usersData]);  

  // Add this hook to refetch the meData when the component mounts
  useEffect(() => {
    refetchMe();
  }, []);

  if (meLoading || usersLoading || messagesLoading) {
    return <div>Loading...</div>;
  }

  if (meError || usersError || messagesError) {
    return (
      <div>
        Error: {meError?.message || usersError?.message || messagesError?.message}
      </div>
    );
  }

  const handleAccept = async (user, requestId) => {
    try {
      const friendId = user?._id || user?.senderId;
      if (friendId) {
        await addFriendMutation({ variables: { friendId: friendId } });
        // Remove the accepted request from your state
      } else {
        console.error("Friend ID is missing.");
      }
    } catch (error) {
      console.error("Error accepting friend:", error);
    }
  };

  const handleDecline = async (user, requestId) => {
    try {
      const friendId = user?._id || user?.senderId;
      if (friendId) {
        await declineFriendRequest({ variables: { friendId: friendId } });
        // Remove the accepted request from your state
      } else {
        console.error("Friend ID is missing.");
      }
    } catch (error) {
      console.error("Error accepting friend:", error);
    }
  };


  return (
    <Box sx={{ px: 2, display: 'flex', flexDirection: 'column', margin: '0 auto' }}>
      <RequestSubscriptions
        requests={friendRequests}
        handleAccept={handleAccept}
        handleDecline={handleDecline}
        meData={meData}
        usersData={usersData}
        addFriendMutation={addFriendMutation}
        subscriptionFriendRequests={subscriptionFriendRequests}
        setSubscriptionFriendRequests={setSubscriptionFriendRequests}
        acceptedFriendRequests={acceptedFriendRequests}
        setAcceptedFriendRequests={setAcceptedFriendRequests}
        userId={meData.me._id}
        username={meData.me.username}
      />

      <RequestsList friendRequests={friendRequests} handleAccept={handleAccept} setFriendRequests={setFriendRequests} userId={meData.me._id} username={meData.me.username} handleDecline={handleDecline}

      />

    </Box>
  );
};

export default UnmatchedUsersList;
