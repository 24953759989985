import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import './Signup.css';
import AgeProfile from "../EditProfile/ageprofile";
import Height from "../EditProfile/height";
import Weight from "../EditProfile/weight";
import KinkChoices from '../EditProfile/kinkchoices';
import Role from '../EditProfile/role';
import Ethnicity from '../EditProfile/ethnicity';
import Gender from "../EditProfile/gender";
import Position from "../EditProfile/position";
import Description from "../EditProfile/description";
import EditPhotos from '../EditProfile//editPhotos';


const StepTwo = ({ formState, onStepTwoChange }) => {
  const [photoURL, setPhotoURL] = useState([null, null, null, null]);
  const [selectableKinks, setSelectableKinks] = useState([
    'BIMBOFICATION',
    'BDSM',
    'BONDAGE',
    'BREEDING',
    'CHASTITY',
    'DOMINATION',
    'FEET',
    'FEMINIZATION',
    'GIRLFRIENDEXPERIENCE',
    'GROUP',
    'HUMILIATION',
    'HYPNO',
    'LATEX',
    'LINGERIE',
    'MAID',
    'RAW',
    'SISSIFICATION',
    'SISSYBRIDE',
    'SHOWOFF',
    'VERBAL'
  ]);

  const handleChange = (e) => {
    var value = e.target.type === "true" ? e.target.checked : e.target.value;
    const name = e.target.name;
    console.log(name, ': ', value)
    onStepTwoChange({ ...formState, [name]: value });
  };

  const handleAddKink = (kink) => (event) => {
    event.stopPropagation();
    onStepTwoChange({
      ...formState,
      kinks: [...formState.kinks, kink], // Add the selected kink to the array
    });
  };

  const handleDeleteKink = (kink) => () => {
    const updatedKinks = formState.kinks.filter((k) => k !== kink); // Remove the kink from the array
    onStepTwoChange({
      ...formState,
      kinks: updatedKinks,
    });
  };

  return (
    <Box>
      <Box>
        <Typography sx={{pl: 2}} variant="body2" color="secondary">
          * All fields are required
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
        <EditPhotos formState={formState} signUp={true} handleChange={handleChange} photoURL={photoURL} setPhotoURL={setPhotoURL} />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
        <AgeProfile
          formState={formState}
          handleChange={handleChange}
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
        <Role formState={formState} handleChange={handleChange} />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
        <Position formState={formState} handleChange={handleChange} />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
        <Ethnicity formState={formState} handleChange={handleChange} />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
        <Gender formState={formState} handleChange={handleChange} />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
        <KinkChoices
          user={formState}
          selectableKinks={selectableKinks}
          handleAddKink={handleAddKink}
          handleDeleteKink={handleDeleteKink}
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
        <Description formState={formState} handleChange={handleChange} />
      </Box>
    </Box>
  );
};

export default StepTwo;
