import React from "react";
import { setContext } from "@apollo/client/link/context";
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink, split } from "@apollo/client";
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Profile from "./components/Profile";
import Home from "./pages/Home"
import LoginPage from "./pages/LoginPage";
import Dashboards from "./pages/Dashboards";
import PageNotFound from "./components/PageNotFound";
import FileUpload from "./components/FileUpload";
import Inbox from "./pages/Inbox";
import ResetPasswordForm from "./components/ResetPasswordForm";
import Verify from "./components/Verify";
import LocationProvider from "./components/LocationProvider";
import { RecoilRoot } from 'recoil';
import PrivacyPolicy from "./components/PrivacyPolicy";
import HomeBack from './pages/Home/homeBack'
import About from "../src/pages/AboutSupportContact/about"
import Support from "../src/pages/AboutSupportContact/support"
import Contact from "../src/pages/AboutSupportContact/contact"
import Donate from "../src/pages/AboutSupportContact/donation"
import Updates from "./pages/AboutSupportContact/updates";

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("id_token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const httpLink = createHttpLink({
  uri: "/graphql",
});

const wsLink = new GraphQLWsLink(
  createClient({
    url:
      process.env.NODE_ENV === "production"
        ? "wss://femswipetest-727657538161.herokuapp.com/graphql"
        : "ws://localhost:3001/graphql",
    connectionParams: () => {
      const token = localStorage.getItem("id_token");
      return {
        authToken: token ? `Bearer ${token}` : "",
      };
    },
  })
);


const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const client = new ApolloClient({
  link: authLink.concat(splitLink),
  cache: new InMemoryCache({
    typePolicies: {
      User: {
        fields: {
          friends: {
            merge(existing = [], incoming) {
              return [...existing, ...incoming];
            },
          },
          dislikedUsers: {
            merge(existing = [], incoming) {
              return [...existing, ...incoming];
            },
          },
        },
      },
    },
  }),
});

function App() {
  return (
    <ApolloProvider client={client}>
      <RecoilRoot>
        <LocationProvider />
        <Router>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {/* <NavBar /> */}
            <div style={{ flex: 1 }}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/dashboard" element={<Dashboards />} />
                <Route path="/login" element={
                  <>
                    <HomeBack />
                    <LoginPage />
                  </>
                } />
                <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                <Route path="/about" element={<About />} />
                <Route path="/support" element={<Support />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/donate" element={<Donate />} />
                <Route path="/updates" element={<Updates />} />
                <Route path="/profile">
                  <Route path=":username" element={<Profile />} />
                  <Route path="" element={<Profile />} />
                </Route>
                <Route path="/fileupload" element={<FileUpload />} />
                <Route path="/inbox/:tabIndex?" element={<Inbox />} />
                <Route path="/reset/:resetToken" element={<ResetPasswordForm />} />
                <Route path="/verify/:verificationToken" element={<Verify />} />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </div>
          </div>
        </Router>
      </RecoilRoot>
    </ApolloProvider>
  );
}

export default App;