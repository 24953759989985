import React, { useState, useRef } from "react";
import { TextField, Typography } from "@mui/material";

const StepOne = ({ formState, onStepOneChange, stepOneError }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const fileInputRef = useRef(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    onStepOneChange({
      ...formState,
      [name]: value,
    });
  };


  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
    onStepOneChange(file);
  };

  return (
    <>
      {stepOneError && (
        <Typography color="error" variant="caption" gutterBottom>
          {stepOneError}
        </Typography>
      )}
      <TextField
        fullWidth
        className="form-input"
        label="Your username"
        name="username"
        type="username"
        id="username"
        value={formState.username}
        onChange={handleChange}
        style={{ marginTop: '5px', marginBottom: '5px' }}
      />
      <TextField
        fullWidth
        className="form-input"
        label="Your email"
        name="email"
        type="email"
        id="email"
        value={formState.email}
        onChange={handleChange}
        style={{ marginTop: '5px', marginBottom: '5px' }}
      />
      <TextField
        fullWidth
        className="form-input"
        label="******"
        name="password"
        type="password"
        id="password"
        value={formState.password}
        onChange={handleChange}
        style={{ marginTop: '5px', marginBottom: '5px' }}
      />
      <input
        ref={fileInputRef}
        accept="image/*"
        type="file"
        id="image-upload"
        style={{ display: "none" }}
        onChange={handleImageUpload}
      />
    </>
  )
};

export default StepOne;
