import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { userLocationState } from '../../recoil/atoms';
import { useMutation } from '@apollo/client';
import { EDIT_USER } from '../../utils/mutations';
import Auth from "../../utils/auth";

const LocationProvider = () => {
  const [userLocation, setUserLocation] = useRecoilState(userLocationState);
  const [editUser] = useMutation(EDIT_USER);
  const loggedIn = Auth.loggedIn();


  useEffect(() => {
    if (loggedIn) {
      const getLocation = () => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(position => {
            setUserLocation({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          }, error => {
            if (error.code === error.PERMISSION_DENIED) {
              // User denied location access, show an alert or notification
              alert('We use location services to show nearby matches. Please grant location permission for accurate results. Otherwise, your default location will be Antarctica. Please allow location or go to EditProfile and set location.');
            }
          });
        } else {
          console.error('Geolocation is not supported by this browser.');
        }
      };
      getLocation();
    }
  }, [loggedIn, setUserLocation]);

  useEffect(() => {
    if (loggedIn && userLocation.latitude && userLocation.longitude) {
      editUser({
        variables: {
          location: [userLocation.latitude, userLocation.longitude],
        },
      })
        .then(response => {
          // console.log(response);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [editUser, loggedIn, userLocation]);

  return null;
};

export default LocationProvider;
