import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { Favorite, ThumbDown } from '@mui/icons-material';
import './SwipeComponent.css';

const SwipeComponent = forwardRef(({ onSwipe, onCardLeftScreen, children, isPhotoModalOpen }, ref) => {
  const [swipeDirection, setSwipeDirection] = useState(null);
  const [boxOffset, setBoxOffset] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [isOffScreen, setIsOffScreen] = useState(false);
  const [opacity, setOpacity] = useState(1); // Initial opacity set to 1
  const startXRef = useRef(null);

  const handleStart = (clientX) => {
    if (isPhotoModalOpen) return;
    startXRef.current = clientX;
    setIsDragging(true);
  };

  const handleMove = (clientX) => {
    if (!isDragging || !startXRef.current) return;

    const diffX = clientX - startXRef.current;
    setBoxOffset(diffX);
  };

  const handleEnd = () => {
    if (!isDragging || !startXRef.current) return;
  
    setIsDragging(false);
    startXRef.current = null;
  
    const diffX = boxOffset;
  
    // Calculate the inverse of boxOffset
    const inverseDiffX = -diffX;
  
    console.log(inverseDiffX); // This will log the inverse of boxOffset
  
    handleSwipe(diffX);
  
    if (Math.abs(diffX) > 100) {
      if (diffX > 0) {
        // Swipe to the right
        setSwipeDirection('right');
        setBoxOffset(window.innerWidth);
        onSwipe('right');
  
        // Set the card to the left side (opposite side)
        setBoxOffset(-window.innerWidth);
      } else {
        // Swipe to the left
        setSwipeDirection('left');
        setBoxOffset(-window.innerWidth);
        onSwipe('left');
  
        // Set the card to the right side (opposite side)
        setBoxOffset(window.innerWidth);
      }
      
      // Set opacity to 0 to make it disappear
      setOpacity(0);

      // Use a setTimeout to allow the card to animate to the opposite side
      setTimeout(() => {
        // Reset boxOffset to 0 to slide in again
        setBoxOffset(0);
        setIsOffScreen(false);
        onCardLeftScreen();

        // Reset opacity to 1 to make it reappear
        setOpacity(1);
      }, 300); // Adjust the timing as needed
    } else {
      setBoxOffset(0);
      setIsOffScreen(false);
    }
  
    if (isOffScreen && onCardLeftScreen) {
      onCardLeftScreen();
    }
  };
  
  const handleSwipe = (diffX) => {
    if (Math.abs(diffX) > 150) {
      if (diffX > 0) {
        setSwipeDirection('right');
      } else {
        setSwipeDirection('left');
      }
    } else {
      setSwipeDirection(null);
    }
  };

  useImperativeHandle(ref, () => ({
    swipe: () => {
      return swipeDirection;
    }
  }));

  useEffect(() => { 
    setTimeout(() => {
      setIsOffScreen(false)
      setBoxOffset(0);
    }, 3000)
   
  }, [isOffScreen])

  return (
    <div
      onTouchStart={(e) => handleStart(e.touches[0].clientX)}
      onTouchMove={(e) => handleMove(e.touches[0].clientX)}
      onTouchEnd={handleEnd}
      onMouseDown={(e) => handleStart(e.clientX)}
      onMouseMove={(e) => handleMove(e.clientX)}
      onMouseUp={handleEnd}
      style={{ width: '100%', overflow: 'hidden', position: 'relative' }}
    >
      <div className="leftBox" style={{ position: 'absolute', height: '100%', width: '25%', left: '105%', backgroundColor: 'transparent', transform: `translateX(${boxOffset}px)`, transition: isDragging ? 'none' : 'transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)', opacity }}>
        <ThumbDown sx={{ fontSize: '2em', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
      </div>
      <div className={`swipe-box ${isDragging ? 'dragging' : ''}`} style={{ transform: `translateX(${boxOffset}px)`, transition: isDragging ? 'none' : 'transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)', opacity }}>
        {children}
      </div>
      <div className="leftBox" style={{ position: 'absolute', height: '100%', width: '25%', top: 0, right: '105%', backgroundColor: 'transparent', transform: `translateX(${boxOffset}px)`, transition: isDragging ? 'none' : 'transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)', opacity }}>
        <Favorite sx={{ fontSize: '2em', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
      </div>
    </div>
  );
});

export default SwipeComponent;
