import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import ClubSissyBanner from "./ClubSissyBanner.png"


export default function About() {
  const navigate = useNavigate(); // Use useNavigate instead of useHistory

  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <Box
      sx={{
        padding: '20px',
        background: 'rgba(0, 0, 0, 0.87)',
        color: 'white',
        minHeight: '100vh',
      }}
    >
      <Container maxWidth="lg" sx={{ py: 10 }}>
        <Button
          variant="outlined"
          onClick={handleGoBack}
          sx={{ color: 'white', borderColor: 'white', marginBottom: '20px' }}
        >
          Back
        </Button>
        <Typography variant="h4" sx={{ mt: 4 }}>
          Welcome to FemSwipe
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          The ultimate online platform for individuals who appreciate and embrace diversity in their dating and connection experiences. At FemSwipe, we've crafted a unique and inclusive space that celebrates the beauty of sissies, crossdressers, and their admirers.
        </Typography>
        <Typography variant="h4" sx={{ mt: 4 }}>
          Our Mission
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          FemSwipe is more than just a dating site; it's a vibrant community where like-minded individuals come together to explore connections that go beyond societal norms. Our mission is to provide a safe, welcoming, and respectful environment for everyone, regardless of their gender identity or interests.
        </Typography>
        <Typography variant="h4" sx={{ mt: 4 }}>
          Discover Your Perfect Match
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          Our innovative matching algorithm connects you with sissies, crossdressers, trans women, and their admirers who share your interests and desires. Whether you're looking for friendship, romance, or something more adventurous, FemSwipe is your gateway to meaningful connections.
        </Typography>
        <Typography variant="h4" sx={{ mt: 4 }}>
          Key Features
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          - <strong>Free Sign-Up:</strong> Joining FemSwipe is as easy as a breeze. Sign up for free and start exploring a world of possibilities.
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          - <strong>Advanced Filtering:</strong> Customize your search by specific kinks, ensuring you find individuals who truly resonate with your desires.
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          - <strong>Instant Messaging:</strong> Engage in seamless conversations with your matches through our user-friendly messaging system. Connect and get to know each other on your terms.
        </Typography>
        <Typography variant="h4" sx={{ mt: 4 }}>
          A Safe and Respectful Community
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          FemSwipe is committed to creating a safe and respectful community where all members can express themselves authentically. We have a zero-tolerance policy for harassment, discrimination, or any form of misconduct. Our team is dedicated to maintaining a secure and friendly atmosphere where you can be yourself without fear of judgment.
        </Typography>
        <Typography variant="h4" sx={{ mt: 4 }}>
          Join FemSwipe Today
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          If you're ready to embark on a journey of connection, understanding, and acceptance, FemSwipe is the perfect place to start. Sign up for free, browse profiles, and start chatting with sissies, crossdressers, trans women, and their admirers who are eager to connect with you.
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          FemSwipe is not just a dating site; it's a celebration of diversity and an exploration of authentic connections. Join us today and be part of our growing community where acceptance knows no bounds. Embrace your true self, connect with others, and let love and understanding flourish. Welcome to FemSwipe, where connections blossom!
        </Typography>
        {/* <Typography variant="h4" sx={{ mt: 4 }}>
          Sites we suggest:
        </Typography> */}
        {/* <Typography variant="body1" sx={{ mt: 2 }}>
          <a href="https://www.clubsissy.com" target="_blank" rel="noopener noreferrer">
            <img
              src={ClubSissyBanner}
              alt="Club Sissy Banner"
              style={{ maxWidth: '90%', height: 'auto' }}
            />
          </a>
          <Button></Button>
        </Typography> */}
        <Typography variant="h4" sx={{ mt: 4 }}>
          Donate
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          If you'd like to support our mission, you can donate using the following methods:
        </Typography>
        <Link to="/donate" style={{ textDecoration: 'none' }}>
          <Button variant="contained" color="primary" sx={{ mt: 2 }}>
            Donate Now
          </Button>
        </Link>
      </Container>
    </Box>
  );
}
