// analytics.js
import ReactGA from 'react-ga';

// Initialize Google Analytics with your Measurement ID
ReactGA.initialize('G-XJCJ2BJD7W');

// Optional: Set custom options
ReactGA.set({
  anonymizeIp: true, // Anonymize user's IP address
});

export default ReactGA;
