import React, { useState, useEffect, useRef } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { QUERY_USERS_BY_ROLE } from "../../utils/queries";
import { meState } from "../../recoil/atoms";
import { useRecoilValue } from "recoil";
import { Alert, Box, Button, Chip, Grid, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import UndoIcon from "@mui/icons-material/Undo";
import ShuffleIcon from '@mui/icons-material/Shuffle';
import UserCard from "../UserCard";
import getDistanceFromLatLonInKm from "../../utils/proximity";
import { REMOVE_DISLIKED_USERS, UNBLOCK_USER } from "../../utils/mutations";

const DashboardRoulette = ({ activeTab, scrollToTabContainer }) => {
  let me = useRecoilValue(meState);
  me = me.me;
  const [showAlert, setShowAlert] = useState(false);
  const [blockedUsername, setBlockedUsername] = useState("");
  const [blockedUserId, setBlockedUserId] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [activeCardIndex, setActiveCardIndex] = useState(0);
  const [isGridView, setIsGridView] = useState(true);
  const cardContainerRef = useRef(null);
  const oppositeRole = me.role === "sissy" ? "admirer" : "sissy";
  const [unblockUser] = useMutation(UNBLOCK_USER);

  const { data, refetch } = useQuery(QUERY_USERS_BY_ROLE, {
    variables: { role: oppositeRole },
  });

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleUnblockUser = async (userId) => {
    try {
      const { data } = await unblockUser({ variables: { userId: userId } });
      if (data.unblockUser.success) {
        console.log("User unblocked successfully.");
        await refetch(); // Refetch the data after unblocking the user
        setShowAlert(false); // Hide the alert
      } else {
        console.log("Error unblocking user.");
      }
    } catch (error) {
      console.error("Error unblocking user:", error);
    }
  };

  const [removeDislikedUsers] = useMutation(REMOVE_DISLIKED_USERS);

  const handleRemoveDislikedUsers = async () => {
    try {
      await removeDislikedUsers();
      // Save the current tab index in local storage before reloading
      localStorage.setItem("activeTab", activeTab);
      window.location.reload();
    } catch (err) {
      console.error(err);
    }
  };

  const handleSwipe = (userId) => {
    const container = cardContainerRef.current;
    const cardHeight = container.offsetHeight;
    const newIndex = Math.round(container.scrollTop / cardHeight);

    const cards = container.children;
    cards[newIndex].style.opacity = 0;

    cards[newIndex].addEventListener("transitionend", () => {
      setFilteredUsers((prevUsers) =>
        prevUsers.filter((user) => user._id !== userId)
      );
    });
  };

  // Function to fetch fresh user data
  const fetchFreshUserData = async () => {
    try {
      await refetch(); // Refetch the data
    } catch (error) {
      console.error("Error fetching fresh user data:", error);
    }
  };

  const handleRerun = async () => {
    // Fetch fresh user data when the shuffle button is clicked
    await fetchFreshUserData();

    if (data) {
      const freshUsers = data.usersByRole.filter((user) => user._id !== me._id);

      // Shuffle the fresh user data
      const shuffledUsers = freshUsers.slice(); // Create a copy
      for (let i = shuffledUsers.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledUsers[i], shuffledUsers[j]] = [shuffledUsers[j], shuffledUsers[i]];
      }

      const randomUsers = shuffledUsers.slice(0, 6); // Adjust the number as needed
      setFilteredUsers(randomUsers.length > 0 ? randomUsers : []);

      // Scroll to the top of the card container
      const cardContainer = cardContainerRef.current;
      if (cardContainer) {
        cardContainer.scrollTo({ top: 0, behavior: "smooth" });
      }

      scrollToTabContainer();
    }
  };

  const toggleView = () => {
    setIsGridView((prevIsGridView) => !prevIsGridView);
  };

  // Render method for the list view
  const renderListView = () => (
    <div className="swipe-container">
      <div className="card-container" ref={cardContainerRef}>
        {showAlert && (
          <Alert
            icon={false}
            sx={{ mb: 2 }}
            variant="outlined"
            severity="info"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setShowAlert(false);
                  handleUnblockUser(blockedUserId); // Pass blockedUserId as an argument
                }}
              >
                <UndoIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {blockedUsername} is blocked
          </Alert>
        )}
        {filteredUsers.map((user, index) => (
          <UserCard
            key={user._id}
            user={user}
            fullSize={index === activeCardIndex}
            onSwipe={() => handleSwipe(user._id)}
            style={{ marginBottom: "20px" }}
          />
        ))}
      </div>
    </div>
  );

  const renderGridView = () => {
    const usersToShow = isSmallScreen ? 6 : 4;
  
    return (
      <Box sx={{ width: "100%", padding: "0px 10px" }}>
        <Grid container spacing={2}>
          {filteredUsers.slice(0, usersToShow).map((user, index) => (
            <Grid item lg={3} md={3} sm={4} xs={4} key={user._id}>
              <UserCard
                key={user._id}
                user={user}
                fullSize={index === activeCardIndex}
                onSwipe={() => handleSwipe(user._id)}
                isGridView={isGridView}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };
  

  useEffect(() => {
    if (data) {
      const filteredUsers = data.usersByRole.filter(
        (user) => user._id !== me._id
      );
      const usersWithDistance = filteredUsers.map((user) => {
        const [aLatitude, aLongitude] = me.location;
        const [bLatitude, bLongitude] = user.location;
        const distanceInKm = getDistanceFromLatLonInKm(
          parseFloat(aLatitude),
          parseFloat(aLongitude),
          parseFloat(bLatitude),
          parseFloat(bLongitude)
        );
        const distanceInMiles = Math.round(distanceInKm / 1.60934);
        return {
          ...user,
          distance: distanceInMiles,
        };
      });
      for (let i = usersWithDistance.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [usersWithDistance[i], usersWithDistance[j]] = [usersWithDistance[j], usersWithDistance[i]];
      }
      const randomUsers = usersWithDistance.slice(0, 6);
      setFilteredUsers(randomUsers);
    }
  }, [data, me]);

  return (
    <>
      <Box sx={{ flexGrow: 1, mt: 5 }}>
        <Grid container={true} justifyContent="end">
          <div style={{ display: "flex", alignItems: "center", gap: "10px", paddingBottom: 15, paddingRight: '10px', justifyContent: 'end' }}>
            <Chip
              label={`Switch to ${isGridView ? "List View" : "Grid View"}`}
              color="primary"
              onClick={toggleView}
            />
          </div>
        </Grid>
        <Grid container={true} spacing={1} justifyContent="center">
          {isGridView ? (
            renderGridView()
          ) : (
            <Grid sx={{ pt: 0 }} lg={4} md={5} sm={6} xs={12}>
              {renderListView()}
            </Grid>
          )}
        </Grid>
        <Grid container={true} spacing={1} justifyContent="center">
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <div className="swipe-container" style={{ marginTop: "14px" }}>
              {filteredUsers.length === 0 ? (
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <p>No more users to show.</p>
                  <button onClick={handleRemoveDislikedUsers} style={{ marginBottom: "20px" }}>Start Fresh</button>
                </div>
              ) : (
                <div style={{ width: "100%" }}>
                  <div className="card-container" style={{ marginTop: "0px" }} ref={cardContainerRef}>

                  </div>
                  <Box sx={{ display: 'flex', justifyContent: 'center', py: 10, backgroundColor: '#0d0d0d' }}>
                    <Button
                      onClick={handleRerun}
                      variant="contained"
                      color={me.role === 'admirer' ? 'primary' : 'secondary'}
                      sx={{
                        py: 2,
                        transition: 'transform 1s',
                        '&:hover': {
                          transform: 'scale(1.05)', // Increase the size on hover
                        },
                      }}
                      endIcon={<ShuffleIcon />}
                    >
                      Shuffle
                    </Button>
                  </Box>
                </div>
              )}
            </div>

          </Grid>
          {!filteredUsers.length && (
            <button style={{ marginBottom: "54px", display: "none" }} onClick={handleRerun}>
              Rerun
            </button>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default DashboardRoulette;
