import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';

const Ethnicities = {
  ALL: "",
  WHITE: "White",
  BLACK: "Black",
  PACIFIC_ISLANDER: "Pacific Islander",
  INDIAN: "Indian",
  ASIAN: "Asian",
  LATIN_HISPANIC: "Latin/Hispanic",
  AMERICAN_INDIAN: "American Indian",
  OTHER: "Other",
};

const EthnicityFilter = ({ selectedEthnicity, onEthnicitySelect }) => {
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [valueGroups, setValueGroups] = useState({ ethnicity: selectedEthnicity || 'ALL' });
  const [optionGroups] = useState({ ethnicity: Object.values(Ethnicities) });

  useEffect(() => {
    if (valueGroups.ethnicity !== 'ALL') {
      setIsPickerOpen(false);
    }
  }, [valueGroups.ethnicity]);

  const handleChange = (name, value) => {
    setValueGroups({ ...valueGroups, [name]: value });
    onEthnicitySelect(value === 'ALL' ? '' : value);
    setIsPickerOpen(false); // Close the list after selection
  };

  const handleBoxClick = () => {
    setIsPickerOpen(!isPickerOpen); // Toggle the list visibility
  };

  const handleClearClick = (event) => {
    event.stopPropagation();
    setValueGroups({ ethnicity: 'ALL' });
    onEthnicitySelect('');
    setIsPickerOpen(false);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#8e0b8a",
          color: "white",
          padding: "4px",
          borderRadius: "4px",
          marginBottom: "10px",
          fontSize: "14px",
        }}
      >
        Ethnicity
      </div>
      <div onClick={handleBoxClick}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            bgcolor: "#2e84b4",
            color: "white",
            padding: "8px",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          <span>They are: {valueGroups.ethnicity !== 'ALL' ? valueGroups.ethnicity : ''}</span>
          {valueGroups.ethnicity !== 'ALL' && (
            <ClearIcon
              style={{ marginLeft: "5px", cursor: "pointer" }}
              onClick={handleClearClick}
            />
          )}
        </Box>
      </div>
      {isPickerOpen && (
        <div
          style={{
            overflow: "auto",
            maxHeight: "150px",
            marginTop: "10px",
            textAlign: "center",
          }}
        >
          <ul style={{ listStyle: "none", padding: "0" }}>
            {optionGroups.ethnicity.map((option) => (
              <li
                key={option}
                onClick={() => handleChange("ethnicity", option)}
                style={{
                  cursor: "pointer",
                  backgroundColor: valueGroups.ethnicity === option ? "#eee" : "transparent",
                  fontSize: "12px",
                  lineHeight: "1.5",
                  padding: "4px",
                  borderRadius: "4px",
                  marginBottom: "4px",
                }}
              >
                {option}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default EthnicityFilter;