import React, { useState, useEffect } from 'react';
import { useQuery, useSubscription, useMutation, useApolloClient } from '@apollo/client';
import { QUERY_USERS_BY_ID, QUERY_ME, LAST_RECEIVED_MESSAGES } from '../../utils/queries';
import { GET_MESSAGES } from '../../utils/subscriptions';
import { createChannelId } from '../../utils/helperFunction';
import { Avatar, Chip, Divider, List, ListItem, ListItemText } from "@mui/material"
import moment from 'moment';
import { FiberManualRecord, Woman, Man, ChatBubbleOutline } from '@mui/icons-material';

import StatusCircle from "../StatusCircle";
import { HIDE_FRIEND, UPDATE_ALL_USER_MESSAGES } from '../../utils/mutations'

const MatchList = (props) => {
  const client = useApolloClient();
  const [matchedProfiles, setMatchedProfiles] = useState(null);
  const [lastMessages, setLastMessages] = useState({});
  const [usernamesById, setUsernamesById] = useState({});
  const [myUsername, setMyUsername] = useState(null);
  const [isSelectMode, setIsSelectMode] = useState(false);
  const [selectedConversations, setSelectedConversations] = useState([]);

  const [hideFriend] = useMutation(HIDE_FRIEND);
  const messageIds = props.matches?.map(item => item._id); // Extracting only _id values

  const filteredIds = (props.matches || [])
    .filter(match => match && match.friendId && match.friendId._id && match.friendId._id !== props.myID)
    .map(match => match && match.friendId && match.friendId._id);

  // const { data } = useQuery(QUERY_USERS_BY_ID, {
  //   variables: {
  //     _id: filteredIds,
  //   },
  //   fetchPolicy: 'network-only',
  // });

  const { data } = useQuery(QUERY_USERS_BY_ID, {
    variables: {
      _id: messageIds,
    },
    fetchPolicy: 'network-only',
  });

  console.log(data)

  const { data: me } = useQuery(QUERY_ME); // old query runs everytime all over
  // let me = useRecoilValue(meState);

  let channelIds = [];
  if (props.matchedWithUsername) {
    try {
      channelIds = props.matchedWithUsername.map(username =>
        createChannelId(props.passUsername, username)
      );
    } catch (error) {
      console.error('Error while mapping matchedWithUsernames:', error);
    }
  } else {
    console.error('matchedWithUsernames is undefined');
  }

  const { data: subscriptionData } = useSubscription(GET_MESSAGES, {
    variables: {
      channelId: channelIds.join(),
    },
  });

  const { data: messagesData, refetch: refetchMessages } = useQuery(LAST_RECEIVED_MESSAGES, {
    variables: {
      username: myUsername,
      recipient: '',
    },
    skip: !myUsername
  });

  useEffect(() => {
    if (myUsername) {
      matchedProfiles?.usersById.forEach((user) => {
        if (user.username !== myUsername) {
          refetchMessages({ username: myUsername, recipient: user.username });
        }
      });
    }
  }, [myUsername, matchedProfiles, refetchMessages]);

  useEffect(() => {
    // if (me && !meLoading) { no longer running query so meloading not necessary
    if (me) {
      setMyUsername(me.me.username);
    }
  }, [me]);

  useEffect(() => {
    if (data) {
      const userIdToUsername = data.usersById.reduce((acc, user) => {
        acc[user._id] = user.username;
        return acc;
      }, {});
      setUsernamesById(userIdToUsername);
      setMatchedProfiles(data);

      data.usersById.forEach((user) => {
        if (user.username !== myUsername) {
          refetchMessages({ username: myUsername, recipient: user.username });
        }
      });
    }
  }, [data, refetchMessages, myUsername]);

  useEffect(() => {
    if (subscriptionData) {
      const message = subscriptionData.messagePosted;
      const partner = message.username === myUsername ? message.recipientId : message.username;

      setLastMessages((prevLastMessages) => {
        const updatedLastMessages = {
          ...prevLastMessages,
          [partner]: {
            username: usernamesById[partner],
            text: message.text,
            createdAt: message.createdAt,
          },
        };

        setMatchedProfiles((prevMatchedProfiles) => {
          const updatedUsers = [...prevMatchedProfiles.usersById];
          const index = updatedUsers.findIndex((user) => user._id === partner);
          if (index !== -1) {
            const currentUser = updatedUsers[index];
            updatedUsers.splice(index, 1);
            updatedUsers.unshift(currentUser);
          }
          return {
            ...prevMatchedProfiles,
            usersById: updatedUsers,
          };
        });

        localStorage.setItem('lastMessages', JSON.stringify(updatedLastMessages));

        return updatedLastMessages;
      });
    }
  }, [subscriptionData, myUsername, usernamesById, setLastMessages, setMatchedProfiles]);

  useEffect(() => {
    if (data && messagesData) {

      const lastMessagesData = messagesData.lastReceivedMessages.reduce((acc, message) => {
        const partner = message.username === myUsername ? message.recipient : message.username;
        const lastMessage = {
          username: partner,
          text: message.text,
          createdAt: message.createdAt,
          read: message.read,
        };
        return {
          ...acc,
          [partner]: lastMessage,
        };
      }, {});

      data.usersById.forEach((user) => {
        if (!lastMessagesData[user.username]) {
          lastMessagesData[user.username] = {
            username: user.username,
            text: '',
            createdAt: '',
            read: true,
          };
        }
      });

      setLastMessages(lastMessagesData);
    }
  }, [data, messagesData, myUsername, setLastMessages, usernamesById]);

  const toggleSelectMode = () => {
    setIsSelectMode(!isSelectMode);
    setSelectedConversations([]);
  };

  const handleCheckboxChange = (conversationId) => {
    if (selectedConversations.includes(conversationId)) {
      setSelectedConversations((prevSelectedConversations) =>
        prevSelectedConversations.filter((id) => id !== conversationId)
      );
    } else {
      setSelectedConversations((prevSelectedConversations) => [
        ...prevSelectedConversations,
        conversationId,
      ]);
    }
  };

  const handleUserClick = (user) => {
    if (isSelectMode) {
      handleCheckboxChange(user._id);
    } else {
      const partner = user.username;
      setLastMessages((prevLastMessages) => {
        return {
          ...prevLastMessages,
          [partner]: {
            ...prevLastMessages[partner],
            read: true,
          },
        };
      });
      props.userNameClick(user.username, user.photoURL);
    }
  };

  const handleDeleteConversations = async () => {

    setSelectedConversations((prevSelectedConversations) => {
      const updatedConversations = [...prevSelectedConversations];

      setLastMessages((prevLastMessages) => {
        const updatedLastMessages = { ...prevLastMessages };
        updatedConversations.forEach((conversationId) => {
          const partner = usernamesById[conversationId];
          delete updatedLastMessages[partner];
          hideFriend({ variables: { friendId: conversationId } });
        });
        return updatedLastMessages;
      });

      setMatchedProfiles((prevMatchedProfiles) => {
        const updatedUsers = [...prevMatchedProfiles.usersById];
        updatedConversations.forEach((conversationId) => {
          const index = updatedUsers.findIndex((user) => user._id === conversationId);
          if (index !== -1) {
            updatedUsers.splice(index, 1);
          }
        });
        return {
          ...prevMatchedProfiles,
          usersById: updatedUsers,
        };
      });

      return [];
    });
    await Promise.all(
      selectedConversations.map(async (conversationId) => {
        await hideFriend({ variables: { friendId: conversationId } });
      })
    );

    selectedConversations.forEach((conversationId) => {
      client.cache.evict({ id: `User:${conversationId}` });
      client.cache.gc();
    });
  };

  const getSortedUsers = () => {
    if (!matchedProfiles || !lastMessages) return [];

    const visibleUsers = (matchedProfiles?.usersById || []).filter(user => {
      // Find the corresponding match object for the user
      const matchObj = props.matches.find(match => match?._id === user?._id);

      // If match object is not found or isHidden is true, exclude the user
      if (!matchObj || matchObj.isHidden) {
        return false;
      }
      return true;
    });
    // Combine users with messages and friends into one array
    const usersWithActivity = visibleUsers.map(user => {
      const lastMessageTime = parseInt(lastMessages?.[user.username]?.createdAt || 0);
      const friendRelationshipDate = props.matches.find(match => match?._id === user._id)?.createdAt || 0;

      return {
        user,
        activityDate: Math.max(lastMessageTime, friendRelationshipDate), // Take the maximum of message and friend date
      };
    });

    // Sort the combined array based on the activityDate in descending order
    usersWithActivity.sort((a, b) => {
      return b.activityDate - a.activityDate;
    });

    // Extract the sorted users from the combined array
    const sortedUsers = usersWithActivity.map(item => item.user);

    return sortedUsers;
  };

  // Call mutation for marking all messages as read //
  const [updateAllUserMessages] = useMutation(UPDATE_ALL_USER_MESSAGES);

  // Function to mark all messages as read //
  const markAllMessagesAsRead = async () => {
    try {
      // Call the mutation to mark all messages as read
      const result = await updateAllUserMessages({
        variables: {
          userId: me.me._id, // Provide the user ID of the current user
          read: true, // Set "read" status to true
        },
      });

      if (result.data.updateAllUserMessages.success) {
        console.log('All messages marked as read.');
      } else {
        console.error('Failed to mark messages as read:', result.data.updateAllUserMessages.message);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };


  useEffect(() => {
    const storedLastMessages = localStorage.getItem('lastMessages');
    if (storedLastMessages) {
      setLastMessages(JSON.parse(storedLastMessages));
    } else {
      setLastMessages({});
    }
  }, []);

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
        <div>
          <Chip
            label="Mark All Messages as Read"
            onClick={markAllMessagesAsRead}
            color="secondary"
            size="small"
            clickable
            icon={<ChatBubbleOutline />}
          />
        </div>
        <div>
          <Chip
            label={isSelectMode ? 'Done' : 'Select'}
            onClick={toggleSelectMode}
            color="primary"
            size="small"
            clickable
          />
          {isSelectMode && (
            <Chip
              label="Delete"
              onClick={handleDeleteConversations}
              color="error"
              size="small"
              clickable
            />
          )}
        </div>
      </div>
      {getSortedUsers().map((user, index, arr) => (
        <React.Fragment key={user._id}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
              paddingLeft: '5%',
            }}
            onClick={() => handleUserClick(user)}
          >
            {isSelectMode && (
              <input
                type="checkbox"
                checked={selectedConversations.includes(user._id)}
                onChange={() => handleCheckboxChange(user._id)}
              />
            )}
            <div style={{ width: '60px', height: '60px', position: 'relative', lineHeight: 0.5 }}>
              <Avatar sx={{ width: '60px', height: '60px', background: user.role === 'admirer' ? '#005b80' : '#9c27b0', }}
                src={user.photoURL[0]}>
                {user.role === 'admirer' ? <Man sx={{ color: '#1785b2', mt: 4, fontSize: '50px' }} /> : <Woman sx={{ color: '#bd59ce', mt: 4, fontSize: '50px' }} />}
              </Avatar>
              <div style={{ position: 'absolute', bottom: '0', right: '0', marginBottom: "2px", marginRight: "3px" }}>
                <StatusCircle lastActive={user.lastActive} />
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
              <span style={{ fontSize: '14px', fontWeight: 'bold' }}>{user.username}</span>
              {!lastMessages[user.username] || !lastMessages[user.username].text ? (
                <span style={{ fontSize: '12px' }}>No messages yet..</span>
              ) : lastMessages[user.username].text.startsWith('https://firebasestorage.googleapis.com') ? (
                <span style={{ fontSize: '12px' }}>Received an image</span>
              ) : (
                <span style={{ fontSize: '12px' }}>{lastMessages[user.username].text}</span>
              )}
            </div>
            {lastMessages[user.username]?.text && (
              <span style={{ fontSize: '10px', marginLeft: 'auto', paddingRight: '2%' }}>
                {lastMessages[user.username]?.createdAt &&
                  moment(parseInt(lastMessages[user.username].createdAt)).fromNow()}
                {!lastMessages[user.username]?.read && (
                  <FiberManualRecord
                    sx={{ fontSize: '12px', color: '#9c27b0', marginLeft: '5px' }}
                  />
                )}
              </span>
            )}
          </div>
          {index !== arr.length - 1 && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Divider sx={{ bgcolor: 'rgba(255,255,255,0.5)', width: '100%', my: 1, opacity: 0 }} />
            </div>
          )}
        </React.Fragment>
      ))}
      {getSortedUsers().length === 0 && (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
          <span style={{ fontSize: '14px', fontWeight: 'bold', color: 'white' }}>No Messages Yet</span>
        </div>
      )}
    </div>
  );
  
};

export default MatchList;