import React, { useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { RESET_PASSWORD } from '../../utils/mutations';

const ResetPasswordForm = () => {
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');

  const { resetToken } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const getEmailFromURL = () => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get('email');
  };

  const email = getEmailFromURL();

  const [resetPassword] = useMutation(RESET_PASSWORD);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // eslint-disable-next-line no-unused-vars
      const { data } = await resetPassword({ variables: { resetToken, newPassword, email } });
      setMessage('Password reset successful');
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (error) {
      console.error(error);
      setMessage('An error occurred');
    }
  };

  return (
    <div>
      <h4>Reset Password</h4>
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          placeholder="New Password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <button type="submit">Reset Password</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default ResetPasswordForm;
