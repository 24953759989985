import React, { useState, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';

const Genders = {
  ALL: "",
  MALE: "Male",
  FEMALE: "Female",
  SISSY: "Sissy",
  CROSSDRESSER: "Crossdresser",
  TRANS_WOMAN: "Trans Woman",
  NON_BINARY: "Non Binary",
};

const GenderFilter = ({ selectedGender, onGenderSelect }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Determine if it's a mobile device
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [valueGroups, setValueGroups] = useState({ gender: selectedGender || 'ALL' });
  const [optionGroups] = useState({ gender: Object.values(Genders) });
  const scrollableContainerRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (valueGroups.gender !== 'ALL') {
      setIsPickerOpen(false);
    }
  }, [valueGroups.gender]);

  const handleChange = (name, value) => {
    setValueGroups({ ...valueGroups, [name]: value });
    onGenderSelect(value === 'ALL' ? '' : value);
    setIsPickerOpen(false); // Close the list after selection
  };

  const handleBoxClick = () => {
    setIsPickerOpen(!isPickerOpen); // Toggle the picker visibility
  };

  const handleClearClick = (event) => {
    event.stopPropagation();
    setValueGroups({ gender: 'ALL' });
    onGenderSelect('');
    setIsPickerOpen(false);
  };

  const handleScroll = () => {
    const scrollableContainer = scrollableContainerRef.current;

    if (scrollableContainer) {
      const { scrollTop, clientHeight, scrollHeight } = scrollableContainer;

      if (scrollTop === 0) {
        scrollableContainer.scrollTop = 1; // Scroll down by 1px
      } else if (scrollTop + clientHeight >= scrollHeight) {
        scrollableContainer.scrollTop -= 1; // Scroll up by 1px
      }
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#8e0b8a",
          color: "white",
          padding: "4px",
          borderRadius: "4px",
          marginBottom: "10px",
          fontSize: "14px",
        }}
      >
        Gender
      </div>
      <div onClick={handleBoxClick}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            bgcolor: "#2e84b4",
            color: "white",
            padding: "8px",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          <span>They are a: {valueGroups.gender !== 'ALL' ? valueGroups.gender : ''}</span>
          {valueGroups.gender !== 'ALL' && (
            <ClearIcon
              style={{ marginLeft: "5px", cursor: "pointer" }}
              onClick={handleClearClick}
            />
          )}
        </Box>
      </div>
      {isMobile ? (
        <div style={{ display: isPickerOpen ? 'block' : 'none', textAlign: "center" }}>
          <ul style={{ listStyle: "none", padding: "0" }}>
            {optionGroups.gender.map((option) => (
              <li
                key={option}
                onClick={() => handleChange("gender", option)}
                style={{
                  cursor: "pointer",
                  backgroundColor: valueGroups.gender === option ? "#eee" : "transparent",
                  fontSize: "12px",
                  lineHeight: "1.5",
                  padding: "4px",
                  borderRadius: "4px",
                  marginBottom: "4px",
                }}
              >
                {option}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div
          ref={scrollableContainerRef}
          style={{
            display: isPickerOpen ? 'block' : 'none',
            overflow: "auto",
            maxHeight: "200px",
            marginTop: "10px",
            textAlign: "center",
          }}
          onScroll={handleScroll}
        >
          <ul style={{ listStyle: "none", padding: "0" }}>
            {optionGroups.gender.map((option) => (
              <li
                key={option}
                onClick={() => handleChange("gender", option)}
                style={{
                  cursor: "pointer",
                  backgroundColor: valueGroups.gender === option ? "#eee" : "transparent",
                  fontSize: "15px",
                  lineHeight: "1.5",
                  padding: "4px",
                  borderRadius: "4px",
                  marginBottom: "4px",
                }}
              >
                {option}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default GenderFilter;