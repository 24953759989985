import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Global } from '@emotion/react';
import { grey } from '@mui/material/colors';
import {
  Box,
  Typography,
  Drawer,
  SwipeableDrawer,
  Button,
  CssBaseline,
  Grid,
  Divider,
  CardMedia,
  Modal,
} from '@mui/material';
import { kinkEmojis } from './kinks';
import Chip from '@mui/material/Chip';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import './ChatPartnerProfile.css';

const drawerBleeding = 106;
const NavbarHeight = 64; // or whatever height you choose

const Root = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor:
    theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? 'black' : grey[800],
  minHeight: `48px`, // Added minHeight property
}));

const Puller = styled(Box)(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  bottom: NavbarHeight + theme.spacing(4),
  left: 0,
  right: 0,
  height: '20vh',
  backgroundColor: 'black',
  cursor: 'pointer',
  zIndex: 999, // Add a higher z-index value
  '&:hover': {
    backgroundColor: theme.palette.mode === 'light' ? grey[100] : grey[800],
  },
}));

const ChatPartnersProfile = ({ chatPartnerData }) => {
  const [open, setOpen] = React.useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = React.useState(null);
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 1024);

  // Function to open the drawer on mobile
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  // Function to open the drawer on desktop
  const handleOpenDrawer = () => {
    setOpen(true);
  };

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedImageIndex(null);
  };

  const handlePreviousImage = () => {
    if (selectedImageIndex > 0) {
      setSelectedImageIndex(selectedImageIndex - 1);
    }
  };

  const handleNextImage = () => {
    if (selectedImageIndex < chatPartnerData.photoURL.length - 1) {
      setSelectedImageIndex(selectedImageIndex + 1);
    }
  };

  React.useEffect(() => {
    // Update the isMobile state when the window is resized
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const birthDate = new Date(chatPartnerData.age);
  const ageDiffMs = Date.now() - birthDate.getTime();
  const ageDate = new Date(ageDiffMs); // miliseconds from epoch
  const age = Math.abs(ageDate.getUTCFullYear() - 1970);

  if (!chatPartnerData) {
    return <div>Loading...</div>;
  }


  return (
    <Root
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        backgroundImage: `url(${chatPartnerData.photoURL})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: 'black'
      }}
    >
      <CssBaseline />
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: `calc(100% - ${drawerBleeding}px)`,
            overflow: 'visible',
          },
        }}
      />

      {isMobile ? ( // Render the SwipeableDrawer on mobile
        <SwipeableDrawer
          anchor="bottom"
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          swipeAreaWidth={drawerBleeding}
          disableSwipeToOpen={false}
          disableBackdropTransition={true}
          disableDiscovery={true}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <StyledBox
            sx={{
              position: 'absolute',
              top: -45,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              visibility: 'visible',
              right: 0,
              left: 0,
            }}
          >
            <Box onClick={toggleDrawer(true)}>
              <Puller />
            </Box>
            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: "15px" }}>
              <Button
                sx={{ width: '3px', height: '3px', bgcolor: 'white', opacity: 0.8 }}
                onClick={() => setOpen(!open)}
              />
            </div>
          </StyledBox>
          <StyledBox
            sx={{
              px: 2,
              pb: 2,
              height: 'auto',
              // marginTop: open ? '-8vh' : 0, // Add marginTop property
              // marginBottom: "64px",
              overflow: "scroll"
            }}
          >
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight="bold" color="white">
                Age:
              </Typography>
              <Typography variant="subtitle1" color="rgba(255,255,255,0.8)">
                {age}
              </Typography>
            </Grid>
            <Divider sx={{ bgcolor: 'rgba(255,255,255,0.5)', my: 1 }} />
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight="bold" color="white">
                Height:
              </Typography>
              <Typography variant="subtitle1" color="rgba(255,255,255,0.8)">
                {chatPartnerData.height}
              </Typography>
            </Grid>
            <Divider sx={{ bgcolor: 'rgba(255,255,255,0.5)', my: 1 }} />
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight="bold" color="white">
                Weight:
              </Typography>
              <Typography variant="subtitle1" color="rgba(255,255,255,0.8)">
                {chatPartnerData.weight}
              </Typography>
            </Grid>
            <Divider sx={{ bgcolor: 'rgba(255,255,255,0.5)', my: 1 }} />
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight="bold" color="white">
                Ethnicity:
              </Typography>
              <Typography variant="subtitle1" color="rgba(255,255,255,0.8)">
                {chatPartnerData.ethnicity}
              </Typography>
            </Grid>
            <Divider sx={{ bgcolor: 'rgba(255,255,255,0.5)', my: 1 }} />
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight="bold" color="white">
                Role:
              </Typography>
              <Typography variant="subtitle1" color="rgba(255,255,255,0.8)">
                {chatPartnerData.role}
              </Typography>
            </Grid>
            <Divider sx={{ bgcolor: 'rgba(255,255,255,0.5)', my: 1 }} />
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight="bold" color="white">
                Gender:
              </Typography>
              <Typography variant="subtitle1" color="rgba(255,255,255,0.8)">
                {chatPartnerData.gender}
              </Typography>
            </Grid>
            <Divider sx={{ bgcolor: 'rgba(255,255,255,0.5)', my: 1 }} />
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight="bold" color="white">
                Position:
              </Typography>
              <Typography variant="subtitle1" color="rgba(255,255,255,0.8)">
                {chatPartnerData.position}
              </Typography>
            </Grid>
            <Divider sx={{ bgcolor: 'rgba(255,255,255,0.5)', my: 1 }} />
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight="bold" color="white">
                Kinks:
              </Typography>
              <Typography sx={{ mt: 1, mb: 1 }}>
                {chatPartnerData.kinks.map((kink) => (
                  <Chip
                    key={kink}
                    sx={{ mr: 1, mb: 1, backgroundColor: 'rgba(255, 255, 255, 0.7)', color: 'black' }}
                    label={
                      <>
                        {kinkEmojis[kink]} {kink}
                      </>
                    }
                  />
                ))}
              </Typography>
            </Grid>
            <Divider sx={{ bgcolor: 'rgba(255,255,255,0.5)', my: 1 }} />
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight="bold" color="white">
                Description:
              </Typography>
              <Typography variant="subtitle1" color="rgba(255,255,255,0.8)">
                {chatPartnerData.description}
              </Typography>
            </Grid>
            <Divider sx={{ bgcolor: 'rgba(255,255,255,0.5)', my: 1 }} />
            <Grid container item xs={12} sx={{ padding: '8px 16px' }}>
              {chatPartnerData.photoURL.map((url, index) => (
                url && (
                  <Grid item xs={3} key={index}>
                    <CardMedia
                      component="img"
                      height="100"
                      image={url}
                      alt={`Image ${index + 1}`}
                      onClick={() => handleImageClick(index)}
                      style={{ cursor: 'pointer' }}
                    />
                  </Grid>
                )
              ))}
            </Grid>

          </StyledBox>
        </SwipeableDrawer>
      ) : (
        // Render a button to open the drawer on mobile
        // Render the regular Drawer on desktop
        <Drawer anchor="bottom" open={open} onClose={toggleDrawer(false)}>
          <StyledBox
            sx={{
              position: 'absolute',
              top: -drawerBleeding,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              visibility: 'visible',
              right: 0,
              left: 0,
            }}
          >
            <Box onClick={toggleDrawer(true)}>
              <Puller />
            </Box>
            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: "15px" }}>
              <Button
                sx={{ width: '3px', height: '3px', bgcolor: 'white', opacity: 0.8 }}
                onClick={() => setOpen(!open)}
              />
            </div>
          </StyledBox>
          <StyledBox
            sx={{
              px: 2,
              pb: 2,
              height: 'auto',
              // marginTop: open ? '-8vh' : 0, // Add marginTop property
              // marginBottom: "64px",
              overflow: "scroll"
            }}
          >
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight="bold" color="white">
                Age:
              </Typography>
              <Typography variant="subtitle1" color="rgba(255,255,255,0.8)">
                {age}
              </Typography>
            </Grid>
            <Divider sx={{ bgcolor: 'rgba(255,255,255,0.5)', my: 1 }} />
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight="bold" color="white">
                Height:
              </Typography>
              <Typography variant="subtitle1" color="rgba(255,255,255,0.8)">
                {chatPartnerData.height}
              </Typography>
            </Grid>
            <Divider sx={{ bgcolor: 'rgba(255,255,255,0.5)', my: 1 }} />
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight="bold" color="white">
                Weight:
              </Typography>
              <Typography variant="subtitle1" color="rgba(255,255,255,0.8)">
                {chatPartnerData.weight}
              </Typography>
            </Grid>
            <Divider sx={{ bgcolor: 'rgba(255,255,255,0.5)', my: 1 }} />
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight="bold" color="white">
                Ethnicity:
              </Typography>
              <Typography variant="subtitle1" color="rgba(255,255,255,0.8)">
                {chatPartnerData.ethnicity}
              </Typography>
            </Grid>
            <Divider sx={{ bgcolor: 'rgba(255,255,255,0.5)', my: 1 }} />
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight="bold" color="white">
                Role:
              </Typography>
              <Typography variant="subtitle1" color="rgba(255,255,255,0.8)">
                {chatPartnerData.role}
              </Typography>
            </Grid>
            <Divider sx={{ bgcolor: 'rgba(255,255,255,0.5)', my: 1 }} />
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight="bold" color="white">
                Gender:
              </Typography>
              <Typography variant="subtitle1" color="rgba(255,255,255,0.8)">
                {chatPartnerData.gender}
              </Typography>
            </Grid>
            <Divider sx={{ bgcolor: 'rgba(255,255,255,0.5)', my: 1 }} />
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight="bold" color="white">
                Position:
              </Typography>
              <Typography variant="subtitle1" color="rgba(255,255,255,0.8)">
                {chatPartnerData.position}
              </Typography>
            </Grid>
            <Divider sx={{ bgcolor: 'rgba(255,255,255,0.5)', my: 1 }} />
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight="bold" color="white">
                Kinks:
              </Typography>
              <Typography sx={{ mt: 1, mb: 1 }}>
                {chatPartnerData.kinks.map((kink) => (
                  <Chip
                    key={kink}
                    sx={{ mr: 1, mb: 1, backgroundColor: 'rgba(255, 255, 255, 0.7)', color: 'black' }}
                    label={
                      <>
                        {kinkEmojis[kink]} {kink}
                      </>
                    }
                  />
                ))}
              </Typography>
            </Grid>
            <Divider sx={{ bgcolor: 'rgba(255,255,255,0.5)', my: 1 }} />
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight="bold" color="white">
                Description:
              </Typography>
              <Typography variant="subtitle1" color="rgba(255,255,255,0.8)">
                {chatPartnerData.description}
              </Typography>
            </Grid>
            <Divider sx={{ bgcolor: 'rgba(255,255,255,0.5)', my: 1 }} />
            <Grid container item xs={12} sx={{ padding: '8px 16px' }}>
              {chatPartnerData.photoURL.map((url, index) => (
                url && (
                  <Grid item xs={3} key={index}>
                    <CardMedia
                      component="img"
                      height="100"
                      image={url}
                      alt={`Image ${index + 1}`}
                      onClick={() => handleImageClick(index)}
                      style={{ cursor: 'pointer' }}
                    />
                    {/* <Avatar onClick={() => handleImageClick(index)} sx={{ height: '400px', width: '400px', background: chatPartnerData.role === 'admirer' ? '#005b80' : '#9c27b0', }}
                      src={url}>
                      {chatPartnerData.role === 'admirer' ? <Man sx={{ color: '#1785b2', mt: 4, fontSize: '50px' }} /> : <Woman sx={{ color: '#bd59ce', mt: 4, fontSize: '50px' }} />}
                    </Avatar> */}
                  </Grid>
                )
              ))}
            </Grid>
          </StyledBox>
        </Drawer>
      )}
      <Button
        className="desktopStatsBtn"
        variant="contained"
        color="primary"
        onClick={handleOpenDrawer}
      >
        See Stats
      </Button>
      <Modal
        open={open && selectedImageIndex !== null}
        onClose={handleCloseModal}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CardMedia
            component="img"
            image={chatPartnerData.photoURL[selectedImageIndex]}
            alt={`Image ${selectedImageIndex + 1}`}
            style={{ maxHeight: '90vh', maxWidth: '90vw', cursor: 'pointer' }}
            onClick={handleCloseModal}
          />
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              p: 2,
              zIndex: 9999,
            }}
          >
            <Button onClick={handlePreviousImage}>
              <KeyboardArrowLeftIcon sx={{ color: 'white' }} />
            </Button>
            <Button onClick={handleNextImage}>
              <KeyboardArrowRightIcon sx={{ color: 'white' }} />
            </Button>
          </Box>
        </Box>
      </Modal>
    </Root>
  );
};

export default ChatPartnersProfile;
