import { IconButton, Menu, MenuItem, Button, Select, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Block, Report } from '@mui/icons-material';
import React from 'react';

export default function ClickMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [blockAlertOpen, setBlockAlertOpen] = React.useState(false);
  const [reportAlertOpen, setReportAlertOpen] = React.useState(false);
  const [reportReason, setReportReason] = React.useState('');

  const handleLocalBlockUser = () => {
    setBlockAlertOpen(false);
    if (props.onRemoveFriend) {
      props.onRemoveFriend();
    }
    props.handleBlockUser(props.userId, props.username);
  };

  const handleReportUser = () => {
    console.log('reporting user: ', reportReason)
    setReportAlertOpen(false);
    if (props.onReportUser) {
      props.onReportUser(reportReason);
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleBlockAlertOpen = () => {
    setBlockAlertOpen(true);
    handleMenuClose();
  };

  const handleBlockAlertClose = () => {
    setBlockAlertOpen(false);
  };

  const handleReportAlertOpen = () => {
    setReportAlertOpen(true);
    handleMenuClose();
  };

  const handleReportAlertClose = () => {
    setReportAlertOpen(false);
  };

  const handleReportReasonChange = (event) => {
    setReportReason(event.target.value);
  };

  const handleReportSubmit = () => {
    handleReportUser();
  };

  return (
    <div>
      <IconButton onClick={handleMenuOpen}>
        <MoreHorizIcon style={{ color: 'white' }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleBlockAlertOpen}>
          <Block />
          Block User
        </MenuItem>
        <MenuItem onClick={handleReportAlertOpen}>
          <Report />
          Report User
        </MenuItem>
      </Menu>
      <Dialog open={blockAlertOpen} onClose={handleBlockAlertClose}>
        <DialogTitle>Block User</DialogTitle>
        <DialogContent>
          Are you sure you want to block this user?
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleLocalBlockUser}>
            Yes
          </Button>
          <Button onClick={handleBlockAlertClose}>No</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={reportAlertOpen} onClose={handleReportAlertClose}>
        <DialogTitle>Report User</DialogTitle>
        <DialogContent>
          Please select a reason for reporting this user:
          <div style={{ marginTop: '1rem' }}>
            <Select
              value={reportReason}
              onChange={handleReportReasonChange}
            >
              <MenuItem value="spam">Spam</MenuItem>
              <MenuItem value="abusive">Abusive</MenuItem>
              <MenuItem value="inappropriate content">Inappropriate content</MenuItem>
            </Select>
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleReportSubmit}>
            Submit
          </Button>
          <Button onClick={handleReportAlertClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}