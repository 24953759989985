import React, { useState } from 'react';
import { Paper, Chip, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import RoomIcon from '@mui/icons-material/Room';
import { useMutation } from '@apollo/client';
import { EDIT_USER } from '../../utils/mutations';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'transparent',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: 'white',
  width: '100%',
}));

const RowContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  justifyContent: 'center',
});

const CenteredGrid = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
});

const ChipWrapper = styled(Chip)(({ theme }) => ({
  backgroundColor: '#005b80',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: 'white',
  cursor: 'pointer',
  height: '5vh',
  width: '100%', // Adjust the width to match the other component
}));

const UpdateLocation = () => {
  const [editUser] = useMutation(EDIT_USER);
  const [loading, setLoading] = useState(false);

  const handleUpdateLocation = () => {
    try {
      setLoading(true);

      // Request location permission
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          console.log("Current latitude: ", latitude);
          console.log("Current longitude: ", longitude);

          // Call the editUser mutation with the obtained coordinates
          editUser({
            variables: {
              location: [latitude, longitude],
            },
            onCompleted: () => {
              setLoading(false);
              alert("Your location was successfully updated.");
            },
          });
        },
        (error) => {
          console.error("Error getting current location:", error);
          setLoading(false);
          alert("Error, Your Location Was Not Updated: Please grant FemSwipe location permission in your browser, iOS, or Android settings for accurate results. Otherwise, your default location will be Antarctica.");
        }
      );
    } catch (err) {
      setLoading(false);
      alert("Error: " + err.message);
    }
  };

  return (
    <CenteredGrid style={{ width: '100%' }} item xs={12}>
      <Item elevation={0}>
        <RowContainer>
          <ChipWrapper
            label="Update Location"
            icon={<RoomIcon style={{ color: 'white' }} />}
            onClick={handleUpdateLocation} // Call the handleUpdateLocation function
          ></ChipWrapper>
        </RowContainer>
        {loading && <p>Updating location...</p>}
      </Item>
    </CenteredGrid>
  );
};

export default UpdateLocation;
