import React, { useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import auth from "../../utils/auth";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useLazyQuery, useMutation, useSubscription } from '@apollo/client';
import { QUERY_ME, QUERY_USERS, QUERY_CHAT_PARTNER } from '../../utils/queries';
import { BLOCK_USER } from '../../utils/mutations';
import { FRIEND_REQUEST_SENT_SUBSCRIPTION } from '../../utils/subscriptions';
import { Box, Grid, Backdrop, IconButton, Avatar, Tabs, Tab, Typography, Badge, AppBar } from '@mui/material';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChatPartnersProfile from '../../components/ChatPartnerProfile';
import MatchList from '../../components/MatchList';
import Chat from '../../components/Chat';
import { createChannelId } from '../../utils/helperFunction';
import UserCardMenu from '../../components/UserCardMenu'
import UnmatchedUsersList from '../../components/UnmatchedUsersList';
import LoadingScreen from '../../components/Loading';
import SecondaryNav from '../../components/SecondaryNav';
import CustomDrawer from '../../components/Drawer';
import Profile from '../../components/Profile';
import Settings from '../Settings';
import { useRecoilState } from 'recoil';
import { meState } from '../../recoil/atoms';
import { Woman, Man } from '@mui/icons-material';
import Analytics from '../../components/Analytics';
import "../../css/chat.css";

const StyledBackdrop = styled(Backdrop)({
  zIndex: 100,
  color: 'white',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  width: '100%',
  height: '100%',
  marginBottom: '88px',
  '@media (min-width: 768px)': {
    marginLeft: '70px',
    width: 'calc(100% - 76px)',
    marginBottom: '0',
    height: '100%'
  },
});

const Inbox = () => {
  const navigate = useNavigate();
  const [isSettingsDrawerOpen, setIsSettingsDrawerOpen] = useState(false);
  const [isProfileDrawerOpen, setIsProfileDrawerOpen] = useState(false);
  const { tabIndex: tabIndexParam = "0" } = useParams();
  const loggedIn = auth.loggedIn();
  const [state, setState] = useState({
    partner: null,
    showInfo: false,
    conversationId: null,
    selectedChannel: null,
    showBackdrop: false,
    lastMessages: {},
    subscriptionData: null,
    usernamesById: {},
    tabIndex: Number(tabIndexParam),
    loading: true, // Added loading state
    hasNewFriendRequest: false, // Added state to track new friend requests
  });
  const { partner, showInfo, selectedChannel, showBackdrop, lastMessages, subscriptionData, usernamesById, tabIndex, loading } = state;
  const [getChatPartner, { data: chatPartnerData }] = useLazyQuery(QUERY_CHAT_PARTNER);
  const [blockUser] = useMutation(BLOCK_USER)
  const [me, setMe] = useRecoilState(meState);
  const [users, setUsers] = useState(null);
  const { loading: loadingMe, data: myData, refetch: refetchMe } = useQuery(QUERY_ME);
  const { loading: loadingUsers, data: usersData } = useQuery(QUERY_USERS);
  const [requestsTabClicked, setRequestsTabClicked] = useState(false);

  const hasNewFriendRequest =
    me && me.me && me.me.friendRequests && me.me.friendRequests.length > 0;


  const matchedUserIds = useMemo(() => {
    if (!me || !me.me || !me.me.friends) {
      return [];
    }
    let friends = me.me.friends || [];
    return friends.map(friend => friend.friendId?._id).concat(me.me._id);
  }, [me]);

  const matchedUsername = useMemo(() => {
    if (!me || !me.me || !me.me.friends) {
      return [];
    }
    let friends = me.me.friends || [];
    return friends.map(friend => friend.friendId?.username).concat(me.me.username);
  }, [me]);

  const myUsername = me?.me ? me.me.username : null;

  const handleClick = (username) => {
    setState(prevState => ({ ...prevState, showBackdrop: true }));
    const currentUser = me?.me.username;
    let newChannelId = null;
    if (currentUser && username) {
      newChannelId = createChannelId(currentUser, username);
    }
    setState(prevState => ({ ...prevState, partner: username, selectedChannel: newChannelId, conversationId: newChannelId }));
    getChatPartner({ variables: { username } });
  };

  const toggleInfo = () => {
    setState(prevState => ({ ...prevState, showInfo: !prevState.showInfo }));
  };

  const handleClose = () => {
    setState(prevState => ({ ...prevState, partner: null, conversationId: null, showInfo: false }));
    refetchMe();
  };

  const handleCloseChat = () => {
    if (partner) {
      handleClose(); // Call the handleClose function to close the chat
    }
  };


  useEffect(() => {
    setMe(myData);
    setUsers(usersData);
    const loadingTimeout = setTimeout(() => {
      setState(prevState => ({ ...prevState, loading: false }));
    }, 1000); // 1 second delay
    return () => clearTimeout(loadingTimeout); // Cleanup timeout on component unmount
  }, [myData, usersData]);

  const handleTabChange = (event, newValue) => {
    setState(prevState => ({ ...prevState, tabIndex: newValue }));
  };

  const handleBlockUser = async (id) => {
    try {
      await blockUser({ variables: { userId: id } });
    } catch (error) {
      console.error(error);
    }
    refetchMe();
  };

  const handleProfileDrawerOpen = () => {
    setIsProfileDrawerOpen(true);
  };

  const handleProfileDrawerClose = () => {
    setIsProfileDrawerOpen(false);
  };

  const handleSettingsDrawerOpen = () => {
    setIsSettingsDrawerOpen(true);
  };

  const handleSettingsDrawerClose = () => {
    setIsSettingsDrawerOpen(false);
  };



  // Add the FRIEND_REQUEST_SENT_SUBSCRIPTION
  const { data: friendRequestData } = useSubscription(FRIEND_REQUEST_SENT_SUBSCRIPTION, {
    variables: { userId: myData?.me?._id },
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData.data) {
        // Update hasNewFriendRequest to true when a new friend request is received
        setState(prevState => ({
          ...prevState,
          hasNewFriendRequest: true
        }));
      }
    },
  });

  useEffect(() => {
    if (subscriptionData) {
      const message = subscriptionData.messagePosted;
      const partner = message.username === me.me.username ? message.recipient : message.username;
      const newLastMessages = {
        ...lastMessages,
        [partner]: {
          username: partner,
          text: message.text,
          createdAt: message.createdAt
        }
      };
      const sortedLastMessages = Object.values(newLastMessages).sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setState(prevState => ({ ...prevState, lastMessages: sortedLastMessages }));
    }
  }, [subscriptionData, me, usernamesById, lastMessages, setState]);

  const unmatchedUserIds = useMemo(() => users?.users.filter(user => !matchedUserIds.includes(user.friendId?._id)), [users, matchedUserIds]);

  if (!loggedIn) {
    navigate('/');
    return null; // You can return null or any other component here
  }

  if (loadingMe || loadingUsers) { // Check the loading state
    return <LoadingScreen />;
  }

  return (
    <div style={{
      height: '100%',
      '@media (min-width: 768px)': {
        marginBottom: '5rem',
      }
    }}
    >
      <Analytics />
      <SecondaryNav username={me?.me?.username} id={me?.me?._id} avatar={me?.me?.photoURL} requests={me?.me?.friendRequests} onProfileButtonClick={handleProfileDrawerOpen} onSettingsButtonClick={handleSettingsDrawerOpen} onCloseChat={handleCloseChat} sx={{ zIndex: 9100 }}
      />
      <CustomDrawer open={isProfileDrawerOpen} onClose={handleProfileDrawerClose}>
        <Profile onClose={handleProfileDrawerClose} />
      </CustomDrawer>
      <CustomDrawer open={isSettingsDrawerOpen} onClose={handleSettingsDrawerClose}>
        <Settings onClose={handleSettingsDrawerClose} />
      </CustomDrawer>
      <Box sx={{
        width: '100%',
        marginBottom: '88px',
        marginLeft: '0',
        height: '100%',
        '@media (min-width: 768px)': {
          marginLeft: '73px',
          width: 'calc(100% - 73px)',
          marginBottom: '0',
        }
      }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", display: 'flex', justifyContent: 'center' }} position="sticky">
          <Tabs value={tabIndex} onChange={handleTabChange} aria-label="inbox tabs">
            <Tab label="Inbox" sx={{ color: 'white' }} />
            <Tab
              label={
                <Badge badgeContent={hasNewFriendRequest ? me?.me?.friendRequests.length : null} color="error">
                  Swipes
                </Badge>
              }
              sx={{ color: 'white' }}
            />
          </Tabs>
        </Box>
        <Grid container sx={{ p: 0, margin: '0 auto' }}>
          {partner ? null : (
            <Grid item xs={12} md={6} lg={6} sx={{ px: 0, margin: '0 auto' }}>
              {tabIndex === 0 && (
                <MatchList shme={me} userNameClick={handleClick} matches={me?.me?.messages} myID={me?.me._id} matchedWithUsername={matchedUsername} passUsername={myUsername} />
              )}
              {tabIndex === 1 && (
                <UnmatchedUsersList
                  users={users?.users}
                  matchedUserIds={matchedUserIds}
                  unmatchedUserIds={unmatchedUserIds}
                />
              )}
            </Grid>
          )}
          <Grid item xs={8}>
            {partner && (
              <StyledBackdrop open={showBackdrop}>
                <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', top: '0px', backgroundColor: 'black', width: '100%', height: "7vh", zIndex: "999" }}>
                  <IconButton onClick={handleClose} sx={{ width: '30px' }}>
                    <ChevronLeft sx={{ color: 'white' }} />
                  </IconButton>
                  <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                    <Avatar onClick={toggleInfo} sx={{ background: chatPartnerData?.user?.role === 'admirer' ? '#005b80' : '#9c27b0', mr: 2 }}
                      src={chatPartnerData?.user?.photoURL[0]}>
                      {chatPartnerData?.user?.role === 'admirer' ? <Man sx={{ color: '#1785b2', mt: 4, fontSize: '50px' }} /> : <Woman sx={{ color: '#bd59ce', mt: 4, fontSize: '50px' }} />}
                    </Avatar>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                      <Typography variant="subtitle1" sx={{ color: 'white' }}>
                        {chatPartnerData?.user.username}
                      </Typography>
                    </div>
                    <UserCardMenu handleBlockUser={handleBlockUser} userId={chatPartnerData?.user._id} myId={me.me._id} onRemoveFriend={() => {
                      setState(prevState => ({ ...prevState, showBackdrop: false }));
                    }} />
                  </div>
                </div>
                {showInfo ? (
                  <ChatPartnersProfile chatPartnerData={chatPartnerData?.user} />
                ) : (
                  me?.me.username && partner &&
                  <Chat
                    myData={me}
                    currentChatPartner={partner}
                    chatPartnerData={chatPartnerData?.user}
                    username={me?.me.username}
                    recipient={partner}
                    channelId={selectedChannel}
                    lastMessages={state.lastMessages}
                    style={{ paddingTop: '5vh' }}
                    onCloseBackdrop={handleClose}
                  />
                )}
              </StyledBackdrop>
            )}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default Inbox;
