import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { VERIFY_USER } from '../../utils/mutations';

const Verify = () => {
  const [userIsVerified, setUserIsVerified] = useState(null);
  const { verificationToken } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const getEmailFromURL = () => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get('email');
  };

  const email = getEmailFromURL();

  const [verifyUser] = useMutation(VERIFY_USER);

  const handleVerification = async () => {
    try {
      const { data } = await verifyUser({ variables: { verificationToken, email } });
      setUserIsVerified('Thank you for verifying your account.');
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (error) {
      console.error(error);
      setUserIsVerified('An error occurred');
    }
  };

  useEffect(() => {
    if (userIsVerified === null) {
      handleVerification();
    }
  }, [userIsVerified]);

  return (
    <div>
      {userIsVerified && <p>{userIsVerified}</p>}
    </div>
  );
};

export default Verify;
