import React, { useState, useRef } from "react";
import { useMutation } from "@apollo/client";
import { ADD_FRIEND, SEND_FRIEND_REQUEST, BLOCK_USER, UNBLOCK_USER, ADD_DISLIKED_USER } from "../../utils/mutations";
import SwipeComponent from "../SwipeComponent";
import { Avatar, Alert, Card, CardContent, Box, Backdrop, IconButton, SwipeableDrawer, Fade } from "@mui/material";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import UndoIcon from '@mui/icons-material/Undo';
import UserCardMenu from "../UserCardMenu";
import UserInfo from './userInfo.js';
import UserKinks from './userKinks';
import UserIdentity from "./userIdentity";
import SingleChat from "./SingleChat";
import { ThumbDown, Favorite, Chat, Woman, Man, Visibility } from '@mui/icons-material';
import "./UserCardGrid.css";

const UserCard = (props) => {
  const [isPhotoModalOpen, setIsPhotoModalOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const { user } = props;
  const [lastDirection, setLastDirection] = useState();
  const cardContentRef = useRef();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showChat, setShowChat] = useState(false)
  const [unblockUser] = useMutation(UNBLOCK_USER);
  const [blockUser] = useMutation(BLOCK_USER);
  const [addFriend] = useMutation(ADD_FRIEND);
  const [addDislikedUser] = useMutation(ADD_DISLIKED_USER);
  const [sendFriendRequest] = useMutation(SEND_FRIEND_REQUEST);

  const swiped = async (direction, nameToDelete, id) => {

    setLastDirection(direction);
    if (direction === "right") {
      await addFriend({ variables: { friendId: id } });
      await sendFriendRequest({ variables: { friendId: id } });
    } else {
      await addDislikedUser({ variables: { dislikedUserId: id } });
      props.onSwipe();
    }
  };
  const outOfFrame = (name) => {
    // console.log(name + " left the screen!");
  };
  const handleBlockUser = async (id, username) => {
    try {
      await blockUser({ variables: { userId: id } });
      setShowAlert(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUnblockUser = async (userId) => {
    try {
      const { data } = await unblockUser({ variables: { userId: userId } });
      setShowAlert(false);
    } catch (error) {
      console.error('Error unblocking user:', error);
    }
  };

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    setShowChat(false);
  };
  const preventDrag = (event) => {
    event.preventDefault();
  };

  return (
    showAlert ? (
      <>
        <Alert
          icon={false}
          sx={{ mb: 2 }}
          variant="outlined"
          severity="info"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setShowAlert(false);
                handleUnblockUser(user._id);
              }}
            >
              <UndoIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {user.username} is blocked
        </Alert>
      </>
    ) : (
      <>
        <SwipeComponent
          isPhotoModalOpen={isPhotoModalOpen}
          key={user.username}
          onSwipe={(dir) => swiped(dir, user.username, user._id)}
          onCardLeftScreen={() => outOfFrame(user.username)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#e4e4e4",
            position: "relative",
            // Make the card smaller and equal in size
          }}
        >
          <Card
            sx={{
              width: '100%',
              height: '100%',
              borderRadius: 2,
              // Adjust background color as needed
              backgroundColor: user.role === 'admirer' ? '#005b80' : '#9c27b0',
              mb: 3
            }}
            id="myContainer"
          >
            <Box sx={{ position: "relative", height: "100%" }}>
              <Box sx={{ position: "relative" }}>
                <Avatar
                  variant="square"
                  sx={{
                    objectFit: "cover",
                    width: "100%",
                    height: props.isGridView ? "250px" : "400px",
                    backgroundColor: user.role === 'admirer' ? '#011a25' : '#230129'
                  }}
                  src={!!user.photoURL[0] ? user.photoURL[0] : ""}
                  alt={user.username}
                  onMouseDown={preventDrag}
                  onTouchStart={preventDrag}
                >
                  {user.role === 'admirer' ? <Man sx={{ fontSize: '200px', color: '#1785b2' }} /> : <Woman sx={{ fontSize: '200px', color: '#bd59ce' }} />}
                </Avatar>
                <Box sx={{ position: "absolute", top: 0, right: 0, zIndex: 1000, color: "white", padding: "0.5rem" }}>
                  <UserCardMenu
                    handleBlockUser={handleBlockUser}
                    myUsername={props.myUsername}
                    userId={user._id}
                    myId={props.myId}
                  />
                </Box>
              </Box>
              <CardContent
                onClick={handleDrawerOpen}
                // onTouchCancel={handleDrawerOpen}
                sx={{
                  backgroundColor: "black",
                  color: "white",
                  padding: "0px",
                  overflow: "auto",
                  pb: '0 !important'
                }}
                ref={cardContentRef}
              >
                <Box sx={{ display: "flex", justifyContent: "center", }}>
                  <DragHandleIcon
                    variant="contained"
                    style={{
                      width: props.isGridView ? 20 : 30,
                      height: props.isGridView ? 20 : 30,
                      margin: "-5 auto",
                    }}
                  />
                </Box>
                {/* <Box sx={{ mr: 1 }} /> */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <UserInfo user={user} isGridView={props.isGridView} />
                  {/* <IconButton onClick={handleDrawerOpen}>
                    <Visibility/>
                  </IconButton> */}
                </Box>
              </CardContent>
            </Box>
          </Card>
        </SwipeComponent>
        <SwipeableDrawer
          className="grid-drawer"
          anchor="bottom"
          open={isDrawerOpen}
          onOpen={() => setShowChat(true)}
          onClose={handleDrawerClose}
        >
          {showChat ? (
            <SingleChat onClose={() => setShowChat(false)} user={user} myId={props.myId} myUsername={props.myUsername} />
          ) : (
            <Box width="100%" height="115%">
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <DragHandleIcon
                  variant="contained"
                  style={{
                    width: 30,
                    height: 30,
                    margin: "-5 auto",
                  }}
                />
              </Box>
              <Box sx={{ display: 'flex', px: 0, alignItems: 'center', justifyContent: 'space-between' }}>
                <UserInfo user={user} />
                <Avatar src={user.photoURL[0]} sx={{ mr: 2 }}></Avatar>
              </Box>
              <UserKinks user={user} />
              <UserIdentity user={user} setIsPhotoModalOpen={setIsPhotoModalOpen} />
              <Box sx={{ display: 'flex', gap: '0.5rem', justifyContent: 'space-between', px: 2, py: 2 }}>
                <IconButton onClick={() => swiped('left', user.username, user._id)} sx={{ color: 'white', backgroundColor: 'transparent', fontSize: '1rem' }}>
                  <ThumbDown />
                </IconButton>
                <IconButton onClick={() => setShowChat(true)} sx={{ color: 'white', backgroundColor: 'transparent', fontSize: '1rem' }}>
                  <Chat />
                </IconButton>
                <IconButton onClick={() => swiped('right', user.username, user._id)} sx={{ color: 'white', backgroundColor: 'transparent', fontSize: '1rem' }}>
                  <Favorite />
                </IconButton>
              </Box>
            </Box>
          )}
        </SwipeableDrawer>
      </>
    )
  );
};

export default UserCard;
