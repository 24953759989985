import React from 'react';

function getUserStatus(lastActive) {
  if (!lastActive) return 'offline';
  const now = Date.now();
  const lastActiveDate = new Date(Number(lastActive));
  const difference = now - lastActiveDate;
  const differenceInMinutes = Math.floor(difference / 1000 / 60);
  return differenceInMinutes <= 240 ? 'online' : 'offline';
}

function StatusCircle({ lastActive, isGridView }) {
  const status = getUserStatus(lastActive);
  const color = status === 'online' ? '#11d211' : 'red';

  const style = {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    backgroundColor: color,
    color: 'white',
    textAlign: 'center',
    lineHeight: '20px',
    fontSize: '1rem',
  };

  return (
    <div className="usercard-statuscircle" style={{ ...style }}>
    </div>
  );
}

export default StatusCircle;
