import React from "react";
import { ReCAPTCHA } from "react-google-recaptcha";
import { Avatar, Box, Link, List, ListItem, ListItemText, Chip } from "@mui/material";
import { kinkEmojis } from '../EditProfile/kinks';


const StepThree = ({ formState, recaptchaRef, agree1, agree2, setAgree1, setAgree2 }) => {
  return (
    <Box>
      <List>
        <ListItem sx={{ p: 0 }}>
        <ListItemText sx={{ color: 'white' }} primary={`Username: ${formState.username}`} />
          <Avatar alt={formState.username} src={formState.photoURL} />
        </ListItem>
        <ListItem sx={{ p: 0 }}>
          <ListItemText sx={{ color: 'white' }} primary={`Ethnicity: ${formState.ethnicity}`} />
        </ListItem>
        <ListItem sx={{ p: 0 }}>
          <ListItemText sx={{ color: 'white' }} primary={`Gender: ${formState.gender}`} />
        </ListItem>
        <ListItem sx={{ p: 0 }}>
          <ListItemText sx={{ color: 'white' }} primary={`Position: ${formState.position}`} />
        </ListItem>
        <ListItem sx={{ p: 0 }}>
          <ListItemText sx={{ color: 'white' }} primary={`Kinks:`} />
        </ListItem>
        <ListItem sx={{ p: 0, flexWrap: 'wrap' }}>
          {formState.kinks.map((kink, index) => (
            <Chip
              key={index}
              label={`${kinkEmojis[kink]} ${kink.toLowerCase()}`}
              sx={{ mr: 1, mb: 1, backgroundColor: '#005b80', color: 'white' }}
            />
          ))}
        </ListItem>
      </List>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey="6LfWD7AmAAAAAK7FU0hL3SfSY3NDmctNc50yn0zX"
      />
      <div>
        <input
          type="checkbox"
          name="agree1"
          value="agree1"
          checked={agree1}
          onChange={(e) => setAgree1(e.target.checked)}
          style={{ color: 'white' }}
        />
        <label style={{ color: 'white', fontSize: "1.15rem" }}>18 years of age or older, or the minimum age required by the laws and regulations of my jurisdiction.</label>
      </div>
      <div>
        <input
          type="checkbox"
          name="agree2"
          value="agree2"
          checked={agree2}
          onChange={(e) => setAgree2(e.target.checked)}
          style={{ color: 'white' }}
        />
        <label style={{ color: 'white', fontSize: "1.15rem" }}>
          I agree to the{" "}
          <Link target="_blank" href="/privacypolicy" color="primary" style={{ color: 'white', fontSize: "1rem" }}>
            Terms of Use & Privacy Policy
          </Link>
        </label>
      </div>
    </Box>
  );
};

export default StepThree;
