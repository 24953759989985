import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Auth from '../../utils/auth';
import DashboardLocation from "../../components/DashboardLocation";
import DashboardRoulette from "../../components/DashboardRoulette";
import { useQuery, useLazyQuery } from "@apollo/client";
import { QUERY_ME } from '../../utils/queries';
import { Tabs, Tab, Box, Snackbar, Button } from "@mui/material";
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import { userLocationState, meState } from '../../recoil/atoms';
import LoadingScreen from "../../components/Loading";
import SecondaryNav from "../../components/SecondaryNav";
import Analytics from "../../components/Analytics";
import CustomDrawer from "../../components/Drawer";
import Profile from "../../components/Profile";
import Settings from "../Settings";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import './styles.css'
import WelcomeMessage from "./welcomeMessage";

const Dashboards = () => {
  const navigate = useNavigate();
  const [isSettingsDrawerOpen, setIsSettingsDrawerOpen] = useState(false);
  const [isProfileDrawerOpen, setIsProfileDrawerOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const tabContainerRef = useRef(null);
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(false); // State for showing the welcome message

  const scrollToTabContainer = () => {
    if (typeof window !== 'undefined' && tabContainerRef.current) {
      tabContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handlePageChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleProfileDrawerOpen = () => {
    setIsProfileDrawerOpen(true);
  };

  const handleProfileDrawerClose = () => {
    setIsProfileDrawerOpen(false);
  };

  const handleSettingsDrawerOpen = () => {
    setIsSettingsDrawerOpen(true);
  };

  const handleSettingsDrawerClose = () => {
    setIsSettingsDrawerOpen(false);
  };

  const loggedIn = Auth.loggedIn();
  // const [me, setMe] = useState(null);
  const [me, setMe] = useRecoilState(meState);
  const userLocation = useRecoilValue(userLocationState);
  const updateUserLocation = useSetRecoilState(userLocationState);

  const { loading: queryLoading, data: cachedData } = useQuery(QUERY_ME);

  const [fetchMeData, { loading: fetchLoading, data: fetchedData }] = useLazyQuery(QUERY_ME);

  useEffect(() => {
    if (!queryLoading && cachedData && cachedData.me) {
      setMe(cachedData);
    } else if (!fetchLoading && fetchedData && fetchedData.me) {
      setMe(fetchedData.me);
    } else if (!fetchLoading && !fetchedData && !queryLoading) {
      fetchMeData();
    }
  }, [queryLoading, cachedData, fetchLoading, fetchedData, fetchMeData, updateUserLocation]);

  useEffect(() => {
    if (!queryLoading) {
      setLoading(false);
    }
  }, [queryLoading]);

  useEffect(() => {
    // Check if the user is no longer logged in
    if (!loggedIn) {
      navigate("/"); // Redirect the user back to "/"
    }
  }, [loggedIn, navigate]);

  useEffect(() => {
    // Check if the welcome message flag is set in localStorage
    const welcomeMessageFlag = localStorage.getItem("welcomeMessageFlag");
    if (!welcomeMessageFlag) {
      // If the flag is not set, show the welcome message
      setShowWelcomeMessage(true);
    }
  }, []);

  const handleSnackbarClose = () => {
    setShowWelcomeMessage(false);
    // Update the flag in localStorage to indicate that the message has been closed
    localStorage.setItem("welcomeMessageFlag", "closed");
  };

  if (queryLoading || loading) {
    return <LoadingScreen />;
  }

  if (loggedIn && me) {
    return (
      <>
        <Analytics />
        <SecondaryNav role={me?.me?.role} username={me?.me?.username} id={me?.me?._id} avatar={me?.me?.photoURL} requests={me?.me?.friendRequests} onProfileButtonClick={handleProfileDrawerOpen} onSettingsButtonClick={handleSettingsDrawerOpen} />
        <CustomDrawer open={isProfileDrawerOpen} onClose={handleProfileDrawerClose}>
          <Profile onClose={handleProfileDrawerClose} />
        </CustomDrawer>
        <CustomDrawer open={isSettingsDrawerOpen} onClose={handleSettingsDrawerClose}>
          <Settings onClose={handleSettingsDrawerClose} />
        </CustomDrawer>
        <Box className='test' sx={{
          width: '100%',
          background: 'rgba(255, 255, 255, 0.05)',
          marginBottom: '88px',
          marginLeft: '0',
          '@media (min-width: 768px)': {
            marginLeft: '73px',
            width: 'calc(100% - 73px)',
            marginBottom: '0',
          }
        }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", display: 'flex', justifyContent: 'center' }} ref={tabContainerRef} position="sticky">
            <Tabs
              value={activeTab}
              onChange={handlePageChange}
              aria-label="basic tabs example"
              style={{
                display: "flex",
              }}
            >
              <Tab
                label="Distance"
                style={{
                  flexGrow: 1,
                 
                  textAlign: "center",
                  color: "white",
                }}
              />
              <Tab
                label="Roulette"
                style={{
                  flexGrow: 1,
                 
                  textAlign: "center",
                  color: "white",
                }}
              />
            </Tabs>
          </Box>
          {activeTab === 0 && <DashboardLocation userLocation={userLocation} />}
          {activeTab === 1 && <DashboardRoulette activeTab={activeTab} scrollToTabContainer={scrollToTabContainer} />}
        </Box>
        <Snackbar
          open={showWelcomeMessage}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={null} // Do not auto-hide
          message={
            <WelcomeMessage />
          }
          action={
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
              <CloseIcon />
            </IconButton>
          }
        />
      </>
    );
  }
  return null;
};

export default Dashboards;

