import React from 'react';
import { Box, Typography } from '@mui/material';
import NearMeIcon from '@mui/icons-material/NearMe';
import StatusCircle from "../StatusCircle";
import InfoIcon from '@mui/icons-material/Info';

const getAge = (birthdate, isGridView) => {
  const today = new Date();
  const birthDate = new Date(birthdate);
  let age = today.getFullYear() - birthDate.getFullYear();
  const month = today.getMonth() - birthDate.getMonth();
  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

const UserInfo = ({ user, isGridView }) => {
  return (
    <Box sx={{ textAlign: "left", position: "relative", px: 2, pt: 0, pb: 2 }}>
      <Typography variant="h6" sx={{
        marginTop: 1,
        '@media screen and (max-width: 767px)': {
          fontSize: isGridView ? '0.5em' : 'inherit'
        }
      }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ display: 'inline-block', color: "white", fontSize: 'inherit' }}>
            {user?.username} <span style={{ fontStyle: 'italic' }}>&nbsp;{getAge(user?.age)}
            </span>
          </span>
        </div>
      </Typography>
      <Typography variant="h6" sx={{
        color: "grey",
        marginTop: 0,
        fontSize: 12,
        fontFamily: '"Roboto Condensed", sans-serif',
        '@media screen and (max-width: 767px)': {
          fontSize: isGridView ? '0.35em' : 'inherit'
        }
      }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <StatusCircle lastActive={user?.lastActive} isGridView={isGridView} />
          <span style={{ marginRight: '5px' }}></span> {/* Add this line for the space */}
          {user?.gender}, <NearMeIcon sx={{
            fontSize: '0.9rem', 
            opacity: 0.7, 
            position: 'relative', 
            top: '3px', 
            left: '-3px',
            '@media screen and (max-width: 767px)': {
              display: isGridView ? 'none' : 'block',
              fontSize: '1em',
              marginLeft: '0.5em',
              marginTop: '-5px'
            }
          }}
          />
          {user?.distance} miles away
        </div>
      </Typography>
      <Typography variant="h6" sx={{
        color: "white",
        marginTop: 0,
        fontSize: 12,
        fontFamily: '"Roboto Condensed", sans-serif',
        '@media screen and (max-width: 767px)': {
          fontSize: isGridView ? '0.35em' : 'inherit'
        }
      }}
      >
        <InfoIcon sx={{
          fontSize: '0.9rem',
          opacity: 0.7,
          position: 'relative',
          top: '3px',
          left: '-3px',
          '@media screen and (max-width: 767px)': {
            display: 'none'
          }
        }}
        />
        <span style={{ color: "grey" }}>{user?.ethnicity}</span>  <span style={{ color: "grey" }}>{user?.height}</span>  <span style={{ color: "grey" }}>{user?.weight} lbs.</span>  <span style={{ color: "grey" }}>{user?.position}</span>
      </Typography>
    </Box>
  );
}

export default UserInfo;
