import React, { useState, useEffect } from 'react';
import marks from '../../data/marks';
import { styled } from '@mui/material/styles';
import { Container, Slider, Grid, Paper, Backdrop, Chip } from '@mui/material';
import HeightIcon from '@mui/icons-material/Height';

// Your findMarkValue function here

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'transparent',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: 'white',
  width: '100%',
}));

const RowContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center', // Center the content horizontally
  gap: '10px',
});

const ChipWrapper = styled(Chip)(({ theme }) => ({
  backgroundColor: '#005b80',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: 'white',
  cursor: 'pointer',
  height: '5vh',
  width: '100vw',
}));

// Function to find the mark value based on the height string
function findMarkValue(heightString, marks) {
  // eslint-disable-next-line
  const heightParts = heightString?.match(/(\d+)\' (\d+)"/);
  if (heightParts && heightParts.length === 3) {
    const feet = parseInt(heightParts[1], 10);
    const inches = parseInt(heightParts[2], 10);
    const totalInches = feet * 12 + inches;

    // Find the mark with the closest value to totalInches
    let closestMark = null;
    let closestDifference = Infinity;

    for (const mark of marks) {
      const difference = Math.abs(mark.value - totalInches);
      if (difference < closestDifference) {
        closestDifference = difference;
        closestMark = mark.value;
      }
    }

    return closestMark;
  }

  return null; // Return null if the heightString is not in the expected format
}

const Height = ({ formState, setFormState }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [sliderValue, setSliderValue] = useState(48); // Default to the lowest value (4'0")

  // Use useEffect to set the initial sliderValue based on formState.height
  useEffect(() => {
    const markValue = findMarkValue(formState.height, marks);
    if (markValue !== null) {
      setSliderValue(markValue);
    }
  }, [formState.height]);

  const handleComponentClick = () => {
    setIsEditing(true);
  };

  const handleChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  const handleSliderChangeCommitted = (event, newValue) => {
    const feet = Math.floor(newValue / 12);
    const inches = newValue % 12;
    const heightString = `${feet}' ${inches}"`;

    setFormState({
      ...formState,
      height: heightString,
    });

    setIsEditing(false);
  };

  const handleBackdropClick = (event) => {
    if (event.target === event.currentTarget) {
      setIsEditing(false);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <Grid item container xs={12}>
        <Grid item xs={12}>
          <Item elevation={0}>
            <RowContainer>
              <ChipWrapper
                icon={<HeightIcon style={{ color: 'white' }} />}
                label="Height"
                onClick={handleComponentClick}
              />
            </RowContainer>
            {isEditing && (
              <Backdrop
                open={isEditing}
                onClick={handleBackdropClick}
                style={{ pointerEvents: 'auto', zIndex: 999, backgroundColor: 'rgba(0,0,0,0.75)' }}
              >
                <Container maxWidth="sm">
                  <Slider
                    value={sliderValue}
                    aria-labelledby="discrete-slider-always"
                    step={1}
                    min={48}
                    max={78}
                    marks={marks}
                    name="height"
                    type="height"
                    id="height"
                    valueLabelDisplay="on"
                    valueLabelFormat={(value) => {
                      const feet = Math.floor(value / 12);
                      const inches = value % 12;
                      return `${feet}' ${inches}"`;
                    }}
                    onChange={handleChange}
                    onChangeCommitted={handleSliderChangeCommitted}
                  />
                </Container>
              </Backdrop>
            )}
          </Item>
        </Grid>
      </Grid>
    </div>
  );
};

export default Height;
