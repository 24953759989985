import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';


const DELETE_USER = gql`
  mutation DeleteUser($myId: ID!) {
    deleteUser(myId: $myId) {
      success
      message
    }
  }
`;


const DeleteAccount = ({ myId }) => {
  const navigate = useNavigate();
  const [deleteAccount] = useMutation(DELETE_USER);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteAccount = async () => {
    try {
      const { data } = await deleteAccount({ variables: { myId } });
      if (data.deleteUser.success) {
        console.log('Account deleted successfully.');
        
        // Remove token from localStorage
        localStorage.removeItem("id_token");
  
        // Redirect the user to the login page
        navigate('/login');
      } else {
        console.log('Error deleting account.');
      }
    } catch (error) {
      console.error('Error deleting account:', error);
    }
  };
  
  

  return (
    <div style={{paddingLeft: 20}}>
      <h3 style={{fontWeight: 400}}>Delete Account</h3>
      <Button variant="contained" color="secondary" onClick={handleClickOpen}>
        Delete Account
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Account Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete your account? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleDeleteAccount();
              handleClose();
            }}
            color="secondary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteAccount;
