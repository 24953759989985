// GAComponent.js
import React, { useEffect } from 'react';
import ReactGA from '../../utils/analytics'; // Import the analytics setup file

function Analytics() {
  console.log('analytics component')
  useEffect(() => {
    // Track a page view when the component mounts
    ReactGA.pageview(window.location.pathname);

    // Example: Track a custom event
    ReactGA.event({
      category: 'User Interaction',
      action: 'Button Clicked',
    });
  }, []);

  return (
    <div>
      {/* Your component content */}
    </div>
  );
}

export default Analytics;
