import React, { useEffect, useState } from "react";

const LoadingScreen = () => {
  const [gradient, setGradient] = useState("");
  const baseColor = "#005b80";

  useEffect(() => {
    const interval = setInterval(() => {
      const color1 = getRandomColor();
      const color2 = getRandomColor();
      const newGradient = `linear-gradient(45deg, ${color1}, ${color2})`;
      setGradient(newGradient);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      const randomIndex = Math.floor(Math.random() * letters.length);
      const randomLetter = letters[randomIndex];
      color += randomLetter;
    }
    return color;
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        position: "relative",
        zIndex: 5,
        background: `${baseColor}`,
        background: `${gradient}`,
      }}
    >
      <h1
        style={{
          color: "white",
          fontSize: "48px",
          textAlign: "center",
        }}
      >
        FemSwipe<br></br>Beta
      </h1>
    </div>
  );
};

export default LoadingScreen;
