import React, { useState, useRef, useCallback, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { QUERY_USERS_BY_ROLE } from "../../utils/queries";
import { meState } from '../../recoil/atoms';
import { useRecoilValue } from 'recoil';
import { Alert, Box, Button, Container, Checkbox, Grid, IconButton, FormControlLabel, Chip } from '@mui/material'
import UndoIcon from '@mui/icons-material/Undo';
import FilterListIcon from "@mui/icons-material/FilterList";
import getDistanceFromLatLonInKm from "../../utils/proximity";
import UserCard from "../UserCard";
import { UNBLOCK_USER } from "../../utils/mutations";
import KinksFilter from "./kinksFilter";
import PositionSelection from "./positionFilter";
import EthnicityFilter from "./ethnicityFilter";
import GenderFilter from "./genderFilter";
import Backdrop from "@mui/material/Backdrop";
import { KeyboardDoubleArrowDown } from '@mui/icons-material';

const DashboardLocation = () => {
  let me = useRecoilValue(meState);
  me = me.me;
  const [sortedUsers, setSortedUsers] = useState([]);
  const [swipedUsers, setSwipedUsers] = useState([]); // Track swiped users separately
  const [activeCardIndex, setActiveCardIndex] = useState(0);
  const [onlineNow, setOnlineNow] = useState(false);
  const [hasPhoto, setHasPhoto] = useState(false);
  const [filterByFriends, setFilterByFriends] = useState(false);
  const [page, setPage] = useState(1);
  const cardContainerRef = useRef(null);
  const [showAlert, setShowAlert] = useState(false);
  const oppositeRole = me.role === "sissy" ? "admirer" : "sissy";
  const [blockedUsername, setBlockedUsername] = useState("");
  const [blockedUserId, setBlockedUserId] = useState("");
  const [unblockUser] = useMutation(UNBLOCK_USER);
  const [selectedKink, setSelectedKink] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [selectedEthnicity, setSelectedEthnicity] = useState(null);
  const [selectedGender, setSelectedGender] = useState(null);
  const [isFilterIconClicked, setIsFilterIconClicked] = useState(false);
  const [isGridView, setIsGridView] = useState(true);
  const [filterChanged, setFilterChanged] = useState(false); // Track if filters have changed

  const { data, fetchMore, refetch } = useQuery(QUERY_USERS_BY_ROLE, {
    variables: {
      role: oppositeRole,
      sortByDistance: true,
      userLocation: me.location,
      limit: 11,
      offset: 0,
      kink: selectedKink,
      position: selectedPosition,
      ethnicity: selectedEthnicity,
      gender: selectedGender,
      active: onlineNow,
      hasPhoto: hasPhoto,
      filterFriends: filterByFriends,
    },
    notifyOnNetworkStatusChange: true,
  });
  const usersByRole = data?.usersByRole || [];


  // Create a function to refetch data with updated filters
  const refetchWithFilters = () => {
    refetch({
      role: oppositeRole,
      sortByDistance: true,
      userLocation: me.location,
      limit: 10,
      offset: 0,
      kink: selectedKink,
      position: selectedPosition,
      ethnicity: selectedEthnicity,
      gender: selectedGender,
      filterFriends: filterByFriends,
    });
  };

  const handleScroll = useCallback(() => {
    const container = cardContainerRef.current;
    if (container) {
      const cardHeight = container.offsetHeight;
      const scrollPosition = container.scrollTop;
      const newIndex = Math.round(scrollPosition / cardHeight);
      setActiveCardIndex(newIndex);
    }
  }, []);

  const handleUnblockUser = async (userId) => {
    try {
      const { data } = await unblockUser({ variables: { userId: userId } });
      if (data.unblockUser.success) {
        // Remove the unblocked user from swipedUsers
        setSwipedUsers((prevSwipedUsers) => prevSwipedUsers.filter((id) => id !== userId));
      }
    } catch (error) {
      console.error("Error unblocking user:", error);
    }
  };

  // Use useEffect to listen for changes in filter values and trigger refetch
  useEffect(() => {
    if (filterChanged) {
      refetchWithFilters(); // Trigger refetch when filters change
      setFilterChanged(!filterChanged); // Reset the filterChanged flag
    }
  }, [filterChanged]);

  useEffect(() => {
    const container = cardContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, [handleScroll]);

  useEffect(() => {
    // Define a function to calculate sorted users
    const calculateSortedUsers = () => {
      const usersWithDistance = usersByRole.map((user) => {
        const [aLatitude, aLongitude] = me.location;
        const [bLatitude, bLongitude] = user.location;
        const distanceInKm = getDistanceFromLatLonInKm(
          parseFloat(aLatitude),
          parseFloat(aLongitude),
          parseFloat(bLatitude),
          parseFloat(bLongitude)
        );
        const distanceInMiles = Math.round(distanceInKm / 1.60934);
        return {
          ...user,
          distance: distanceInMiles,
        };
      });

      // Filter out users who are in swipedUsers array
      const filteredUsers = usersWithDistance.filter((user) => !swipedUsers.includes(user._id));

      setSortedUsers(filteredUsers);
    };

    calculateSortedUsers();
  }, [data, swipedUsers, selectedKink, selectedPosition, selectedEthnicity, selectedGender]);

  // Define function for loading more users
  const loadMoreUsers = () => {
    fetchMore({
      variables: {
        limit: 10,
        offset: page * 10,
        kink: selectedKink,
        position: selectedPosition,
        ethnicity: selectedEthnicity,
        gender: selectedGender,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (
          !fetchMoreResult ||
          !fetchMoreResult.usersByRole ||
          !fetchMoreResult.usersByRole.users
        ) {
          return prev;
        }
        const newUsers = fetchMoreResult.usersByRole.users;
        const updatedUsers = [...prev.usersByRole.users, ...newUsers];

        return {
          ...prev,
          usersByRole: {
            ...prev.usersByRole,
            users: updatedUsers,
          },
        };
      },
    })
      .then(() => {
        setPage((prevPage) => prevPage + 1);
      })
      .catch((error) => {
        console.error("Error loading more users:", error);
      });
  };

  const handleSwipe = async (userId) => {
    setSwipedUsers((prevSwipedUsers) => [...prevSwipedUsers, userId]);
  };


  const handleKinkSelection = (kink) => {
    setSelectedKink((prevSelectedKink) => {
      const newSelectedKink = prevSelectedKink === kink ? null : kink;
      setFilterChanged(true); // Set the flag when filters change
      return newSelectedKink;
    });
  };

  const handlePositionSelection = (position) => {
    setSelectedPosition((prevSelectedPosition) => {
      const newSelectedPosition = prevSelectedPosition === position ? null : position;
      setFilterChanged(true); // Set the flag when filters change
      return newSelectedPosition;
    });
  };

  const handleEthnicitySelection = (ethnicity) => {
    setSelectedEthnicity((prevSelectedEthnicity) => {
      const newSelectedEthnicity = prevSelectedEthnicity === ethnicity ? null : ethnicity;
      setFilterChanged(true); // Set the flag when filters change
      return newSelectedEthnicity;
    });
  };

  const handleGenderSelection = (gender) => {
    setSelectedGender((prevSelectedGender) => {
      const newSelectedGender = prevSelectedGender === gender ? null : gender;
      setFilterChanged(true); // Set the flag when filters change
      return newSelectedGender;
    });
  };

  const handleFilterIconClick = () => {
    setIsFilterIconClicked((prevValue) => !prevValue);
  };

  const toggleView = () => {
    setIsGridView((prevIsGridView) => !prevIsGridView);
  };

  const handleFilterOptionClick = (event) => {
    // Stop click event propagation to prevent backdrop from closing
    event.stopPropagation();
  };

  const handleOnlineFilterChange = () => {
    setOnlineNow(!onlineNow);
  };

  const handleHasPhotoFilterChange = () => {
    setHasPhoto(!hasPhoto);
  };

  // Render method for the list view
  const renderListView = () => (
    <div className="swipe-container">
      <div className="card-container" ref={cardContainerRef}>
        {showAlert && (
          <Alert
            icon={false}
            sx={{ mb: 2 }}
            variant="outlined"
            severity="info"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setShowAlert(false);
                  handleUnblockUser(blockedUserId); // Pass blockedUserId as an argument
                }}
              >
                <UndoIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {blockedUsername} is blocked
          </Alert>
        )}
        {sortedUsers.slice(0, page * 10).map((user, index) => (
          <UserCard
            key={user._id}
            user={user}
            myId={me._id}
            myUsername={me.username}
            fullSize={index === activeCardIndex}
            onSwipe={() => handleSwipe(user._id)}
            style={{ marginBottom: "20px" }}
            distance={`${user.distance} mi`}
            isGridView={isGridView}
          />
        ))}
      </div>
    </div>
  );

  // Render method for the grid view
  const renderGridView = () => (
    <Box sx={{ width: '100%', padding: '0px 10px' }}>
      {showAlert && (
        <Alert
          icon={false}
          sx={{ mb: 2 }}
          variant="outlined"
          severity="info"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setShowAlert(false);
                handleUnblockUser(blockedUserId); // Pass blockedUserId as an argument
              }}
            >
              <UndoIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {blockedUsername} is blocked
        </Alert>
      )}
      <Grid container spacing={2}>
        {sortedUsers.slice(0, page * 12).map((user, index) => (
          <Grid item lg={3} md={3} sm={4} xs={4} key={user._id}>
            <UserCard
              user={user}
              myId={me._id}
              myUsername={me.username}
              onSwipe={() => handleSwipe(user._id)}
              distance={`${user.distance} mi`}
              isGridView={isGridView} // Pass isGridView as the smallerText prop
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  return (
    <Box sx={{ flexGrow: 1, mt: 3 }}>
      <Grid container={true} justifyContent="end">
        <div style={{ display: "flex", alignItems: "center", gap: "10px", paddingBottom: 15, paddingRight: '10px', justifyContent: 'end' }}>
          <Chip
            label={`Switch to ${isGridView ? "List View" : "Grid View"}`}
            color="primary"
            onClick={toggleView}
          />
          <Chip
            label="Filter"
            color={filterChanged ? 'warning' : 'primary'}
            onClick={handleFilterIconClick}
            icon={<FilterListIcon />}
          />
        </div>
      </Grid>

      <Backdrop
        open={isFilterIconClicked}
        onClick={handleFilterIconClick}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 3,
          backgroundColor: "rgba(18, 10, 27, 0.85)",
        }}
      >
        <Container maxWidth="sm">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              gap: "5vh",
              paddingTop: "10px",
              paddingBottom: "10px",
              flexDirection: "column",
              flexGrow: 1, // Allow the container to grow and fill the available height
            }}
            onClick={handleFilterOptionClick} // Prevent backdrop from closing on filter option click
          >
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterByFriends}
                    onChange={() => setFilterByFriends((prev) => !prev)}
                    name="filterByFriends"
                    color="primary"
                    sx={{
                      color: "white",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  />
                }
                label="Favorites"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={onlineNow}
                    onChange={handleOnlineFilterChange}
                    name="onlineNow"
                    color="primary"
                    sx={{
                      color: "white", // Change the color to white
                      "&:hover": {
                        backgroundColor: "transparent", // Make the background transparent on hover
                      },
                    }}
                  />
                }
                label="Online Now"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hasPhoto}
                    onChange={handleHasPhotoFilterChange}
                    name="Has Photo"
                    color="primary"
                    sx={{
                      color: "white", // Change the color to white
                      "&:hover": {
                        backgroundColor: "transparent", // Make the background transparent on hover
                      },
                    }}
                  />
                }
                label="Has a Photo"
              />
            </Box>

            <KinksFilter selectedKink={selectedKink} onKinkSelect={handleKinkSelection} />
            <PositionSelection selectedPosition={selectedPosition} onPositionSelect={handlePositionSelection} />
            <EthnicityFilter selectedEthnicity={selectedEthnicity} onEthnicitySelect={handleEthnicitySelection} />
            <GenderFilter selectedGender={selectedGender} onGenderSelect={handleGenderSelection} />
          </div>
        </Container>
      </Backdrop>

      <Grid container={true} spacing={1} justifyContent="center">
        {isGridView ? (
          renderGridView()
        ) : (
          <Grid sx={{ pt: 0 }} lg={4} md={5} sm={6} xs={12}>
            {renderListView()}
          </Grid>
        )}
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "center", py: 2, backgroundColor: "#0d0d0d" }}>
        <Button
          variant="contained"
          color={me.role === "admirer" ? "primary" : "secondary"}
          onClick={loadMoreUsers}
          disabled={page * 10 >= sortedUsers.length}
          sx={{
            py: 2,
            transition: "transform 1s",
            "&:hover": {
              transform: "scale(1.05)", // Increase the size on hover
            },
          }}
          endIcon={<KeyboardDoubleArrowDown />}
        >
          Load More Profiles
        </Button>
      </Box>
    </Box>
  );
};

export default DashboardLocation;