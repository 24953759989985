import React, { useState, useRef } from 'react';
import { Box, TextField, Backdrop, Paper, Chip } from '@mui/material';
import CakeIcon from '@mui/icons-material/Cake';
import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'transparent',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: 'white',
  width: '100%',
}));

const RowContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  justifyContent: 'center',
});

const CenteredGrid = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
});

const ChipWrapper = styled(Chip)(({ theme }) => ({
  backgroundColor: '#005b80',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: 'white',
  cursor: 'pointer',
  height: '5vh',
  width: '100vw',
}));

const AgeProfile = ({ formState, handleChange }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [tempDate, setTempDate] = useState(formState.age);
  const [error, setError] = useState('');
  const textFieldRef = useRef(null);

  const calculateAge = (birthDate) => {
    const birthDateObj = new Date(birthDate);
    const currentDate = new Date();
    const age = currentDate.getFullYear() - birthDateObj.getFullYear();

    // Check if the birthday for this year has occurred or not
    if (
      currentDate.getMonth() < birthDateObj.getMonth() ||
      (currentDate.getMonth() === birthDateObj.getMonth() &&
        currentDate.getDate() < birthDateObj.getDate())
    ) {
      return age - 1;
    }

    return age;
  };

  const handleComponentClick = () => {
    setIsEditing(true);
    setTempDate(formState.age);
    setError(''); // Clear any previous error message
  };

  const handleBackdropClick = (event) => {
    if (!error && textFieldRef.current && textFieldRef.current.contains(event.target)) {
      return;
    }
    if (!error) {
      setIsEditing(false);
      handleChange({ target: { name: 'age', value: tempDate } });
    }
  };

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 18);

    // Parse the selected date to a Date object for comparison
    const selectedDateObj = new Date(selectedDate);

    if (selectedDateObj < minDate) {
      // Allow the date change
      setTempDate(selectedDate);
      setError(''); // Clear any previous error message
    } else {
      // Prevent the date change and display an error message
      setError('You must be at least 18 years old.');
    }
  };

  return (
    <CenteredGrid style={{ width: '100%' }} item xs={12}>
      <Item elevation={0}>
        <RowContainer>
          <ChipWrapper
            label={tempDate ? `Age: ${calculateAge(tempDate)}` : 'Age:'}
            icon={<CakeIcon style={{ color: 'white' }} />}
            onClick={handleComponentClick}
          />
        </RowContainer>
        {isEditing && (
          <Backdrop open={isEditing} onClick={handleBackdropClick} style={{ zIndex: 999, backgroundColor: "rgba(0, 0, 0, 0.8)" }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', mt: -10 }}>
              <Box sx={{mb: 2}}>
                {error && <div style={{ color: 'red' }}>{error}</div>}
              </Box>
              <TextField
                className="form-input"
                ref={textFieldRef}
                type="date"
                id="date"
                name="age"
                value={tempDate}
                InputLabelProps={{ shrink: true }}
                onChange={handleDateChange}
                inputProps={{
                  max: new Date(
                    new Date().setFullYear(new Date().getFullYear() - 18)
                  )
                    .toISOString()
                    .split('T')[0],
                  style: {
                    minWidth: '100px'
                  },
                }}
                label="Select a date"
              />
            </Box>
          </Backdrop>
        )}
      </Item>
    </CenteredGrid>
  );
};

export default AgeProfile;