import React, { useState, useEffect } from "react";
import moment from "moment";
import { useMutation } from "@apollo/client";
import { Avatar, Box, Divider, CircularProgress, IconButton, SwipeableDrawer } from "@mui/material";
import { useSubscription } from "@apollo/client";
import NewMessageProfile from "../newMessageProfile";
import UserProfileCard from "../../UserProfileCard";
import SingleChat from "../../UserCard/SingleChat";
import { ADD_FRIEND, SEND_FRIEND_REQUEST, BLOCK_USER, UNBLOCK_USER, ADD_DISLIKED_USER } from "../../../utils/mutations";
import { FRIEND_REQUEST_SENT_SUBSCRIPTION } from "../../../utils/subscriptions";
import StatusCircle from "../../StatusCircle";
import { ThumbDown, Favorite, Chat, Woman, Man } from '@mui/icons-material';

const RequestsList = ({ friendRequests, handleAccept, setFriendRequests, userId, handleDecline, username }) => {
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [subscriptionFriendRequest, setSubscriptionFriendRequest] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showChat, setShowChat] = useState(false)
  const { data: subscriptionData } = useSubscription(FRIEND_REQUEST_SENT_SUBSCRIPTION, { variables: { userId: userId } });
  const [unblockUser] = useMutation(UNBLOCK_USER);
  const [blockUser] = useMutation(BLOCK_USER);
  const [addFriend] = useMutation(ADD_FRIEND);
  const [addDislikedUser] = useMutation(ADD_DISLIKED_USER);
  const [sendFriendRequest] = useMutation(SEND_FRIEND_REQUEST);

  const swiped = async (direction, nameToDelete, id) => {

    if (direction === "right") {
      await addFriend({ variables: { friendId: id } });
      await sendFriendRequest({ variables: { friendId: id } });
    } else {
      await addDislikedUser({ variables: { dislikedUserId: id } });
    }
  };

  useEffect(() => {
    if (subscriptionData && subscriptionData.friendRequestSent) {
      setSubscriptionFriendRequest(subscriptionData.friendRequestSent);
    }
  }, [subscriptionData]);

  useEffect(() => {
    if (subscriptionFriendRequest) {
      setFriendRequests(prevFriendRequests => {
        const updatedFriendRequests = [...prevFriendRequests];
        updatedFriendRequests.push(subscriptionFriendRequest);
        return updatedFriendRequests;
      });
    }
  }, [subscriptionFriendRequest, setFriendRequests]);


  const handleAcceptWithLoading = async (friend) => {
    setLoading(true);
    await handleAccept(friend);
    setLoading(false);
  };

  const handleDeclineWithLoading = async (friend) => {
    setLoading(true);
    await handleDecline(friend);
    setLoading(false);
  };

  const openUserInformation = (user) => {
    setSelectedUser(user);
    setIsDrawerOpen(true);
  };

  const closeUserInformation = () => {
    setSelectedUser(null);
    handleDrawerClose();
  };

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    setShowChat(false);
  };

  return (
    <>
      {friendRequests &&
        friendRequests.map((request, index, arr) =>
          request.friendId && (
            <React.Fragment key={request._id}>
              {loading && (
                <div className="loading-overlay">
                  <CircularProgress />
                </div>
              )}
              <div style={{ display: "flex", alignItems: "center", marginBottom: '10px', }}>
                <div
                  style={{ position: "relative", marginRight: "10px" }}
                >
                  <Avatar onClick={() => openUserInformation(request.friendId)} sx={{ width: '60px', height: '60px', background: request.friendId.role === 'admirer' ? '#005b80' : '#9c27b0' }}
                    src={request.friendId.photoURL[0]}>
                    {request.friendId.role === 'admirer' ? <Man sx={{ color: '#1785b2', mt: 4, fontSize: '50px' }} /> : <Woman sx={{ color: '#bd59ce', mt: 4, fontSize: '50px' }} />}
                  </Avatar>
                  <div
                    style={{
                      position: "absolute",
                      bottom: "0px",
                      right: "0px",
                    }}
                  >
                    <StatusCircle lastActive={request.friendId.lastActive} />
                  </div>
                </div>
                <div
                  style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}
                >
                  <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                    {request.friendId.username}
                  </span>
                  <span style={{ fontSize: "12px" }}>
                    Swiped you! Swipe back!
                  </span>
                </div>
                <span style={{ fontSize: "10px", marginLeft: "auto", paddingRight: "2%", maxWidth: '70px', width: '100%' }}>
                  {moment(parseInt(request.createdAt)).fromNow()}
                </span>
                <div className="button-container" style={{ display: "flex" }}>
                  <IconButton
                    onClick={() => handleAcceptWithLoading(request.friendId)}
                    style={{ marginRight: "5px" }}
                  >
                    <Favorite sx={{ color: 'white' }} />
                  </IconButton>
                  <IconButton
                    onClick={() => handleDeclineWithLoading(request.friendId)}
                    disabled={loading}
                  >
                    <ThumbDown sx={{ color: 'white' }} />
                  </IconButton>
                </div>
              </div>
              {index !== arr.length - 1 && (
                <div className="divider-container">
                  <Divider className="divider" />
                </div>
              )}

            </React.Fragment>
          )
        )}
      {selectedUser && (
        // <NewMessageProfile open={true} onClose={closeUserInformation} selectedUser={selectedUser} />
        <SwipeableDrawer
          className="grid-drawer"
          anchor="bottom"
          // open={isDrawerOpen}
          // onClose={handleDrawerClose}
          open={isDrawerOpen}
          onClose={handleDrawerClose}
        >
          {showChat ? (
            <SingleChat onClose={() => setShowChat(false)} user={selectedUser} myId={userId} myUsername={username} />
          ) : (
            <>
              <UserProfileCard user={selectedUser} />
              <Box sx={{ display: 'flex', gap: '0.5rem', justifyContent: 'space-between', px: 2, py: 2 }}>
                <IconButton onClick={() => swiped('left', selectedUser?.username, selectedUser?._id)} sx={{ color: 'white', backgroundColor: 'transparent', fontSize: '1rem' }}>
                  <ThumbDown />
                </IconButton>
                <IconButton onClick={() => setShowChat(true)} sx={{ color: 'white', backgroundColor: 'transparent', fontSize: '1rem' }}>
                  <Chat />
                </IconButton>
                <IconButton onClick={() => swiped('right', selectedUser?.username, selectedUser?._id)} sx={{ color: 'white', backgroundColor: 'transparent', fontSize: '1rem' }}>
                  <Favorite />
                </IconButton>
              </Box>
            </>

          )}
        </SwipeableDrawer>
      )}
    </>
  );
};

export default RequestsList;