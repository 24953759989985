import React from 'react';
import { Chip, Box } from '@mui/material';
import { styled } from '@mui/system';
import { keyframes } from '@emotion/react';

const scrollAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-50%);
  }
`;

const reverseScrollAnimation = keyframes`
  0% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateY(0);
  }
`;

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100vh',
  width: '100vw',
  transform: 'rotate(-30deg)',
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(1),
  background: 'rgba(255, 255, 255, 0.5)',
  width: '230px',
  height: '48px', // Twice the default height
  lineHeight: '48px', // Align the text vertically
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: "22px",
  padding: theme.spacing(0, 1), // Adjust the padding to fit the content
  backgroundColor: "#005b80",
  color: "whitesmoke"
}));

const StyledColumn = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '200%',
  overflow: 'hidden',
  animation: `${scrollAnimation} 40s linear infinite`,
}));

const StyledColumnReverse = styled(StyledColumn)(({ theme }) => ({
  animation: `${reverseScrollAnimation} 30s linear infinite`,
}));

const kinks = [
  { label: "🕯️ BDSM" },
  { label: "🪢 BONDAGE" },
  { label: "🤰 BREEDING" },
  { label: "🔒 CHASTITY" },
  { label: "👑 DOMINATION" },
  { label: "👗 FEMINIZATION" },
  { label: "🦶 FEET" },
  { label: "💞 GFE" },
  { label: "👥 GROUP" },
  { label: "🤡 HUMILIATION" },
  { label: "🌀 HYPNO" },
  { label: "👩‍✈️ LATEX" },
  { label: "🎀 LINGERIE" },
  { label: "👩‍🍳 MAID" },
  { label: "🥩 RAW" },
  { label: "📷 RECORDING" },
  { label: "💅 SISSIFICATION" },
  { label: "👰 SISSYBRIDE" },
  { label: "🔎 SHOWOFF" },
  { label: "🗣️ VERBAL" },
];

export default function HomeBack() {
  const columns = Array(3).fill().map((_, index) => {
    const offset = index * kinks.length / 3;
    const slicedKinks = kinks.slice(offset).concat(kinks.slice(0, offset));

    if (index === 1) { // If it's the second column
      return (
        <StyledColumnReverse key={index}>
          {[...slicedKinks, ...slicedKinks].map((kink, i) => (
            <StyledChip
              key={kink.label + i}
              label={kink.label}
            />
          ))}
        </StyledColumnReverse>
      );
    } else {
      return (
        <StyledColumn key={index}>
          {[...slicedKinks, ...slicedKinks].map((kink, i) => (
            <StyledChip
              key={kink.label + i}
              label={kink.label}
            />
          ))}
        </StyledColumn>
      );
    }
  });

  return (
    <div className='chip-holder' style={{position: 'fixed', width: '100%', height: '100%', overflow: 'hidden'}}>
      <StyledBox>
      <Box className='chip-box' display="flex" justifyContent="space-between">
        {columns}
      </Box>
    </StyledBox>
    </div>
    
  );
}



