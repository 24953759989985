import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Auth from "../../utils/auth";
import HomeNav from "./HomeNav";
import Hero from "./Hero";
import LoadingScreen from "../../components/Loading";
import About from "./About";
import Footer from "./Footer";
import "./styles.css";
import Analytics from "../../components/Analytics";

const Home = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const loggedIn = Auth.loggedIn();

  useEffect(() => {
    const visitedBefore = localStorage.getItem("visited");
    if (visitedBefore) {
      setLoading(false);
    } else {
      localStorage.setItem("visited", "true");
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1500);

      return () => clearTimeout(timer);
    }
  }, []);

  useEffect(() => {
    const handleUnload = () => {
      localStorage.removeItem("visited");
    };

    window.addEventListener("unload", handleUnload);

    return () => {
      window.removeEventListener("unload", handleUnload);
    };
  }, []);

  useEffect(() => {
    if (loggedIn) {
      navigate("/dashboard");
    }
  }, [loggedIn, navigate]);

  return (
    <div>
      <Analytics/>
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          <div className="home-appbar" style={{ position: "relative", zIndex: 1 }}>
            <HomeNav />
            <Hero />
            <About />
            <Footer />
          </div>
        </>
      )}
    </div>
  );
};

export default Home;
