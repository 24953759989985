import React from "react";
import { Link } from "react-router-dom";
import { AppBar, Toolbar } from "@mui/material";
// Import the About component from its correct location

const HomeNav = () => {
  return (
    <AppBar
      sx={{
        backgroundColor: 'transparent',
        boxShadow: 'none',
        backdropFilter: 'blur(8px)',
        webkitBackdropFilter: 'blur(8px)'
      }}
      position="fixed"
      style={{ backgroundColor: 'transparent', boxShadow: 'none' }}
    >
      <Toolbar
        sx={{
          justifyContent: 'space-evenly',
          '@media (min-width: 497px)': { justifyContent: 'flex-end' }
        }}
      >
        {/* Use the Link component to navigate to the About page */}
        <Link to="/about" style={{ marginRight: 20, display: 'inline-block', color: 'white' }}>
          About
        </Link>
        <Link to="/support" style={{ marginRight: 20, display: 'inline-block', color: 'white' }}>
          Support
        </Link>
        <Link to="/contact" style={{ marginRight: 10, display: 'inline-block', color: 'white' }}>
          Contact
        </Link>
      </Toolbar>
    </AppBar>
  );
};

export default HomeNav;
