import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom


const Contact = () => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        background: 'rgba(0, 0, 0, 0.87)',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
      }}
    >
      <Container maxWidth="lg">
        <Button
          variant="outlined"
          onClick={() => window.history.back()}
          sx={{ color: 'white', borderColor: 'white', marginBottom: '20px' }}
        >
          Back
        </Button>
        <Typography component="h2" variant="h3" sx={{ fontWeight: 'bold', mb: 2 }}>
          Contact Us
        </Typography>
        <Typography variant="h4" sx={{ mt: 4 }}>
          Reasons to Contact Us
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          You can contact us for various reasons, including:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">
              General inquiries or feedback
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Reporting issues or problems
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Suggestions for improvements
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Partnership opportunities
            </Typography>
          </li>
        </ul>
        <Typography variant="h4" sx={{ mt: 4 }}>
          Contact for Advertisements
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          If you are interested in advertising with FemSwipe, here are some reasons why you should consider it:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">
              Reach a diverse and engaged audience
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Promote your products or services effectively
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Increase brand visibility and awareness
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Targeted advertising options
            </Typography>
          </li>
        </ul>
        <Typography variant="body1" sx={{ mt: 2 }}>
          To inquire about advertising opportunities or discuss advertising with us, please contact us at{' '}
          <a href="mailto:Admin@Femswipe.com" style={{ color: 'white', textDecoration: 'underline' }}>
            Admin@Femswipe.com
          </a>
          .
        </Typography>

        <Typography variant="h4" sx={{ mt: 4 }}>
          Donate
        </Typography>
        <Link to="/donate" style={{ textDecoration: 'none' }}>
          <Button variant="contained" color="primary" sx={{ mt: 2 }}>
            Donate Now
          </Button>
        </Link>
      </Container>
    </Box>
  );
};

export default Contact;
