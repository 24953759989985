import React, { useState, useEffect } from 'react';
import { Avatar, Container, Grid, Box, CardMedia, Backdrop } from '@mui/material';
import { styled } from '@mui/material/styles';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import DeleteIcon from '@mui/icons-material/Delete';
import FileUpload from '../FileUpload';
import { useQuery, useMutation } from '@apollo/client';
import { QUERY_ME } from '../../utils/queries';
import { EDIT_USER } from '../../utils/mutations';
import { deleteObject, ref } from 'firebase/storage';
import { storage } from '../../firebase';

const StyledGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1),
  width: '100%'
}));

const RowContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  justifyContent: 'center',
  width: '100%'
});

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  height: '200px',
}));

const StyledBackdrop = styled(Backdrop)({
  backgroundColor: 'rgba(0,0,0,0.75)',
  zIndex: "9999"
});

const MainPhotoContainer = styled(Grid)({
  marginBottom: '10%',
});

const ChipWrapper = styled('div')(({ theme }) => ({
  backgroundColor: '#005b80',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: 'white',
  cursor: 'pointer',
  height: '5vh',
  width: '100vw',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '16px'
}));

const labelStyle = {
  fontSize: '14px', // Adjust the font size as needed
};

const EditPhotos = ({ setPhotoURL, handleChange, formState }) => {
  console.log('checking handleChange: ', handleChange)
  const { data } = useQuery(QUERY_ME);
  const [editUser] = useMutation(EDIT_USER);
  const [isBackdropOpen, setIsBackdropOpen] = useState(false);
  const [currentImages, setCurrentImages] = useState([]);
  const isAvatarEmpty = data?.me?.photoURL?.every((str) => str === "");

  useEffect(() => {
    if (data && data.me) {
      const updatedImages = [...data.me.photoURL];
      while (updatedImages.length < 4) {
        updatedImages.push('');
      }
      setCurrentImages(updatedImages);
      setPhotoURL(data.me.photoURL);
    }
  }, [data, setPhotoURL]);

  const handleBackdropOpen = () => {
    setIsBackdropOpen(true);
  };

  const handleBackdropClose = (event) => {
    if (event.target === event.currentTarget) {
      setIsBackdropOpen(false);
    }
  };

  const provideURL = (url) => {
    if (handleChange) {
      handleChange({ target: { name: 'photoURL', value: url } });
    }
  }

  const clearImage = async (index) => {
    const newImages = [...currentImages];
    newImages[index] = '';

    const imageUrl = currentImages[index];
    const imageRef = ref(storage, imageUrl);
    await deleteObject(imageRef);

    const filteredImages = newImages.filter(image => image !== '');
    while (filteredImages.length < 4) {
      filteredImages.push('');
    }

    setCurrentImages(filteredImages);

    await editUser({
      variables: { photoURL: filteredImages },
    });
  };

  return (
    <div style={{ width: '100%' }}>
      <StyledGrid container item xs={12}>
        <RowContainer>
          <ChipWrapper className={isAvatarEmpty ? 'pulse' : ''} onClick={handleBackdropOpen}>
            <AddAPhotoIcon style={{ fontSize: '1rem', marginRight: '8px' }} />
            <span>Photos:&nbsp;&nbsp;</span>
            <Avatar sx={{width: '20px', height: '20px'}} src={formState.photoURL} />
          </ChipWrapper>
        </RowContainer>
      </StyledGrid>

      <StyledBackdrop open={isBackdropOpen} onClick={handleBackdropClose}>
        <Container maxWidth="sm">
          <Grid container sx={{ padding: '16px' }}>
          <label style={labelStyle}>Main Photo:</label>
            <MainPhotoContainer sx={{position: 'relative'}} item xs={12}>
              <Box sx={{ position: 'relative' }}>
                <StyledCardMedia
                  component="img"
                  height="400"
                  image={currentImages[0] ? currentImages[0] : 'https://fakeimg.pl/600x400?text=Upload+New'}
                  alt="Main Photo"
                />
                <DeleteIcon
                  onClick={() => clearImage(0)}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    color: 'white',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  }}
                />
              </Box>
              <Box sx={{ mt: 2, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>
                <FileUpload
                  provideURL={provideURL}
                  index={0}
                  photoURL={currentImages[0]}
                  setPhotoURL={(url) => {
                    let newImages = [...currentImages];
                    newImages[0] = url;
                    setCurrentImages(newImages);
                  }}
                />
              </Box>
            </MainPhotoContainer>

            <Grid container item xs={12} spacing={1}>
              {currentImages.slice(1).map((url, index) => (
                <Grid sx={{position:'relative'}} item xs={4} key={index}>
                  <Box position="relative" width="100%">
                    <StyledCardMedia
                      component="img"
                      height="100"
                      image={url ? url : 'https://fakeimg.pl/600x400?text=Upload+New'}
                      alt={`Image ${index + 2}`}
                    />

                    <DeleteIcon
                      onClick={() => clearImage(index + 1)}
                      style={{
                        color: 'white',
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // half-transparent black
                      }}
                    />
                  </Box>
                  <Box sx={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}} display="flex" justifyContent="center" alignItems="center" mt={2}>
                    <Box width={25}>
                      <FileUpload
                        index={index + 1}
                        photoURL={currentImages[index + 1]}
                        setPhotoURL={(url) => {
                          let newImages = [...currentImages];
                          newImages[index + 1] = url;
                          setCurrentImages(newImages);
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Container>
      </StyledBackdrop>
    </div>
  );
};

export default EditPhotos;
