import React, { useState, useRef } from 'react';
import { TextField, Backdrop, Paper, Chip } from '@mui/material';
import BookIcon from '@mui/icons-material/Book';
import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'transparent',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: 'white',
  width: '100%',
}));

const ChipWrapper = styled(Chip)(({ theme }) => ({
  backgroundColor: '#005b80',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: 'white',
  cursor: 'pointer',
  height: '5vh',
  width: '100%', // Adjust the width to match the other component
}));

const Description = ({ formState, handleChange }) => {
  const [open, setOpen] = useState(false);
  const descriptionRef = useRef(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleBackdropClick = (event) => {
    // check if the click is inside the descriptionRef element
    if (!descriptionRef.current.contains(event.target)) {
      handleClose();
    }
  };

  const handleInputChange = (e) => {
    handleChange(e);
  };

  return (
    <>
      <Item elevation={0}>
        <ChipWrapper
          icon={<BookIcon style={{ color: 'white' }} />}
          label="Username/Description"
          onClick={handleOpen}
        />
        {open && (
          <Backdrop sx={{ background: 'rgba(0,0,0,0.75)' }} open={open} onClick={handleBackdropClick} style={{ zIndex: 1 }}>
            <Container maxWidth="sm">
              <div ref={descriptionRef} style={{ padding: '20px', borderRadius: '4px', width: '100%' }}>
                {/* Add TextField for formState.username */}
                <TextField
                  sx={{ width: '100%' }}
                  className="form-input"
                  variant="outlined"
                  name="username" // Use the correct name from formState
                  type="text" // Use the correct type
                  id="username" // Use the correct ID
                  label="Username" // Label for the field
                  value={formState.username} // Use the correct value from formState
                  onChange={handleInputChange}
                  autoFocus
                />
                {/* Add a TextField for formState.description */}
                <TextField
                  sx={{ width: '100%', marginTop: '16px' }}
                  className="form-input"
                  multiline
                  rows={4}
                  variant="outlined"
                  name="description"
                  type="description"
                  id="description"
                  label="Description"
                  value={formState.description}
                  onChange={handleInputChange}
                />
              </div>
            </Container>
          </Backdrop>
        )}
      </Item>
    </>
  );
};

export default Description;
