import React, { useState, useRef } from "react";
import { ADD_USER, VALIDATE_USER_CREDENTIALS } from "../../utils/mutations";
import { useMutation } from "@apollo/client";
import { Button, Typography, IconButton, Stepper, Step, StepLabel } from "@mui/material";
import { styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import Box from '@mui/material/Box';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const steps = ['Account Details', 'Profile Info', 'Review & Join'];

const Signup = () => {
  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: '#8e0b8a',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: purple[700],
    },
  }));
  const [activeStep, setActiveStep] = useState(0);
  const [userSignedup, setUserSignedup] = useState(null);
  const [formState, setFormState] = useState({
    username: "",
    email: "",
    password: "",
    age: "",
    description: "",
    ethnicity: "",
    position: "",
    gender: "",
    role: "",
    photoURL: "",
    kinks: []
  });
  const [stepOneError, setStepOneError] = useState(null); // State for Step One error
  const [agree1, setAgree1] = useState(false);
  const [agree2, setAgree2] = useState(false);
  const [addUser] = useMutation(ADD_USER);
  const [validateUserCredentials] = useMutation(VALIDATE_USER_CREDENTIALS); // Add validation mutation
  const recaptchaRef = useRef();

  const handleStepOneChange = (updatedFormState) => {
    setFormState(updatedFormState);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      console.log('submitted data: ', formState)
      const recaptchaToken = recaptchaRef.current.getValue();
      await addUser({
        variables: {
          ...formState,
          recaptchaToken: recaptchaToken,
        },
      });
      setUserSignedup('Thank you for signing up! Please check your email (including spam or junk folder) to verify your account.');
    } catch (e) {
      console.error(e);
      setStepOneError('An error occurred, try again.');
    }
  };

  const handleValidateCredentials = async () => {
    try {
      const { data } = await validateUserCredentials({
        variables: {
          username: formState.username,
          email: formState.email,
          password: formState.password,
        },
      });

      if (data.validateUserCredentials === "Credentials are valid") {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      } else {
        setStepOneError(data.validateUserCredentials);
      }
    } catch (error) {
      console.error(error);
      setStepOneError("An error occurred while validating credentials.");
    }
  };

  const handleNext = () => {
    // Only validate credentials on the first step
    if (activeStep === 0) {
      handleValidateCredentials();
      return;
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  return (
    <>
      {!userSignedup ? (
        <>
          <Stepper
            sx={{ mb: 2, "& .MuiStepIcon-root": { color: "#005b80" } }} activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step sx={{ color: 'white' }} key={label}>
                <StepLabel sx={{ color: 'white', "& .MuiStepLabel-label": { color: "white" }, "& .Mui-active": { color: '#8e0b8a !important' }, "& .MuiStepLabel-label.Mui-completed": { color: "#b4b4b4 !important" } }}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === 0 && (
            <StepOne
              formState={formState}
              onStepOneChange={handleStepOneChange}
              stepOneError={stepOneError} // Pass the error to StepOne component
            />
          )}
          {activeStep === 1 && (
            <StepTwo
              formState={formState}
              onStepTwoChange={(updatedFormState) => {
                setFormState({ ...formState, ...updatedFormState });
              }}
            />
          )}
          {activeStep === 2 && (
            <StepThree
              formState={formState}
              recaptchaRef={recaptchaRef}
              agree1={agree1}
              agree2={agree2}
              setAgree1={setAgree1}
              setAgree2={setAgree2}
            />
          )}
          {activeStep === 3 && (
            <>
              <p>Review</p>
              <p>Step {activeStep + 1}</p>
            </>
          )}
          <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', pt: 2 }}>
            <IconButton
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ opacity: activeStep === 0 ? 0 : 1 }}
              style={{ color: 'white' }}
            >
              <ArrowBackIcon />
            </IconButton>
            <IconButton
              style={{
                color: 'white',
                opacity: (activeStep === 0 && (!formState.username || !formState.email || !formState.password)) ||
                  (activeStep === 1 && (!formState.role || !formState.ethnicity || !formState.position || !formState.gender || !formState.age || !formState.photoURL)) ||
                  activeStep === 2 ? 0 : 1, // Set opacity to 0 when activeStep is 2
              }}
              onClick={handleNext}
              disabled={
                (activeStep === 0 && (!formState.username || !formState.email || !formState.password)) ||
                (activeStep === 1 && (!formState.role || !formState.ethnicity || !formState.position || !formState.gender || !formState.age || !formState.photoURL))
              }
            >
              <ArrowForwardIcon />
            </IconButton>
          </Box>
          {activeStep === steps.length - 1 && (
            <form onSubmit={handleFormSubmit}>
              {stepOneError && <Typography color="error">{stepOneError}</Typography>}
              <ColorButton sx={{ mb: 2, mt: 1, pt: 2, pb: 2, width: '100%', borderRadius: '20px' }} type="submit" variant="contained">Sign Up</ColorButton>
            </form>
          )}
        </>
      ) : (
        <Typography align="center" color="white">{userSignedup}</Typography>
      )}
    </>
  );
};

export default Signup;
