import React, { useState, useRef, useEffect } from 'react';
import { useSubscription, useQuery, useLazyQuery } from '@apollo/client';
import { useMutation } from "@apollo/client";
import { GET_MESSAGES, USER_BLOCKED_SUBSCRIPTION } from '../../utils/subscriptions';
import { QUERY_MESSAGES, QUERY_MESSAGES_TO_RECIPIENT } from '../../utils/queries';
import { POST_MESSAGE } from '../../utils/mutations';
import { UPDATE_MESSAGE } from '../../utils/mutations';
import { createChannelId } from '../../utils/helperFunction';
import ChatFileUpload from '../Chat/chatFileUpload';
import UserInfo from './userInfo.js';
import { Avatar, Box, Grid, Paper, TextField, Chip, Stack, Button, Dialog, DialogContent, DialogActions, DialogContentText } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import { v4 as uuidv4 } from 'uuid';
import dateFormat from '../../utils/dateFormat';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.9)', // Set the background color here
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: 'center',
  borderRadius: '0',
  color: theme.palette.text.secondary,

  position: 'relative',
  top: 0,
  left: 0,
  right: 0,
  overflowY: 'scroll',
  '& > h2': {
    position: 'sticky',
    top: 0,
    width: '100',
    zIndex: 1,
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    padding: theme.spacing(1),
    margin: 0,
  },
  '& > form': {
    position: 'sticky',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: theme.spacing(1),
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  },
}));

const SingleChat = ({ user, myId, myUsername, onClose }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [setNewMessages] = useState(0);
  const [showTimestamps, setShowTimestamps] = useState(false);
  const [postMessage] = useMutation(POST_MESSAGE);
  const [username, setUsername] = useState(null);
  const [recipient, setRecipient] = useState(null);
  const [channelId, setChannelId] = useState(null); // add the channelId state here
  const [selectedImage, setSelectedImage] = useState(null);
  const [isMarkedAsRead, setIsMarkedAsRead] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);

  // This function is used to run the UPDATE_MESSAGE mutation.
  const [updateMessage] = useMutation(UPDATE_MESSAGE);
  // This function marks a message as read by running the UPDATE_MESSAGE mutation.
  const markMessageAsRead = async (messageId) => {
    try {
      await updateMessage({
        variables: {
          id: messageId,
          input: {
            read: true,
          },
        },
      });
    } catch (error) {
      console.error('Error marking message as read:', error);
    }
  };

  const { data: subscriptionData } = useSubscription(USER_BLOCKED_SUBSCRIPTION, {
    variables: { userId: myId }
  });

  useEffect(() => {
    if (subscriptionData) {
      console.log(subscriptionData);
    }

  }, [subscriptionData])

  useEffect(() => {
    messages.forEach((message) => {
      if (message.username === user?.username && !message.read && !isMarkedAsRead) {
        markMessageAsRead(message._id);
        setIsMarkedAsRead(true);
      }
    });
  }, [user?.username, messages, markMessageAsRead, isMarkedAsRead]);

  useEffect(() => {
    setUsername(myUsername);
    setRecipient(user?.username);
    setChannelId(createChannelId(myUsername, user?.username));
  }, [myUsername, user?.username]);

  // Use Apollo's useSubscription hook to subscribe to new messages in a specific channel.
  // The "channelId" is passed in as a variable to the GET_MESSAGES subscription.
  const { data, error } = useSubscription(GET_MESSAGES, {
    variables: { channelId: channelId },
    onSubscriptionData: ({ subscriptionData }) => {
      const newMessage = subscriptionData.data.messagePosted;

      // Add the new message to the messages state.
      setMessages((prevMessages) => {
        const updatedMessages = prevMessages.concat(newMessage);
        organizeMessages(updatedMessages);
        return updatedMessages;
      });

      // Call scrollToBottom to scroll to the new message
      scrollToBottom();

      // Increment the newMessages state by 1.
      setNewMessages((prev) => prev + 1);
      // Update the channelId state with the channelId of the new message.
      setChannelId(newMessage.channelId);
      // If the new message is from the current chat partner, mark it as read.
      if (newMessage.username === user?.username) {
        markMessageAsRead(newMessage._id);
      }
    },
    onError: (error) => {
      // Handle the error here.
    },
  });

  const [getMessagesTo, { data: messagesTo, loading: loadingMessagesTo }] = useLazyQuery(
    QUERY_MESSAGES_TO_RECIPIENT,
    {
      variables: { username: myUsername, recipient: user?.username },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setMessages((prevMessages) => {
          const updatedMessages = prevMessages.concat(data.messagesToRecipient);
          organizeMessages(updatedMessages);
          return updatedMessages;
        });
      },
    }
  );

  const [getMessagesFrom, { data: messagesFrom, loading: loadingMessagesFrom }] = useLazyQuery(
    QUERY_MESSAGES_TO_RECIPIENT,
    {
      variables: { username: user?.username, recipient: myUsername },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setMessages((prevMessages) => {
          const updatedMessages = prevMessages.concat(data.messagesToRecipient);
          organizeMessages(updatedMessages);
          return updatedMessages;
        });
      },
    }
  );

  // Fetch all messages in the app
  const { subscribeToMore, data: result, loading: loadingMessages } = useQuery(QUERY_MESSAGES);

  const organizeMessages = (mergedMessages) => {
    mergedMessages.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1));
    setMessages(mergedMessages.slice(-50)); // Keep only the last 50 messages
  };

  // Update the message state whenever the input field changes
  const handleChange = (e) => {
    setMessage(e.target.value);
  }

  const handleClose = () => {
    setOpen(false);
    // props.onCloseBackdrop();
  };

  ///ref to focus on text so you can automatically type again after sending message//
  const textRef = useRef(null);

  async function postIt(event) {
    event.preventDefault();
    if (message !== '') {
      try {
        await postMessage({
          variables: {
            username: myUsername,
            text: message,
            recipient: user?.username,
          },
        });
        setMessage('');
        textRef.current.focus(); // Focus on the input element
      } catch (error) {
        console.error("Error while sending the message:", error);
        setOpen(true);
      }
    }

  }


  ///post image function//
  async function postImageMessage(fileURL, message) {
    try {
      const isImage = message.startsWith('https://firebasestorage.googleapis.com');
      if (isImage) {
        await postMessage({
          variables: {
            username: myUsername,
            text: fileURL,
            recipient: user?.username,
          },
        });
      } else {
        await postMessage({
          variables: {
            username: myUsername,
            text: fileURL,
            recipient: user?.username,
          },
        });
      }
    } catch (e) {
      console.error('Error while sending the image message:', e);
    }
  }

  // Ref for scrolling to the bottom of the chat window
  const messagesEndRef = useRef(null);
  // Function to scroll to the bottom of the chat window
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'auto', block: 'end' });
  };

  // Fetch messages sent by the current user to the chat partner
  useEffect(() => {
    if (user?.username) {
      getMessagesTo();
    }
  }, [user?.username, getMessagesTo]);
  // Fetch messages sent by the chat partner to the current user
  useEffect(() => {
    if (user?.username) {
      getMessagesFrom();
    }
  }, [user?.username, getMessagesFrom]);

  useEffect(() => {
    setUsername(myUsername);
    setRecipient(user?.username);
  }, [myUsername, user?.username, setRecipient, setUsername]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div key={messages.length} style={{ width: '100%' }}>
      <div>
        <Grid container direction="column" alignItems="center" spacing={0}>
          <Grid item sx={{ width: '100%', position: 'relative' }}>
            <Box sx={{ background: 'black', position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'space-between', top: 0, width: '100%', zIndex: 10, borderBottom: '1px solid rgb(49, 49, 49)' }}>
              <Box sx={{display: 'flex', alignItems: 'center'}}>
                <ArrowBackIcon sx={{ ml: 3, color: 'white' }} onClick={onClose} />
                <UserInfo user={user} />
              </Box>
              <Avatar src={user?.photoURL[0]} sx={{ mr: 2 }}></Avatar>
            </Box>
            <Item sx={{ overflow: 'hidden' }}>
              <Box className='chat-container' sx={{ 
                position: 'relative', 
                paddingTop: '7em',
                '@media screen and (max-width: 767px)': {
                  paddingTop: '9em'
                }
                }}
              >
                {messages.map((message, index) => {
                  const isImage = message.text.startsWith('https://firebasestorage.googleapis.com');
                  const isSender = message.username === username;
                  return (
                    <Stack
                      key={uuidv4()}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '5px 2%',
                        marginTop: '20px',
                        alignItems: isSender ? 'flex-end' : 'flex-start',
                      }}
                      ref={index === messages.length - 1 ? messagesEndRef : null}
                    >
                      {isImage ? (
                        <img src={message.text} alt="uploaded content" style={{ maxWidth: '200px' }} onClick={() => setSelectedImage(message.text)} />
                      ) : (
                        <Chip
                          sx={{
                            height: 'auto',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                            borderRadius: '10px',
                            mx: 3,
                            '& .MuiChip-label': {
                              display: 'block',
                              whiteSpace: 'normal',
                              textAlign: 'left',
                            },
                          }}
                          data-id={message._id}
                          className={`chat-message ${isSender ? 'sender' : 'recipient'}`}
                          // avatar={<Avatar>{isSender ? username.charAt(0) : recipient.charAt(0)}</Avatar>}
                          color={isSender ? "primary" : "secondary"}
                          label={message.text}
                          onClick={() => setShowTimestamps(!showTimestamps)}
                        />
                      )}
                      {showTimestamps && (
                        <p style={{ color: 'white', fontSize: '8px' }}>
                          {dateFormat(parseInt(message.createdAt))}
                        </p>
                      )}
                      <div ref={messagesEndRef}></div>
                    </Stack>
                  );
                })}
                {errorMessage && (
                  <Dialog
                    open={open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {errorMessage}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} autoFocus>
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                )}
              </Box>
              <div style={{ display: 'flex', alignItems: 'center', padding: '20px 0' }}>
                <ChatFileUpload
                  postImageMessage={postImageMessage}
                  username={myUsername}
                  recipient={user?.username}
                />
                <form onSubmit={postIt} style={{ backgroundColor: 'rgba(255, 255, 255, 0.6)', color: 'rgba(255, 255, 255, 0.6)', flex: 1, }}>
                  <div style={{ position: 'sticky', bottom: 0, display: 'flex', alignItems: 'center' }}>
                    <TextField
                      inputRef={textRef} // Add a ref directly to the input element
                      style={{ width: '100%', zIndex: '999' }}
                      onChange={handleChange}
                      value={message}
                      placeholder="Type here"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SendIcon onClick={postIt} />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                  </div>
                </form>
              </div>
            </Item>
          </Grid>
        </Grid>
      </div>
      {selectedImage && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'black',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 999,
          }}
          onClick={() => setSelectedImage(null)}
          onTouchStart={() => setSelectedImage(message.text)}
        >
          <div style={{ maxWidth: '90%', maxHeight: '90%' }}>
            <img src={selectedImage} alt="Selected Image" style={{ maxWidth: '100%', maxHeight: '100%' }} />
          </div>
        </div>
      )}
    </div>
  );
}
export default SingleChat;
