import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const NewMessageProfile = ({ open, onClose, selectedUser }) => {
  console.log(selectedUser.photoURL)
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <Backdrop
      open={open}
      onClick={onClose}
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', zIndex: "9999" }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        padding="20px"
      >
        <Typography variant="h6" style={{ marginBottom: "10px", color: "#fff" }}>
          {selectedUser?.username}
        </Typography>
        <Typography variant="body1" style={{ marginBottom: "5px", color: "#fff" }}>
          Age: {selectedUser?.age}
        </Typography>
        <Typography variant="body1" style={{ marginBottom: "5px", color: "#fff" }}>
          Height: {selectedUser?.height}
        </Typography>
        <Typography variant="body1" style={{ marginBottom: "5px", color: "#fff" }}>
          Weight: {selectedUser?.weight} lbs.
        </Typography>
        <Typography variant="body1" style={{ marginBottom: "5px", color: "#fff" }}>
          Ethnicity: {selectedUser?.ethnicity}
        </Typography>
        <Typography variant="body1" style={{ marginBottom: "5px", color: "#fff" }}>
          Role: {selectedUser?.role}
        </Typography>
        <Typography variant="body1" style={{ marginBottom: "5px", color: "#fff" }}>
          Gender: {selectedUser?.gender}
        </Typography>
        <Typography variant="body1" style={{ marginBottom: "5px", color: "#fff" }}>
          Position: {selectedUser?.position}
        </Typography>
        <Typography variant="body1" style={{ marginBottom: "20px", color: "#fff" }}>
          Description: {selectedUser?.description}
        </Typography>

        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="center"
        >
          {selectedUser?.photoURL && selectedUser.photoURL.some(photoUrl => photoUrl.trim() !== "") && (
            <div className="photos-container">
              {selectedUser.photoURL
                .filter(photoUrl => photoUrl.trim() !== "") // Filter out empty strings
                .map((photoUrl, index) => (
                  <img
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                      borderRadius: "50%",
                      margin: "5px",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.9)",
                      cursor: "pointer"
                    }}
                    key={index}
                    src={photoUrl}
                    alt={`Photo ${index}`}
                  />
                ))}
            </div>
          )}
        </Box>
        <Modal
          open={modalOpen}
          onClose={handleCloseModal}
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
          <img
            src={selectedImage}
            alt="Modal Image"
            style={{ maxWidth: "90%", maxHeight: "90%", objectFit: "contain" }}
          />
        </Modal>
      </Box>
    </Backdrop>
  );
};

export default NewMessageProfile;