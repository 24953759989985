import React from 'react';
import { Container, Typography } from '@mui/material';

class PrivacyPolicy extends React.Component {
  render() {
    return (
      <Container maxWidth="md" sx={{ backgroundColor: 'black', color: 'white', padding: '2rem', borderRadius: '8px', textAlign: 'left' }}>
        <Typography variant="h4" component="h1" sx={{ marginBottom: '1rem' }}>
          Privacy Policy
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
          We respect your privacy and are committed to protecting your personal data. This privacy policy will inform you how we collect, use, and protect your personal data when you visit our website.
        </Typography>
        <Typography variant="h5" component="h2" sx={{ marginBottom: '1rem' }}>
          Information Collection and Use
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
          We may collect personal data that you voluntarily provide to us when you use our website, including your name, email address, and any other information you choose to share with us. We may use your personal data to communicate with you, provide services to you, or improve our website.
        </Typography>
        <Typography variant="h5" component="h2" sx={{ marginBottom: '1rem' }}>
          Information Sharing and Disclosure
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
          We will not share or disclose your personal data to any third-party, except as required by law or to provide services to you.
        </Typography>
        <Typography variant="h5" component="h2" sx={{ marginBottom: '1rem' }}>
          Data Security
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
          We take appropriate measures to protect your personal data from unauthorized access or disclosure.
        </Typography>
        <Typography variant="h5" component="h2" sx={{ marginBottom: '1rem' }}>
          Email Communication
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
          We may use the email address you provide to us for the following purposes:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">
              Sending you important updates and notifications related to your account, including new messages, account activity, and service changes.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Contacting you with information about new features, promotions, and other relevant content related to our services. You can opt out of these communications at any time.
            </Typography>
          </li>
        </ul>
        <Typography variant="h5" component="h2" sx={{ marginBottom: '1rem' }}>
          Terms of Use
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
          By using our website, you agree to the following terms:
        </Typography>
  <li>
    <Typography variant="body1">
    You must be of the minimum age 18 or 21 required by the laws and regulations of your jurisdiction to use our website.
    </Typography>
  </li>
  <li>
  </li>
        <ul>
          <li>
            <Typography variant="body1">
              We are not liable for any damages or losses that may result from your use of our website.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              We reserve the right to modify or discontinue our website at any time, without notice.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              You are solely responsible for any content that you post on our website.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              You agree to use our website in compliance with all applicable laws and regulations.
            </Typography>
          </li>
        </ul>
        <Typography variant="h5" component="h2" sx={{ marginBottom: '1rem' }}>
          Disclaimer of Liability
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
          Our website is provided on an "as is" and "as available" basis. We do not warrant that our website will be uninterrupted or error-free. We are not liable for any damages or losses that may result from your use of our website, including but not limited to direct, indirect, incidental, punitive, and consequential damages. You acknowledge and agree that you use our website at your own risk.
        </Typography>
        <Typography variant="body1">
          If you have any questions or concerns about our Privacy Policy and Terms of Use, please contact us at [contact email].
        </Typography>
      </Container>
    );
  }
}

export default PrivacyPolicy;
