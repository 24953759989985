import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ChatIcon from '@mui/icons-material/Chat';
import PersonIcon from '@mui/icons-material/Person';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Container from '@mui/material/Container';

export default function About() {
  return (
    <Box sx={{padding: '20px'}}>
      <Container maxWidth="lg" sx={{py: 10}}>
        <Typography 
          component="h2"
          sx={{ 
            color: "#FFFFFF", 
            fontFamily: "Roboto, Sans Serif", 
            fontWeight: "bold", 
            fontSize: '2.3rem',
            '@media (min-width: 768px)': {
              fontSize: '2.5rem',
              lineHeight: 1.3, 
              mb: 2
            },
          }}
        >
          How FemSwipe Works
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                height: '100%',
                background: '#444444',
                padding: '20px',
                borderRadius: '8px',
                textAlign: 'center',
                color: 'white',
              }}
            >
              <FavoriteIcon sx={{ fontSize: 60, marginBottom: '20px' }} />
              <Typography variant="h5" component="h2">
                Dating
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Find your perfect match and connect with like-minded individuals.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                height: '100%',
                background: '#444444',
                padding: '20px',
                borderRadius: '8px',
                textAlign: 'center',
                color: 'white',
              }}
            >
              <ChatIcon sx={{ fontSize: 60, marginBottom: '20px' }} />
              <Typography variant="h5" component="h2">
                Chat
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Connect and chat with other users in real-time.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                height: '100%',
                background: '#444444',
                padding: '20px',
                borderRadius: '8px',
                textAlign: 'center',
                color: 'white',
              }}
            >
              <PersonIcon sx={{ fontSize: 60, marginBottom: '20px' }} />
              <Typography variant="h5" component="h2">
                Profile
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Create and customize your profile to showcase your interests.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                height: '100%',
                background: '#444444',
                padding: '20px',
                borderRadius: '8px',
                textAlign: 'center',
                color: 'white',
              }}
            >
              <NotificationsIcon sx={{ fontSize: 60, marginBottom: '20px' }} />
              <Typography variant="h5" component="h2">
                Notifications
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Stay updated with notifications and alerts for new matches and messages.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
