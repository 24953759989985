import * as React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import TwitterIcon from '@mui/icons-material/Twitter';
import RedditIcon from '@mui/icons-material/Reddit';
import InstagramIcon from '@mui/icons-material/Instagram';
import logo from '../../../images/femswipe-logo.svg';

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer
      style={{
        backgroundColor: 'rgba(0,0,0,0.5)',
        padding: '20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: '#ffffff', // Set text color to white
      }}
    >
      <div>
        <img
          src={logo}
          alt="FemSwipe"
          style={{ height: '40px', width: 'auto' }}
        />
      </div>
      <div>
        <Typography variant="body2" color="inherit"> {/* Set text color to inherit */}
          &copy; {currentYear} FemSwipe Beta
        </Typography>
      </div>
      <div>
        <IconButton
          component={Link}
          href="https://twitter.com"
          target="_blank"
          rel="noopener"
          aria-label="Twitter"
          color="inherit" // Set icon color to inherit
        >
          <TwitterIcon />
        </IconButton>
        <IconButton
          component={Link}
          href="https://reddit.com"
          target="_blank"
          rel="noopener"
          aria-label="Reddit"
          color="inherit" // Set icon color to inherit
        >
          <RedditIcon />
        </IconButton>
        <IconButton
          component={Link}
          href="https://instagram.com"
          target="_blank"
          rel="noopener"
          aria-label="Instagram"
          color="inherit" // Set icon color to inherit
        >
          <InstagramIcon />
        </IconButton>
      </div>
    </footer>
  );
}
