import React, { useEffect, useState } from "react";
import moment from "moment";
import { Avatar, Box, Divider, CircularProgress, IconButton, SwipeableDrawer } from "@mui/material";
import { useSubscription, useMutation } from "@apollo/client";
import { ADD_FRIEND, SEND_FRIEND_REQUEST, BLOCK_USER, UNBLOCK_USER, ADD_DISLIKED_USER } from "../../../utils/mutations";
import { FRIEND_REQUEST_SENT_SUBSCRIPTION } from "../../../utils/subscriptions";
import NewMessageProfile from "../newMessageProfile";
import UserProfileCard from "../../UserProfileCard";
import SingleChat from "../../UserCard/SingleChat";
import StatusCircle from "../../StatusCircle";
import { ThumbDown, Favorite, Chat, Woman, Man } from '@mui/icons-material';

const RequestSubscriptions = ({
  handleAccept,
  meData,
  usersData,
  subscriptionFriendRequests,
  setSubscriptionFriendRequests,
  acceptedFriendRequests,
  setAcceptedFriendRequests,
  handleDecline,
  userId,
  username
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [loadingRequestId, setLoadingRequestId] = useState(null);
  const { data: newFriendRequestData, subscribeToMore, unsubscribe } = useSubscription(
    FRIEND_REQUEST_SENT_SUBSCRIPTION,
    {
      variables: { userId: meData?.me?._id }
    }
  );
  const [unblockUser] = useMutation(UNBLOCK_USER);
  const [blockUser] = useMutation(BLOCK_USER);
  const [addFriend] = useMutation(ADD_FRIEND);
  const [addDislikedUser] = useMutation(ADD_DISLIKED_USER);
  const [sendFriendRequest] = useMutation(SEND_FRIEND_REQUEST);

  const swiped = async (direction, nameToDelete, id) => {
    // setLastDirection(direction);
    if (direction === "right") {
      await addFriend({ variables: { friendId: id } });
      await sendFriendRequest({ variables: { friendId: id } });
    } else {
      await addDislikedUser({ variables: { dislikedUserId: id } });
    }
  };

  useEffect(() => {
    if (newFriendRequestData && usersData) {
      const newFriendRequest = newFriendRequestData.friendRequestSent;
      if (acceptedFriendRequests.includes(newFriendRequest._id)) {
        return;
      }
      const sender = usersData.users.find((user) => user._id === newFriendRequest.senderId);
      if (sender) {
        const enrichedFriendRequest = {
          ...newFriendRequest,
          senderId: newFriendRequest.senderId,
          senderPhotoURL: sender.photoURL
        };
        setSubscriptionFriendRequests((prevSubscriptionFriendRequests) => {
          if (!prevSubscriptionFriendRequests.some((request) => request._id === enrichedFriendRequest._id)) {
            return [...prevSubscriptionFriendRequests, enrichedFriendRequest];
          }
          return prevSubscriptionFriendRequests;
        });
      }
    }
  }, [newFriendRequestData, usersData, acceptedFriendRequests]);

  const handleLocalAccept = async (request) => {
    setLoadingRequestId(request._id);
    await handleAccept(request, request._id);
    setAcceptedFriendRequests((prev) => [...prev, request._id]);
    setSubscriptionFriendRequests((prevRequests) =>
      prevRequests.filter((prevRequest) => prevRequest._id !== request._id)
    );
    setLoadingRequestId(null);
  };

  const handleLocalDecline = async (request) => {
    setLoadingRequestId(request._id);
    await handleDecline(request, request._id);
    setSubscriptionFriendRequests((prevRequests) =>
      prevRequests.filter((prevRequest) => prevRequest._id !== request._id)
    );
    setLoadingRequestId(null);
  };

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    setShowChat(false);
  };

  return (
    <>
      {subscriptionFriendRequests.map((request, index, arr) => (
        request.senderId && (
          <React.Fragment key={request._id}>
            <div style={{ marginBottom: '10px' }} className={`request-container ${loadingRequestId === request._id ? 'loading' : ''}`}>
              {loadingRequestId === request._id && (
                <div className="loading-overlay">
                  <CircularProgress size={24} />
                </div>
              )}
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{ position: "relative", marginRight: "10px" }}
                >
                  <Avatar sx={{ width: '60px', height: '60px', background: request?.senderRole === 'admirer' ? '#9c27b0' : '#005b80' }}
                    src={request.senderPhotoURL}>
                    {request.senderRole === 'admirer' ? <Woman sx={{ color: '#bd59ce', mt: 4, fontSize: '50px' }} /> : <Man sx={{ color: '#1785b2', mt: 4, fontSize: '50px' }} />}
                  </Avatar>
                  <div
                    style={{
                      position: "absolute",
                      bottom: "0px",
                      right: "0px"
                    }}
                  >
                    <StatusCircle lastActive={request.lastActive} />
                  </div>
                </div>
                <div
                  style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}
                >
                  <span style={{ fontSize: "14px", fontWeight: "bold" }}>{request.senderUsername}</span>
                  <span style={{ fontSize: "12px" }}>Swiped you! Swipe back!</span>
                </div>
                <span style={{ fontSize: "10px", marginLeft: "auto", paddingRight: "2%", maxWidth: '70px', width: '100%' }}>
                  {moment(parseInt(request.createdAt)).fromNow()}
                </span>
                <div className="button-container">
                  <IconButton
                    onClick={() => handleLocalAccept(request)}
                    style={{ marginRight: "5px" }}
                    disabled={loadingRequestId !== null}
                  >
                    <Favorite sx={{ color: 'white' }} />
                  </IconButton>
                  <IconButton
                    onClick={() => handleLocalDecline(request)}
                    disabled={loadingRequestId !== null}
                  >
                    <ThumbDown sx={{ color: 'white' }} />
                  </IconButton>
                </div>
              </div>
            </div>
            {loadingRequestId === request._id && (
              <NewMessageProfile
                open={true}
                onClose={() => { }}
                selectedUser={request}
              />
            )}
            {index !== arr.length - 1 && (
              <div className="divider-container">
                <Divider className="divider" />
              </div>
            )}
            <SwipeableDrawer
              className="grid-drawer"
              anchor="bottom"
              open={isDrawerOpen}
              onClose={handleDrawerClose}
            >
              {showChat ? (
                <SingleChat onClose={() => setShowChat(false)} user={request.friendId} myId={userId} myUsername={username} />
              ) : (
                <>
                  <UserProfileCard user={request.friendId} />
                  <Box sx={{ display: 'flex', gap: '0.5rem', justifyContent: 'space-between', px: 2, py: 2 }}>
                    <IconButton onClick={() => swiped('left', request.senderUsername, request._id)} sx={{ color: 'white', backgroundColor: 'transparent', fontSize: '1rem' }}>
                      <ThumbDown />
                    </IconButton>
                    <IconButton onClick={() => setShowChat(true)} sx={{ color: 'white', backgroundColor: 'transparent', fontSize: '1rem' }}>
                      <Chat />
                    </IconButton>
                    <IconButton onClick={() => swiped('right', request.senderUsername, request._id)} sx={{ color: 'white', backgroundColor: 'transparent', fontSize: '1rem' }}>
                      <Favorite />
                    </IconButton>
                  </Box>
                </>

              )}
            </SwipeableDrawer>
          </React.Fragment>
        )
      ))}
    </>
  );
};

export default RequestSubscriptions;
