import React, { useState } from 'react';
import { Typography, Box, Grid, CardMedia, Modal, IconButton } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const UserIdentity = ({ user, setIsPhotoModalOpen }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [touchStartX, setTouchStartX] = useState(0);
  const [showFullDescription, setShowFullDescription] = useState(false);

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    setModalOpen(true);
    setIsPhotoModalOpen(true);
  };

  const findNextNonEmptyIndex = (startIndex) => {
    for (let i = startIndex + 1; i < user.photoURL.length; i++) {
      if (user.photoURL[i] !== "") {
        return i;
      }
    }
    return startIndex;
  };

  const findPrevNonEmptyIndex = (startIndex) => {
    for (let i = startIndex - 1; i >= 0; i--) {
      if (user.photoURL[i] !== "") {
        return i;
      }
    }
    return startIndex;
  };

  const handleImageNext = () => {
    setSelectedImageIndex((prevIndex) => findNextNonEmptyIndex(prevIndex));
  };

  const handleImagePrev = () => {
    setSelectedImageIndex((prevIndex) => findPrevNonEmptyIndex(prevIndex));
  };

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    const touchEndX = e.touches[0].clientX;
    const touchDiff = touchEndX - touchStartX;

    if (touchDiff > 50 && !isFirstImage) { // Swipe right
      handleImagePrev();
    } else if (touchDiff < -50 && !isLastImage) { // Swipe left
      handleImageNext();
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setIsPhotoModalOpen(false);
  };

  const showArrows = user.photoURL.length > 1;
  const isFirstImage = selectedImageIndex === 0;
  const isLastImage = selectedImageIndex === user.photoURL.length - 1;


  return (
    <Box sx={{ px: 2 }}>
      <Box sx={{ textAlign: "left", marginBottom: ".9rem" }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant='subtitle1' component="h6" sx={{ fontFamily: '"Roboto Condensed", sans-serif', fontSize: '1rem', mb: 1 }}>About Me: </Typography>
          <div style={{ position: 'relative' }}>
            <div style={{
              overflow: 'hidden',
            }}>
              <span style={{ fontSize: "0.9rem", fontWeight: 300, color: "white", padding: "0.5em 1em", border: "1px solid #2c2c2c", borderRadius: "10px 10px 10px 0px", background: "#151515", display: 'block' }}>
                {user.description}
              </span>
            </div>
          </div>
        </Box>
      </Box>
      <Grid container>
        {user.photoURL.map((url, index) => (
          url !== "" && (
            <Grid item xs={3} key={index} style={{ padding: '4px' }}>
              <CardMedia
                component="img"
                height="100"
                image={url}
                alt={`Image ${index + 1}`}
                onClick={() => handleImageClick(index)}
                onTouchStart={() => handleImageClick(index)}
              />
            </Grid>
          )
        ))}
      </Grid>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          <CardMedia
            component="img"
            src={user.photoURL[selectedImageIndex]}
            alt={`Fullscreen ${selectedImageIndex + 1}`}
            sx={{
              maxWidth: "80vw",
              maxHeight: "80vh",
              objectFit: 'contain',
            }}
          />
          {showArrows && (
            <Box style={{display: 'flex', justifyContent:'space-between', position: 'absolute', width: '100%', top: '50%', transform: 'translateY(-50%)'}}>
              <IconButton onClick={handleImagePrev} onTouchStart={handleImagePrev} style={{ opacity: isFirstImage ? 0 : 1 }}>
                <KeyboardArrowLeftIcon style={{ color: 'white' }} />
              </IconButton>
              <IconButton onClick={handleImageNext} onTouchStart={handleImageNext} style={{ opacity: isLastImage ? 0 : 1 }}>
                <KeyboardArrowRightIcon style={{ color: 'white' }} />
              </IconButton>
            </Box>
          )}
        </Box>
      </Modal>
    </Box>
  );
}

export default UserIdentity;
