import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Container, Slider, Grid, Paper, Backdrop, Chip } from '@mui/material';
import ScaleIcon from '@mui/icons-material/Scale';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'transparent',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: 'white',
  width: '100%'
}));

const RowContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center', // Center the content horizontally
  gap: '10px',
});

const ChipWrapper = styled(Chip)(({ theme }) => ({
  backgroundColor: '#005b80',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: 'white',
  cursor: 'pointer',
  height: "5vh",
  width: "100vw"
}));

const Weight = ({ formState, setFormState }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [sliderValue, setSliderValue] = useState(formState.weight);

  const handleComponentClick = () => {
    setIsEditing(true);
  };

  const handleBackdropClick = () => {
    setIsEditing(false);
    setFormState((prevState) => ({
      ...prevState,
      weight: sliderValue.toString(),
    }));
  };

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  const handleSliderChangeCommitted = (event, newValue) => {
    setIsEditing(false);
    setFormState((prevState) => ({
      ...prevState,
      weight: newValue.toString(),
    }));
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <Grid item container xs={12}>
        <Item elevation={0}>
          <RowContainer>
            <ChipWrapper
              icon={<ScaleIcon style={{ color: 'white' }} />}
              label="Weight"
              onClick={handleComponentClick}
            />
          </RowContainer>
          {isEditing && (
            <Backdrop
              open={isEditing}
              onClick={handleBackdropClick}
              style={{ pointerEvents: 'auto', zIndex: "999", backgroundColor: "rgba(0, 0, 0, 0.8)" }}>
              <Container maxWidth="sm">
                <Slider
                  aria-labelledby="discrete-slider-always"
                  step={1}
                  min={90}
                  max={300}
                  name="weight"
                  type="weight"
                  id="weight"
                  value={Number(sliderValue)} // Convert to number
                  valueLabelDisplay="on"
                  valueLabelFormat={(value) => `${value} lbs.`}
                  onChange={handleSliderChange}
                  onChangeCommitted={handleSliderChangeCommitted}
                />
              </Container>
            </Backdrop>
          )}
        </Item>
      </Grid>
    </div>
  );
};

export default Weight;
