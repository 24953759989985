import React from 'react';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';

function Updates() {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <div style={{ padding: '20px', borderRadius: '8px', maxWidth: '600px', margin: '0 auto', marginBottom: '20px' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                    variant="outlined"
                    onClick={handleGoBack}
                    sx={{ color: 'white', borderColor: 'white', marginBottom: '20px' }}
                >
                    Back
                </Button>
            </div>
            <h2 style={{ textAlign: 'center', fontSize: '24px', fontWeight: 'bold' }}>FemSwipe Beta 1.1.2 Update Highlights</h2>
            <h4 style={{ fontSize: '14px', textAlign: 'center' }}>Updated 01/02/2024</h4>

            <h3 style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '20px' }}>1. Bug Fixes</h3>
            <p style={{ fontSize: '16px' }}>
                <strong>Location Update:</strong> Resolved issues where clicking "Update Location" in Edit Profile did not update the location.
            </p>
            <p style={{ fontSize: '16px' }}>
                <strong>Profile Touch Opening:</strong> Enhanced performance for opening profiles during scrolling, ensuring a smoother experience.
            </p>

            <h3 style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '20px' }}>2. Features/Changes</h3>
            <p style={{ fontSize: '16px' }}>
                <strong>Message non-swiped users:</strong> Directly message users by clicking on their profile. See and receive messages from new contacts.
            </p>
            <p style={{ fontSize: '16px' }}>
                <strong>New User Profile Drawer:</strong> Open concise user profiles with options to "Favorite," "Dislike," view photos, and start a direct chat.
            </p>
            <p style={{ fontSize: '16px' }}>
                <strong>Matches as Swipes:</strong> Matches are now presented as swipes in the inbox, allowing direct interaction before reciprocation.
            </p>
            <p style={{ fontSize: '16px' }}>
                <strong>Filtering:</strong> Easily filter the main dashboard to see favorites, profiles with photos, and more.
            </p>
            <p style={{ fontSize: '16px' }}>
                <strong>Roulette Grid and List View:</strong> Improved user experience in the Roulette tab with grid and list view options.
            </p>

            <h3 style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '20px' }}>Known Bugs</h3>
            <ul style={{ fontSize: '16px' }}>
                <li>
                    <strong>Safari chat on iOS:</strong> Tapping outside the text field may be required to open the keyboard.
                </li>
                <li>
                    <strong>Profile Info Display on Desktop Browsers:</strong> Clicking to view profile information may exhibit a style issue, causing it to appear broken or not extend to the top of the container.
                </li>
                <li>
                    <strong>In-App Swipe Notification:</strong> There may be an issue where in-app swipe notifications do not appear within the application, but rest assured, you should still receive email notifications for swipes.
                </li>

            </ul>

            <h3 style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '20px' }}>Road Map</h3>
            <ul style={{ fontSize: '16px' }}>
                <li>
                    <strong>Premium features</strong>
                </li>
                <li>
                    <strong>iOS and Android app</strong>
                </li>
            </ul>

            <p style={{ fontSize: '16px', marginTop: '20px' }}>
                These enhancements aim to improve user experience on FemSwipe. Your continued support and feedback are invaluable. Feel free to donate to cover server costs.
            </p>
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to="/donate"
                >
                    Please consider donating
                </Button>
            </div>
        </div>
    );
}

export default Updates;
