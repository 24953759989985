import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { CHANGE_EMAIL } from "../../utils/mutations"; // Import your mutation
import { Alert, Button, TextField, IconButton, List, ListItem, Stack, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ChangeEmailForm = ({ onClose }) => {
  const [newEmail, setNewEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState(""); // State for success message

  const [changeEmailMutation] = useMutation(CHANGE_EMAIL);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newEmail || !password) {
      setError("All fields are required");
      return;
    }

    try {
      const { data } = await changeEmailMutation({
        variables: { newEmail, password },
      });

      // Handle success, update your UI, and show success message
      setSuccessMessage(`Email changed to ${data.changeEmail.email}`);
    } catch (error) {
      setError("Failed to change email. Please check your password.");
      console.error("Error changing email:", error);
    }

    setNewEmail("");
    setPassword("");
  };

  return (
    <form onSubmit={handleSubmit}>
      <div style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid rgba(255,255,255,0.2)', paddingBottom: '0.5em', paddingTop: '0.5em' }}>
        <IconButton color="white" onClick={onClose}>
          <ArrowBackIcon sx={{ color: 'white' }} />
        </IconButton>
        <h3 style={{ paddingLeft: 10, fontWeight: 400 }}>Change Email</h3>
      </div>
      {error && (
        <Stack sx={{ width: '100%' }} >
          <Alert sx={{ color: 'rgb(204, 232, 205)' }} variant="outlined" severity="success">
            {error}
          </Alert>
        </Stack>
      )}
      <List>
        <ListItem>
          {successMessage && (
            <Stack sx={{ width: '100%' }} >
              <Alert sx={{ color: 'rgb(204, 232, 205)' }} variant="outlined" severity="success">
                {successMessage}
              </Alert>
            </Stack>
          )}
        </ListItem>
        {!successMessage && (
          <>
            <ListItem>
              <TextField
                sx={{ mt: 0 }}
                className="form-input"
                label="New Email"
                type="email"
                fullWidth
                margin="normal"
                variant="outlined"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
              />
            </ListItem>
            <ListItem sx={{ pt: 0 }}>
              <TextField
                sx={{ mt: 0 }}
                className="form-input"
                label="Confirm Current Password"
                type="password"
                fullWidth
                margin="normal"
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </ListItem>
            <ListItem>
              <Button type="submit" variant="contained" color="secondary">
                Change Email
              </Button>
            </ListItem>
          </>
        )}
      </List>
    </form>
  );
};

export default ChangeEmailForm;
