import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { Container, Box, Chip } from '@mui/material';
import { EDIT_USER, ADD_USER_KINKS, DELETE_USER_KINKS } from "../../utils/mutations";
import { QUERY_ME, QUERY_USER } from "../../utils/queries";
import auth from "../../utils/auth";
import "../../css/editprofile.css";
import CloseIcon from '@mui/icons-material/Close';
import Height from './height';
import Weight from './weight'
import Role from './role';
import Ethnicity from './ethnicity';
import KinkChoices from './kinkchoices';
import AgeProfile from './ageprofile';
import Description from './description';
import Gender from "./gender";
import Position from "./position";
import EditPhotos from './editPhotos';
import UpdateLocation from "./updateLocation";

const EditProfile = ({ handleBackdropClose }) => {
  // State variables
  // eslint-disable-next-line
  const [profileHeightUpdated, setProfileHeightUpdated] = useState(false);
  // eslint-disable-next-line
  const [profileUpdated, setProfileUpdated] = useState(false);
  // eslint-disable-next-line
  const [isOpen, setIsOpen] = useState(true);
  // eslint-disable-next-line
  const [showStats, setShowStats] = useState(false);
  const [state, setState] = React.useState({
    left: true,
  });
  const [photoURL, setPhotoURL] = useState([null, null, null, null]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsOpen(open);
    setState({ ...state, [anchor]: open });
  };
// eslint-disable-next-line
  const [selectableKinks, setSelectableKinks] = useState([
    'BIMBOFICATION',
    'BDSM',
    'BONDAGE',
    'BREEDING',
    'CHASTITY',
    'DOMINATION',
    'FEET',
    'FEMINIZATION',
    'GIRLFRIENDEXPERIENCE',
    'GROUP',
    'HUMILIATION',
    'HYPNO',
    'LATEX',
    'LINGERIE',
    'MAID',
    'RAW',
    'SISSIFICATION',
    'SISSYBRIDE',
    'SHOWOFF',
    'VERBAL'
  ]);

  // Mutations
  // eslint-disable-next-line
  const [addUserKinks, { error: addUserKinksError }] = useMutation(ADD_USER_KINKS);
  // eslint-disable-next-line
  const [deleteUserKinks, { error: deleteUserKinksError }] = useMutation(DELETE_USER_KINKS);

  // Event handlers
  const handleAddKink = (kink) => async (event) => {
    event.stopPropagation();
    try {
      await addUserKinks({
        variables: { kinks: [kink] },
      });
      auth.loggedIn();
      setProfileUpdated(true);
    } catch (e) {
      console.error(e);
    }
  };

  const handleDeleteKink = (kink) => {
    return async () => {
      try {
        await deleteUserKinks({
          variables: { kinks: [kink] },
        });
        auth.loggedIn();
        setProfileUpdated(true);
      } catch (e) {
        console.error(e);
      }
    };
  };

  // Retrieve username from URL params
  const { username: userParam } = useParams();

  // Query data
  const { loading, data } = useQuery(userParam ? QUERY_USER : QUERY_ME, {
    variables: { username: userParam }
  });

  console.log(data)

  const user = data?.me || data?.user || {};

  // Form state
  const [formState, setFormState] = useState({
    age: user.age,
    height: user.height,
    weight: user.weight,
    role: user.role,
    kinks: user.kinks,
    ethnicity: user.ethnicity,
    gender: user.gender,
    position: user.position,
    username: user.username,
    description: user.description,
    location: user.location,
  });

  // Mutation for editing user
  // eslint-disable-next-line
  const [editUser, { error }] = useMutation(EDIT_USER);

  // Handle form input changes
  const handleChange = (e) => {
    setProfileHeightUpdated(false);
    var value = e.target.type === "true" ? e.target.checked : e.target.value;
    const name = e.target.name;

    if (e.target.name === 'height') {
      var heightValue = value.toString();
      value = toFeetandInch(heightValue);

      function toFeetandInch(inches) {
        return (parseInt(inches / 12) + " ' " + Math.round(inches % 12, 1) + ' " ');
      }

      heightValue = value;
    }

    if (e.target.name === 'age') {
      var agevalue = e.target.type === "true" ? e.target.checked : e.target.value;
      var today = new Date();
      var birthDate = new Date(value);
      var age = today.getFullYear() - birthDate.getFullYear();
      // eslint-disable-next-line
      agevalue = age.toString();
    }

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSlider = (e) => {
    setProfileUpdated(false);
    var value = e.target.type === "true" ? e.target.checked : e.target.value;
    const name = e.target.name;

    var weightValue = value.toString();
    value = weightValue;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  // Handle form submission
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      await editUser({
        variables: { ...formState },
      });
      auth.loggedIn();
      setProfileUpdated(true);
    } catch (error) {
      console.error(error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Container maxWidth="sm">
      <div style={{ flexDirection: 'row', width: '100%' }}>
        <div style={{ flexGrow: 1 }}></div>
        <div>
          <div>
            {['EDIT PROFILE'].map((anchor) => (
              <React.Fragment key={anchor}>
                <Box
                  sx={{
                    width: anchor === 'left' || anchor === 'right' ? 'auto' : "100%",
                    '@media (max-width: 600px)': {
                      width: anchor === 'left' || anchor === 'right' ? 'auto' : "100%",
                    },
                  }}
                  role="presentation"
                  onClick={(e) => e.stopPropagation()}
                  onKeyDown={toggleDrawer(anchor, true)}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    sx={{ pb: 1 }}
                  >
                    <Chip
                      icon={<CloseIcon style={{ color: "#fff" }} />}
                      label="Save"
                      onClick={(e) => {
                        toggleDrawer(anchor, false)(e);
                        handleFormSubmit(e);
                        handleBackdropClose();
                      }}
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#005b80",
                        color: "#fff",
                      }}
                    />
                  </Box>
                  <form className="form-container">
                    <div className="form-column" style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '1vh' }}>
                      {/* <hr style={{ borderTop: ".5px solid grey", width: "90%" }} /> */}
                      <Role formState={formState} handleChange={handleChange} />
                      <AgeProfile
                        formState={formState}
                        handleChange={handleChange}
                      />
                      <Gender formState={formState} handleChange={handleChange} />
                      <Position formState={formState} handleChange={handleChange} />
                      <Ethnicity formState={formState} handleChange={handleChange} />

                      <Height formState={formState} setFormState={setFormState} />
                      <Weight formState={formState} setFormState={setFormState} handleSlider={handleSlider} />
                      <EditPhotos formState={formState} photoURL={photoURL} setPhotoURL={setPhotoURL} />

                      <KinkChoices
                        user={user}
                        selectableKinks={selectableKinks}
                        handleAddKink={handleAddKink}
                        handleDeleteKink={handleDeleteKink}
                      />
                      <Description formState={formState} handleChange={handleChange} />
                      <UpdateLocation formState={formState} handleChange={handleChange}/>
                      {/* <hr style={{ borderTop: ".5px solid grey", width: "90%" }} /> */}
                    </div>
                  </form>
                </Box>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </Container>

  );
};

export default EditProfile;
