import React from 'react';
import { storage } from '../../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import IconButton from '@mui/material/IconButton';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Compressor from 'compressorjs';
import { v4 as uuidv4 } from 'uuid';

function ChatFileUpload({ postImageMessage }) {

  const handleChange = async (event) => {
    if (event.target.files[0]) {
      const file = event.target.files[0];

      // Check if the selected file is an image
      if (!file.type.startsWith('image/')) {
        console.log('Selected file is not an image.');
        return;
      }

      // Additional check to ensure no video files are selected
      if (file.type.startsWith('video/')) {
        console.log('Selected file is a video. Videos are not allowed.');
        return;
      }

      // Generate a unique file name using UUID
      const uniqueFileName = `${uuidv4()}-${file.name}`;


      // Compress the file using compressorjs
      const compressedFile = await new Promise((resolve) => {
        new Compressor(file, {
          quality: 0.6,
          success: (result) => {
            resolve(result);
          },
          error: (error) => {
            console.error(error);
            resolve(file);
          },
        });
      });
      const fileRef = ref(storage, `chatfiles/${uniqueFileName}`);
      const upload = await uploadBytes(fileRef, compressedFile);
      const getURL = await getDownloadURL(upload.ref);
      await postImageMessage(getURL, file.name);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '5vh',
        marginLeft: 'auto',
      }}
    >
      <form>
        <IconButton color='primary' aria-label='upload file' component='label'>
          <input
            hidden
            accept="image/*"
            type="file"
            name="chatfile"
            id="chatfile"
            onChange={handleChange}
          />
          <CameraAltIcon />
        </IconButton>
        <button type='submit' style={{ display: 'none' }}></button>
      </form>
    </div>
  );
}

export default ChatFileUpload;
