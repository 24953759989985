export const Kinks = {
  BIMBOFICATION: "BIMBOFICATION",
  BDSM: "BDSM",
  BONDAGE: "BONDAGE",
  BREEDING: "BREEDING",
  CHASTITY: "CHASTITY",
  DOMINATION: "DOMINATION",
  FEET: "FEET",
  FEMINIZATION: "FEMINIZATION",
  GIRLFRIENDEXPERIENCE: "GIRLFRIENDEXPERIENCE",
  GROUP: "GROUP",
  HUMILIATION: "HUMILIATION",
  HYPNO: "HYPNO",
  LATEX: "LATEX",
  LINGERIE: "LINGERIE",
  MAID: "MAID",
  RAW: "RAW",
  RECORDING: "RECORDING",
  SISSIFICATION: "SISSIFICATION",
  SISSYBRIDE: "SISSYBRIDE",
  SHOWOFF: "SHOWOFF",
  VERBAL: "VERBAL"
};

export const kinkEmojis = {
  [Kinks.BIMBOFICATION]: "👩‍🎓",
  [Kinks.BDSM]: "🕯️",
  [Kinks.BONDAGE]: "🪢",
  [Kinks.BREEDING]: "🤰",
  [Kinks.CHASTITY]: "🔒",
  [Kinks.DOMINATION]: "👑",
  [Kinks.FEMINIZATION]: "👗",
  [Kinks.FEET]: "🦶",
  [Kinks.GIRLFRIENDEXPERIENCE]: "💞",
  [Kinks.GROUP]: "👥",
  [Kinks.HUMILIATION]: "🤡",
  [Kinks.HYPNO]: "🌀",
  [Kinks.LATEX]: "👩‍✈️",
  [Kinks.LINGERIE]: "🎀",
  [Kinks.MAID]: "👩‍🍳",
  [Kinks.RAW]: "🥩",
  [Kinks.RECORDING]: "📷",
  [Kinks.SISSIFICATION]: "💅",
  [Kinks.SISSYBRIDE]: "👰",
  [Kinks.SHOWOFF]: "🔎",
  [Kinks.VERBAL]: "🗣️",
};