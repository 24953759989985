// Hero.js
import React from "react";
import { Grid, Typography, Button, Container, Card, CardContent, Box } from "@mui/material";
import { Link } from "react-router-dom"; // Import the Link component
import badPhone from "../../../images/bad-phone.png";
import Logo from "../../../components/Logo";
import '../styles.css'

const Hero = () => {
  return (
    <div className='background-idea'>
       <Container sx={{overflowX: 'hidden'}} maxWidth="lg">
      <Grid container spacing={2} style={{minHeight: '100vh', height: '100%'}}>
        {/* Left Grid */}
        <Grid item xs={12} sm={12} md={7} lg={6} sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{mt: 8, '@media (min-width: 768px)': {mt: 0}}}>
            <Logo />
            <Card
              variant="outlined"
              className="login-content"
              sx={{
                backgroundColor: "#000000",
                py: 1,
                px: 3,
                width: "100%",
                maxWidth: "100%",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <CardContent>
                <Typography
                  component="h1"
                  variant="h3"
                  sx={{ 
                    color: "#FFFFFF", 
                    fontFamily: "Roboto, Sans Serif", 
                    fontWeight: "bold", 
                    fontSize: '2.3rem',
                    '@media (min-width: 768px)': {
                      fontSize: '2rem',
                      lineHeight: 1.3, 
                      mb: 2
                    },
                  }}
                >
                  Connect With Crossdressers, Femboys, Sissies, Trans Women & Their Admirers For Free
                </Typography>
                <Typography variant="body1" sx={{ color: "#FFFFFF", lineHeight: 2, mb: 3 }}>
                  Meet and chat with crossdressers, femboys, sissies, trans women and their admirers in your area. Free sign-up and chat, find sissies/crossdressers,
                  trans women and admirers in your area and filter by specific kinks.
                </Typography>
                {/* Replace the onClick handlers with Link components */}
                <Button sx={{ mr: 2 }} variant="contained" color="primary" component={Link} to="/login">
                  Login
                </Button>
                <Button variant="contained" color="secondary" component={Link} to="/login?signup=true">
                  Sign Up
                </Button>
              </CardContent>
            </Card>
          </Box>
        </Grid>
        {/* Right Grid */}
        <Grid item xs={12} sm={12} md={5} lg={6} sx={{ display: "flex", alignItems: "center", position: "relative" }}>
          <img className='home-phone' src={badPhone} alt="Placeholder" style={{ alignSelf: "center" }} />
        </Grid>
      </Grid>
    </Container>
    </div>
   
  );
};

export default Hero;
