import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { UNBLOCK_USER } from '../../utils/mutations';
import { GET_BLOCKED_USERS, QUERY_ME } from '../../utils/queries';
import { Avatar, Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useRecoilState } from 'recoil';
import { meState } from '../../recoil/atoms';
import { Woman, Man } from '@mui/icons-material';

const BlockedUsersList = ({ myId, onClose }) => {
  const { refetch: refetchMe } = useQuery(QUERY_ME);

  const [setMe] = useRecoilState(meState);

  const { data, loading, error, refetch } = useQuery(GET_BLOCKED_USERS, {
    variables: { myId },
    fetchPolicy: 'network-only',
  });

  const [unblockUser] = useMutation(UNBLOCK_USER);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const handleUnblockUser = async () => {
    if (selectedUser) {
      try {
        const { data } = await unblockUser({ variables: { userId: selectedUser._id } });
        if (data.unblockUser.success) {
          console.log('User unblocked successfully.');
          await refetch(); // Refetch the data after unblocking the user
          await refetchMe();
          const { data } = await refetchMe();
          console.log('what is data before state update: ', data)
          setMe(data);
        } else {
          console.log('Error unblocking user.');
        }
      } catch (error) {
        console.error('Error unblocking user:', error);
      }
    }
    setSelectedUser(null);
    setIsConfirmationOpen(false);
  };

  const openConfirmationDialog = (user) => {
    setSelectedUser(user);
    setIsConfirmationOpen(true);
  };

  const closeConfirmationDialog = () => {
    setSelectedUser(null);
    setIsConfirmationOpen(false);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IconButton color="white" onClick={onClose}>
          <ArrowBackIcon sx={{ color: 'white' }} />
        </IconButton>
        <h3 style={{ paddingLeft: 10, fontWeight: 400 }}>Unblock Users</h3>
      </div>
      <List>
        {data?.getBlockedUsers?.blockedUsers.map((user) => (
          <ListItem
            sx={{
              borderTop: '1px solid rgba(255,255,255,0.2)',
              borderBottom: '1px solid rgba(255,255,255,0.2)'
            }}
            key={user._id}
            onClick={() => openConfirmationDialog(user)}
          >
            <ListItemAvatar>
              <Avatar sx={{ background: user.role === 'admirer' ? '#9c27b0' : '#005b80', }}
                src={user.photoURL[0]}>
                {user.role === 'admirer' ? <Woman sx={{ color: '#bd59ce', mt: 2, fontSize: '50px' }} /> : <Man sx={{ color: '#1785b2', mt: 4, fontSize: '50px' }} />}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={user.username} />
          </ListItem>
        ))}
      </List>
      <Dialog open={isConfirmationOpen} onClose={closeConfirmationDialog}>
        <DialogTitle>Unblock User</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to unblock {selectedUser?.username}?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmationDialog}>Cancel</Button>
          <Button onClick={handleUnblockUser}>Unblock</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BlockedUsersList;
