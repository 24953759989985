import React, { useState, useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Auth from '../../utils/auth';
import { Avatar, Box, Container, Collapse, Chip, Grid, Card, CardMedia, CardContent, Typography, IconButton, Backdrop, List, ListItem } from '@mui/material';
import EditProfile from '../EditProfile';
import { Woman, Man, Info } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useRecoilValue } from 'recoil';
import { meState } from '../../recoil/atoms';
import { kinkEmojis } from '../EditProfile/kinks';
import './Profile.css'

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const Profile = ({ onClose }) => {
  const [expanded, setExpanded] = useState(false);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [photoURL, setPhotoURL] = useState([null, null, null, null]);
  const { username: userParam } = useParams();
  ///convert date to age//  
  const getAge = (birthdate) => {
    const today = new Date();
    const birthDate = new Date(birthdate);
    let age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  let me = useRecoilValue(meState);
  me = me.me;
  const isAvatarEmpty = me.photoURL?.every((str) => str === "");

  useEffect(() => {
    setPhotoURL(me.photoURL || [null, null, null, null]);
  }, [me.photoURL]);

  const handleBackdropOpen = () => {
    setBackdropOpen(true);
  };

  const handleBackdropClose = () => {
    setBackdropOpen(false);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  if (Auth.loggedIn() && Auth.getProfile().data.username === userParam) {
    return <Navigate to="/profile" />;
  }

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={onClose}>
          <ArrowBackIcon sx={{ color: 'white', mr: 1 }} />
        </IconButton>
        <h3 style={{ marginLeft: '10px', fontWeight: 400 }}>Profile</h3>
        <Chip
          label="Edit Profile"
          sx={{ ml: 'auto', fontSize: '10px', color: 'white', backgroundColor: "#005b80", mr: 2 }}
          onClick={handleBackdropOpen}
        />
      </div>
      <List sx={{ borderTop: '1px solid rgba(255,255,255,0.2)' }}>
        <ListItem>
          <Card variant="outlined" sx={{ borderRadius: 2, maxWidth: 450, width: '100%', m: 3, backgroundColor: "rgb(0, 0, 0, .5)", color: "#fff" }}>
            <Avatar
              variant="square"
              sx={{ objectFit: "cover", width: "100%", height: "55vh", background: me.role === 'admirer' ? '#005b80' : '#9c27b0', }}
              src={!!me.photoURL[0] ? me.photoURL[0] : ""}
              alt={me.username}
            >
              {me.role === 'admirer' ? <Man sx={{ fontSize: '400px', color: '#1785b2', mt: 10 }} /> : <Woman sx={{ fontSize: '400px', color: '#bd59ce', mt: 10 }} />}
            </Avatar>
            <Typography variant="h5" sx={{ color: 'white', textAlign: 'center', mt: 1 }}>
              {me.username} <span style={{ fontStyle: 'italic' }}>{getAge(me.age)}</span>
            </Typography>
            <CardContent>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography variant="h6" sx={{ color: "white", marginTop: 0, fontSize: 12, fontFamily: '"Roboto Condensed", sans-serif' }}>
                    <Info sx={{ fontSize: '0.9rem', opacity: 0.7, position: 'relative', top: '3px', left: '-3px' }} />
                    <span style={{ marginRight: 2 }}>{me.gender ? me.gender : ''}</span>
                    {me.position ? <span style={{ marginRight: 2 }}>{me.position}</span> : null}
                    {me.ethnicity ? <span style={{ marginRight: 2 }}>{me.ethnicity}</span> : null}
                    {me.height ? <span style={{ marginRight: 2 }}>{me.height}</span> : null}
                    {me.weight ? <span>{me.weight} lbs</span> : null}
                  </Typography>
                </Grid>
                <Grid item>
                </Grid>
              </Grid>
              <Backdrop
                style={{ backgroundColor: 'rgba(0,0,0,0.8)' }}
                className='backdrop'
                open={backdropOpen}
                onClick={handleBackdropClose}
              >
                <Container maxWidth="md" style={{ maxWidth: '100%' }}>
                  <EditProfile handleBackdropClose={handleBackdropClose} />
                </Container>
              </Backdrop>
            </CardContent>
            {backdropOpen ? null : ( // Conditionally render Collapse and ExpandMoreIcon
              <>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <Box sx={{ px: 1 }}>
                    <Typography variant="body1" sx={{ color: 'white' }}>
                      {me.description}
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                      {me.kinks.map((kink) => (
                        <Chip
                          label={`${kinkEmojis[kink]} ${kink.toLowerCase()}`}
                          sx={{ mr: 1, mb: 1, fontSize: "10px", color: 'white', backgroundColor: "#005b80" }}
                          key={kink}
                        />
                      ))}
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <Grid container spacing={2}>
                        {photoURL.slice(1).map((url, index) => (
                          <Grid item xs={4} key={index}>
                            {url && <CardMedia component="img" height="100" image={url} alt={`Image ${index + 1}`} />}
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Box>
                </Collapse>
                <ExpandMore
                  expand={expanded}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon sx={{ color: 'white' }} />
                </ExpandMore>
              </>
            )}
          </Card>
        </ListItem>
      </List>
    </div>
  );
};

export default Profile;
