import React, { useState, useEffect } from 'react';
import auth from '../../utils/auth';
import BlockedUsersList from '../../components/BlockedUsersList';
import ChangeEmailForm from './ChangeEmailForm';
import ChangePasswordForm from './ChangePasswordForm';
import DeleteAccount from '../../components/DeleteAccount';
import { Container, Backdrop, IconButton, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Drawer from '@mui/material/Drawer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LogoutIcon from '@mui/icons-material/Logout';
import { useRecoilValue } from 'recoil';
import { meState } from '../../recoil/atoms';
import EditProfile from "../../components/EditProfile";
import UpdateLocation from '../../components/EditProfile/updateLocation';
import { Link } from 'react-router-dom';


const Settings = ({ onClose }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isEmailDrawerOpen, setIsEmailDrawerOpen] = useState(false);
  const [isPasswordDrawerOpen, setIsPasswordDrawerOpen] = useState(false);
  const [backdropOpen, setBackdropOpen] = useState(false);

  let me = useRecoilValue(meState)
  me = me.me
  const myId = me?._id;

  const handleBlockedUsersClick = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const handleBackClick = () => {
    onClose(); // Call the onClose function when the back arrow is clicked
  };

  const handleEmailDrawerOpen = () => {
    setIsEmailDrawerOpen(true);
  };

  const handleEmailDrawerClose = () => {
    setIsEmailDrawerOpen(false);
  };

  const handlePasswordDrawerOpen = () => {
    setIsPasswordDrawerOpen(true);
  };

  const handlePasswordDrawerClose = () => {
    setIsPasswordDrawerOpen(false);
  };

  const handleBackdropOpen = () => {
    setBackdropOpen(true);
  };

  const handleBackdropClose = () => {
    setBackdropOpen(false);
  };

  const handleChangePassword = (newPassword) => {
    console.log("Changing password to:", newPassword);
  };

  const handleChangeEmail = (newEmail, password) => {
    console.log("Changing email to:", newEmail);
    console.log("Password:", password);
  };

  const logout = (e) => {
    e.preventDefault();
    auth.logout();
  };

  return (
    <div style={{ width: "100%" }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IconButton color="white" onClick={handleBackClick}>
          <ArrowBackIcon sx={{ color: 'white' }} />
        </IconButton>
        <h3 style={{ marginLeft: '10px', fontWeight: 400 }}>Settings</h3>
      </div>
      <List component="nav">
        <ListItemButton
          onClick={handleEmailDrawerOpen}
          sx={{
            borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
            borderTop: '1px solid rgba(255, 255, 255, 0.2)',
            borderRadius: '0',
          }}
        >
          <ListItemText
            primary="Email"
            sx={{ color: 'white' }} // Set text color to white
          />
          <ListItemIcon sx={{ color: 'white' }}>
            <ChevronRightIcon />
          </ListItemIcon>
        </ListItemButton>
        <ListItemButton
          onClick={handlePasswordDrawerOpen}
          sx={{
            borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
            borderRadius: '0',
          }}
        >
          <ListItemText
            primary="Password"
            sx={{ color: 'white' }} // Set text color to white
          />
          <ListItemIcon sx={{ color: 'white' }}>
            <ChevronRightIcon />
          </ListItemIcon>
        </ListItemButton>
        <ListItemButton
          onClick={handleBackdropOpen}
          sx={{
            borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
            borderRadius: '0',
          }}
        >
          <ListItemText
            primary="Edit Profile"
            sx={{ color: 'white' }} // Set text color to white
          />
          <ListItemIcon sx={{ color: 'white' }}>
            <ChevronRightIcon />
          </ListItemIcon>
        </ListItemButton>
      </List>
      <Link to="/updates" style={{ textDecoration: 'none', color: 'inherit' }}>
      <ListItemButton
        sx={{
          borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
          borderRadius: '0',
        }}
      >
        <ListItemText primary="1.1.2 Update" sx={{ color: 'white' }} />
        <ListItemIcon sx={{ color: 'white' }}>
          <ChevronRightIcon />
        </ListItemIcon>
      </ListItemButton>
    </Link>
      <h3 style={{ fontWeight: 400, paddingLeft: 15 }}>Security and Privacy</h3>
      <List component="nav">
        <ListItemButton
          onClick={handleBlockedUsersClick}
          sx={{
            borderTop: '1px solid rgba(255, 255, 255, 0.2)',
            borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
            borderRadius: '0',
          }}
        >
          <ListItemText
            primary="Blocked Users"
            sx={{ color: 'white' }} // Set text color to white
          />
          <ListItemIcon sx={{ color: 'white' }}>
            <ChevronRightIcon />
          </ListItemIcon>
        </ListItemButton>
        <ListItemButton primary="Update My Location">
          <UpdateLocation />
        </ListItemButton>
      </List>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={handleDrawerClose}
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: 457,
            background: 'rgb(0, 0, 0)',
            background: 'radial-gradient(circle, rgba(0, 0, 0, 1) 31%, rgba(27, 25, 60, 1) 100%)',
          },
        }}
      >
        <BlockedUsersList myId={myId} onClose={handleDrawerClose} />
      </Drawer>
      <Drawer
        anchor="right"
        open={isEmailDrawerOpen}
        onClose={handleEmailDrawerClose}
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: 457,
            background: 'rgb(20, 26, 31)',
            background: 'radial-gradient(circle, rgba(0, 0, 0, 1) 31%, rgba(27, 25, 60, 1) 100%)',
          },
        }}
      >
        <ChangeEmailForm onClose={handleEmailDrawerClose} onSubmit={handleChangeEmail} />
      </Drawer>

      <Drawer
        anchor="right"
        open={isPasswordDrawerOpen}
        onClose={handlePasswordDrawerClose}
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: 457,
            background: 'rgb(0, 0, 0)',
            background: 'radial-gradient(circle, rgba(0, 0, 0, 1) 31%, rgba(27, 25, 60, 1) 100%)',
          },
        }}
      >
        <ChangePasswordForm onClose={handlePasswordDrawerClose} onSubmit={handleChangePassword} />
      </Drawer>
      <DeleteAccount myId={myId} />
      
      <List sx={{ borderTop: '1px solid rgba(255,255,255,0.2)', mt: 2 }} component="nav">
        <ListItemButton sx={{ color: 'white' }} to="/" onClick={logout}>
          <ListItemIcon>
            <LogoutIcon style={{ color: "#fff" }} />
          </ListItemIcon>
          <ListItemText primary="Log Out" />
        </ListItemButton>
      </List>
      <Backdrop
        style={{ backgroundColor: 'rgba(0,0,0,0.8)' }}
        className='backdrop'
        open={backdropOpen}
        onClick={handleBackdropClose}
      >
        <Container maxWidth="md" style={{ maxWidth: '100%' }}>
          <EditProfile handleBackdropClose={handleBackdropClose} />
        </Container>
      </Backdrop>
    </div>
  );
};

export default Settings;
