import React, { useState, useRef } from "react";
import { useMutation } from "@apollo/client";
import { LOGIN_USER } from "../../utils/mutations";
import auth from "../../utils/auth";
import { TextField, Button, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import ReCAPTCHA from "react-google-recaptcha";
import './styles.css';

const Login = (props) => {
  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: '#8e0b8a',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: purple[700],
    },
  }));
  const [formState, setFormState] = useState({ identifier: "", password: "" });
  const [login, { error }] = useMutation(LOGIN_USER);
  // Declare the recaptchaRef reference
  const recaptchaRef = useRef();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const recaptchaToken = recaptchaRef.current.getValue();
      const variables = {
        ...formState,
        recaptchaToken: recaptchaToken,
      };
      const { data } = await login({
        variables: variables,
      });
      auth.login(data.login.token);
    } catch (e) {
      console.error("Error details:", e);
    }
  };

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <TextField
          fullWidth
          className="form-input"
          label="Your email or username"
          name="identifier"
          id="identifier"
          value={formState.identifier}
          onChange={handleChange}
          style={{ marginTop: '5px', marginBottom: '5px', color: "white" }}
          InputLabelProps={{
            style: { color: 'white' }
          }}
          InputProps={{
            style: { color: 'white' } // Set typed text color to white
          }}
        />
        <TextField
          fullWidth
          className="form-input"
          label="******"
          name="password"
          type="password"
          id="password"
          value={formState.password}
          onChange={handleChange}
          style={{ marginTop: '5px', marginBottom: '5px' }}
          InputLabelProps={{
            style: { color: 'white' }
          }}
          InputProps={{
            style: { color: 'white' } // Set typed text color to white
          }}
        />
        {error && <Typography color="error">Login failed</Typography>}
        <div className="recaptcha-holder">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LfWD7AmAAAAAK7FU0hL3SfSY3NDmctNc50yn0zX"
          />
          {/* this is for mobile i guess */}
          {/* <ReCAPTCHA
            ref={recaptchaRef} // Pass the recaptchaRef to the ReCAPTCHA component
            sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" //test key
          //real key "6LfWD7AmAAAAAK7FU0hL3SfSY3NDmctNc50yn0zX"
          /> */}
        </div>
        <ColorButton sx={{ mb: 2, mt: 1, pt: 2, pb: 2, width: '100%', borderRadius: '20px' }} type="submit" variant="contained">Login</ColorButton>
      </form>
      
    </>
  );
};

export default Login;
