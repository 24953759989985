import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';

const Kinks = {
  ALL: "",
  BIMBOFICATION: "BIMBOFICATION",
  BDSM: "BDSM",
  BONDAGE: "BONDAGE",
  BREEDING: "BREEDING",
  CHASTITY: "CHASTITY",
  DOMINATION: "DOMINATION",
  FEET: "FEET",
  FEMINIZATION: "FEMINIZATION",
  GROUP: "GROUP",
  GIRLFRIENDEXPERIENCE: "GIRLFRIENDEXPERIENCE",
  HUMILIATION: "HUMILIATION",
  HYPNO: "HYPNO",
  LATEX: "LATEX",
  LINGERIE: "LINGERIE",
  MAID: "MAID",
  RAW: "RAW",
  RECORDING: "RECORDING",
  SISSIFICATION: "SISSIFICATION",
  SISSYBRIDE: "SISSYBRIDE",
  SHOWOFF: "SHOWOFF",
  VERBAL: "VERBAL",
};

const KinksSelection = ({ selectedKink, onKinkSelect }) => {
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [valueGroups, setValueGroups] = useState({ kink: selectedKink || 'ALL' });
  const [optionGroups] = useState({ kink: Object.values(Kinks) });

  useEffect(() => {
    if (valueGroups.kink !== 'ALL') {
      setIsPickerOpen(false);
    }
  }, [valueGroups.kink]);

  const handleChange = (name, value) => {
    setValueGroups({ ...valueGroups, [name]: value });
    onKinkSelect(value === 'ALL' ? '' : value);
    setIsPickerOpen(false); // Close the list after selection
  };

  const handleBoxClick = () => {
    setIsPickerOpen(!isPickerOpen); // Toggle the list visibility
  };

  const handleClearClick = (event) => {
    event.stopPropagation();
    setValueGroups({ kink: 'ALL' });
    onKinkSelect(null);
    setIsPickerOpen(false);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#8e0b8a",
          color: "white",
          padding: "4px",
          borderRadius: "4px",
          marginBottom: "10px",
          fontSize: "14px",
        }}
      >
        Kink
      </div>
      <div onClick={handleBoxClick}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            bgcolor: "#2e84b4",
            color: "white",
            padding: "8px",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          <span>They are into: {valueGroups.kink !== 'ALL' ? valueGroups.kink : ''}</span>
          {valueGroups.kink !== 'ALL' && (
            <ClearIcon
              style={{ marginLeft: "5px", cursor: "pointer" }}
              onClick={handleClearClick}
            />
          )}
        </Box>
      </div>
      {isPickerOpen && (
        <div
          style={{
            overflow: "auto",
            maxHeight: "200px",
            marginTop: "10px",
            textAlign: "center",
          }}
        >
          <ul style={{ listStyle: "none", padding: "0" }}>
            {optionGroups.kink.map((option) => (
              <li
                key={option}
                onClick={() => handleChange("kink", option)}
                style={{
                  cursor: "pointer",
                  backgroundColor: valueGroups.kink === option ? "#eee" : "transparent",
                  fontSize: "12px",
                  lineHeight: "1.5",
                  padding: "4px",
                  borderRadius: "4px",
                  marginBottom: "4px",
                }}
              >
                {option}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default KinksSelection;