import React, { useRef, useEffect, useState } from 'react';
import { Box, Chip, IconButton, Typography } from '@mui/material';
import { kinkEmojis } from './kinks';

const UserKinks = ({ user }) => {
  const scrollContainerRef = useRef(null);
  const [showFullDescription, setShowFullDescription] = useState(false);

  useEffect(() => {
    const container = scrollContainerRef.current;
    container.scrollLeft = container.scrollWidth; // Set scroll position to the maximum value
  }, []);

  return (
    <Box sx={{ textAlign: 'left', px: 2, pb: 2, pt: 1 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <div>
          <Typography variant='subtitle1' component="h6" sx={{ fontFamily: '"Roboto Condensed", sans-serif', fontSize: '1rem', mb: 1 }}>Kinks </Typography>
        </div>
        <div
          ref={scrollContainerRef}
          style={{ position: 'relative', display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center', flexWrap: 'wrap', overflow: 'hidden' }}
        >
          {user?.kinks?.map((kink) => (
            <Chip
              key={kink}
              size="small"
              label={<span style={{ color: 'white' }}>{kinkEmojis[kink]} {kink}</span>}
              style={{ width: 'auto', backgroundColor: '#04567a' }}
              sx={{ fontSize: 10, mb: 0.5 }}
            />
          ))}
        </div>
       
      </Box>
    </Box>
  );
};

export default UserKinks;
