import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';

const Positions = {
  ALL: "",
  TOP: "Top",
  VERSTOP: "Vers Top",
  VERS: "Vers",
  VERSBOTTOM: "Vers Bottom",
  BOTTOM: "Bottom",
  ORAL_ONLY: "Oral Only",
};

const PositionSelection = ({ selectedPosition, onPositionSelect }) => {
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [valueGroups, setValueGroups] = useState({ position: selectedPosition || 'ALL' });
  const [optionGroups] = useState({ position: Object.values(Positions) });

  useEffect(() => {
    if (valueGroups.position !== 'ALL') {
      setIsPickerOpen(false);
    }
  }, [valueGroups.position]);

  const handleChange = (name, value) => {
    setValueGroups({ ...valueGroups, [name]: value });
    onPositionSelect(value === 'ALL' ? '' : value);
    setIsPickerOpen(false); // Close the list after selection
  };

  const handleBoxClick = () => {
    setIsPickerOpen(!isPickerOpen); // Toggle the list visibility
  };

  const handleClearClick = (event) => {
    event.stopPropagation();
    setValueGroups({ position: 'ALL' });
    onPositionSelect('');
    setIsPickerOpen(false);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#8e0b8a",
          color: "white",
          padding: "4px",
          borderRadius: "4px",
          marginBottom: "10px",
          fontSize: "14px",
        }}
      >
        Position
      </div>
      <div onClick={handleBoxClick}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            bgcolor: "#2e84b4",
            color: "white",
            padding: "8px",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          <span>They are: {valueGroups.position !== 'ALL' ? valueGroups.position : ''}</span>
          {valueGroups.position !== 'ALL' && (
            <ClearIcon
              style={{ marginLeft: "5px", cursor: "pointer" }}
              onClick={handleClearClick}
            />
          )}
        </Box>
      </div>
      {isPickerOpen && (
        <div
          style={{
            overflow: "auto",
            maxHeight: "200px",
            marginTop: "10px",
          }}
        >
          <ul style={{ listStyle: "none", padding: "0", textAlign: "center" }}>
            {optionGroups.position.map((option) => (
              <li
                key={option}
                onClick={() => handleChange("position", option)}
                style={{
                  cursor: "pointer",
                  backgroundColor: valueGroups.position === option ? "#eee" : "transparent",
                  fontSize: "15px",
                  lineHeight: "1.5",
                  padding: "4px",
                  borderRadius: "4px",
                  marginBottom: "4px",
                }}
              >
                {option}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default PositionSelection;