import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';


const Support = () => {
  return (
    <Box
      sx={{
        padding: '20px',
        background: 'rgba(0, 0, 0, 0.87)',
        color: 'white',
        minHeight: '100vh',
      }}
    >
      <Container maxWidth="lg" sx={{ py: 10 }}>
        <Button
          variant="outlined"
          onClick={() => window.history.back()}
          sx={{ color: 'white', borderColor: 'white', marginBottom: '20px' }}
        >
          Back
        </Button>
        <Typography component="h2" variant="h3" sx={{ fontWeight: 'bold', mb: 2 }}>
          Support
        </Typography>
        <Typography variant="h4" sx={{ mt: 4 }}>
          Why Reach Out for Support?
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          You can reach out to our support team for various reasons, including:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">
              Questions or issues with your account
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Reporting inappropriate content or behavior
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Technical problems with the website or app
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Assistance with using our features
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Any other concerns or feedback
            </Typography>
          </li>
        </ul>
        <Typography variant="h4" sx={{ mt: 4 }}>
          Contact Us
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          If you need assistance or have any questions, please don't hesitate to contact us at{' '}
          <a href="mailto:Admin@Femswipe.com" style={{ color: 'white', textDecoration: 'underline' }}>
            Admin@Femswipe.com
          </a>
          . Our dedicated support team is here to help you with any inquiries or issues you may have.
        </Typography>
        <Typography variant="h4" sx={{ mt: 4 }}>
          Donate
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          If you'd like to support our mission, you can donate using the following methods:
        </Typography>
        <Link to="/donate" style={{ textDecoration: 'none' }}>
          <Button variant="contained" color="primary" sx={{ mt: 2 }}>
            Donate Now
          </Button>
        </Link>
      </Container>
    </Box>
  );
};

export default Support;
