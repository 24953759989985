import React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { List } from '@mui/material';

function CustomDrawer({ open, onClose, children, maxWidth }) {
  const handleDrawerClose = () => {
    onClose();
  };

  return (
    <Drawer PaperProps={{ sx: { width: '100%', maxWidth: maxWidth || 457} }} anchor="right" open={open} onClose={handleDrawerClose}>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          background: '#222222',
          '& .MuiDrawer-paper': {
            width: '350px',
            '@media (max-width: 497px)': {
              width: '100%',
            },
          },
        }}
      >
        <List
          sx={{
            borderTop: '1px solid rgba(255, 255, 255, 0.2)',
            borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
            background: '#222222'
          }}
        >
          {children}
        </List>
      </Box>
    </Drawer>
  );
}

export default CustomDrawer;
