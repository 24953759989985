import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { CHANGE_PASSWORD } from "../../utils/mutations";
import { Alert, Button, IconButton, List, ListItem, TextField, Stack, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ChangePasswordForm = ({ onClose }) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState(""); // State for success message

  const [changePasswordMutation] = useMutation(CHANGE_PASSWORD);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!currentPassword || !newPassword || !confirmPassword) {
      setError("All fields are required");
      return;
    }
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      await changePasswordMutation({
        variables: {
          currentPassword,
          newPassword,
        },
      });

      // Handle success, update your UI, and show success message
      setSuccessMessage("Password changed successfully!");
    } catch (error) {
      setError("Failed to change password. Please check your current password.");
    }

    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  return (
    <form onSubmit={handleSubmit}>
      <div style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid rgba(255,255,255,0.2)', paddingBottom: '0.5em' }}>
        <IconButton color="white" onClick={onClose}>
          <ArrowBackIcon sx={{ color: 'white' }} />
        </IconButton>
        <h3 style={{ paddingLeft: 10, fontWeight: 400 }}>Change Password</h3>
      </div>
      {error && (
        <List sx={{pb: 0, mb: 0}}>
          <ListItem>
            <Stack>
              <Alert sx={{ color: 'rgb(204, 232, 205)' }} variant="outlined" severity="error">
                {error}
              </Alert>
            </Stack>
          </ListItem>
        </List>
      )}
      <List>
        <ListItem>
          {successMessage && (
            <Stack sx={{ width: '100%' }}>
              <Alert sx={{ color: 'rgb(204, 232, 205)' }} variant="outlined" severity="success">
                {successMessage}
              </Alert>
            </Stack>
          )}
        </ListItem>
        {!successMessage && (
          <>
            <ListItem>
              <TextField
                sx={{ mt: 0 }}
                className="form-input"
                label="Current Password"
                type="password"
                fullWidth
                margin="normal"
                variant="outlined"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
            </ListItem>
            <ListItem>
              <TextField
                sx={{ mt: 0 }}
                className="form-input"
                label="New Password"
                type="password"
                fullWidth
                margin="normal"
                variant="outlined"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </ListItem>

            <ListItem sx={{ pt: 0 }}>
              <TextField
                sx={{ mt: 0 }}
                className="form-input"
                label="Confirm New Password"
                type="password"
                fullWidth
                margin="normal"
                variant="outlined"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </ListItem>
            <ListItem sx={{ pt: 0 }}>
              <Button type="submit" variant="contained" color="secondary">
                Change Password
              </Button>
            </ListItem>
          </>
        )}
      </List>
    </form>
  );
};

export default ChangePasswordForm;
