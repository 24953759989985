import React from "react";

const WelcomeMessage = () => {
  return (
    <div style={{ fontSize: "12px", color: "white" /* Set the alert color */ }}>
      <div>FemSwipe Beta 1.1.2 Update Highlights:</div>
      <div>Updated 01/02/2024</div>
      <br></br>
      <li style={{ fontSize: "12px" }}>
        <strong>Location Update:</strong> Resolved issues where clicking "Update Location" in Edit Profile did not update the location.
      </li>
      <li style={{ fontSize: "12px" }}>
        <strong>Profile Touch Opening:</strong> Enhanced performance for opening profiles during scrolling, ensuring a smoother experience.
      </li>
      <li style={{ fontSize: "12px" }}>
        <strong>Message non-swiped users:</strong> Directly message users by clicking on their profile. See and receive messages from new contacts.
      </li>
      <li style={{ fontSize: "12px" }}>
        <strong>New User Profile Drawer:</strong> Open concise user profiles with options to "Favorite," "Dislike," view photos, and start a direct chat.
      </li>
      <li style={{ fontSize: "12px" }}>
        <strong>Matches as Swipes:</strong> Matches are now presented as swipes in the inbox, allowing direct interaction before reciprocation.
      </li>
      <li style={{ fontSize: "12px" }}>
        <strong>Filtering:</strong> Easily filter the main dashboard to see favorites, profiles with photos, and more.
      </li>
      <li style={{ fontSize: "12px" }}>
        <strong>Roulette Grid and List View:</strong> Improved user experience in the Roulette tab with grid and list view options.
      </li>
      <li style={{ fontSize: "12px" }}>
        Introducing "Grid View" and "List View" display options for flexible match viewing.
      </li>
      <p style={{ fontSize: "12px" }}>
        Don't forget to update your age and location in the "Edit Profile" section.
        If the location is not included, your coordinates will be shown as somewhere in Antarctica.
      </p>
    </div>
  );
};

export default WelcomeMessage;
