import React, { useState, useEffect, useRef } from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Paper } from '@mui/material';
import ExtensionIcon from '@mui/icons-material/Extension';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'transparent',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: 'white',
  width: '100%',
}));

const RowContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  justifyContent: 'center',
});

const CenteredGrid = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
});

const ChipWrapper = styled('div')(({ theme }) => ({
  backgroundColor: '#005b80',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: 'white',
  cursor: 'pointer',
  height: '5vh',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '16px',
}));

const ListWrapper = styled('div')({
  position: 'absolute',
  top: '100%',
  left: 0,
  // backgroundColor: '#fff',
  padding: '8px',
  paddingTop: '0px',
  zIndex: 2,
  width: '100%',
  maxHeight: '200px',
  overflow: 'auto',
  boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
});

const ListItem = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  cursor: 'pointer',
  color: 'black',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
}));

const Ethnicities = [
  "White",
  "Black",
  "Pacific Islander",
  "Indian",
  "Asian",
  "Latin/Hispanic",
  "American Indian",
  "Other",
];

const Ethnicity = ({ formState, handleChange }) => {
  const [isListOpen, setIsListOpen] = useState(false);
  const [selectedEthnicity, setSelectedEthnicity] = useState(formState.ethnicity || '');
  const listRef = useRef(null);
  const chipRef = useRef(null);

  const handleOpenList = () => {
    setIsListOpen(!isListOpen);
  };

  const handleCloseList = () => {
    setIsListOpen(false);
  };

  const handleSelectChange = (value) => {
    setSelectedEthnicity(value);
    handleChange({ target: { name: 'ethnicity', value: value } });
    handleCloseList();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (listRef.current && !listRef.current.contains(event.target)) {
        // Check if the click target is not the ChipWrapper itself
        if (chipRef.current && !chipRef.current.contains(event.target)) {
          setIsListOpen(false);
        }
      }
    };

    if (isListOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isListOpen]);

  return (
    <CenteredGrid style={{ width: '100%' }} item xs={12}>
      <Item elevation={0}>
        <RowContainer>
          <ChipWrapper onClick={handleOpenList} ref={chipRef}>
            <ExtensionIcon style={{ color: 'white', marginRight: '8px' }} />
            Ethnicity: {selectedEthnicity || ''}
          </ChipWrapper>
        </RowContainer>
      </Item>
      {isListOpen && (
        <ListWrapper ref={listRef}>
          <div style={{ background: 'white', borderRadius: '10px' }}>
            <h6 style={{ textAlign: 'center', color: 'black' }}>Select an ethnicity:</h6>
            {Ethnicities.map((ethnicity) => (
              <ListItem key={ethnicity} onClick={() => handleSelectChange(ethnicity)}>
                {ethnicity}
              </ListItem>
            ))}
          </div>
        </ListWrapper>
      )}
    </CenteredGrid>
  );
};

export default Ethnicity;
