// LoginPage.js
import React, { useState } from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import Logo from "../../components/Logo";
import Login from "../../components/Login";
import Signup from "../../components/Signup";
import ForgotPasswordForm from "../../components/ForgotPasswordForm";
import { useLocation } from "react-router-dom"; // Import useLocation
import Analytics from "../../components/Analytics";
import "./styles.css";

const LoginPage = () => {
  const location = useLocation(); // Get the current location
  const queryParams = new URLSearchParams(location.search);
  const showSignupParam = queryParams.get("signup") === "true"; // Read the 'signup' query parameter

  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showLogin, setShowLogin] = useState("hero");
  const [showSignup, setShowSignup] = useState(showSignupParam); // Use the query parameter to set initial state
  const toggleLogin = (value, showSignup) => {
    setShowLogin(value);
    setShowSignup(showSignup);
  };

  const handleToggle = () => {
    setShowForgotPassword(false);
    toggleLogin("login", !showSignup);
  };

  const handleForgotPasswordToggle = () => {
    setShowForgotPassword(!showForgotPassword);
    toggleLogin('login');
  };

  return (
    <div className="login-bg" style={{ position: "relative", height: "100vh" }}>
      <Analytics />
      <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
        <Grid container justifyContent="center" alignItems="center" height="100%">
          <Grid item>
            <div style={{ borderRadius: "8px", position: "relative" }}>
              <Card className="login-content" variant="outlined">
                <CardContent>
                  {!showSignup || showForgotPassword ? <Logo /> : null}
                  {!showForgotPassword && (showSignup ? <Signup /> : <Login />)}
                  {showForgotPassword && <ForgotPasswordForm />}
                 
                    <div className="login-link">
                      <Typography variant="p" align="center" style={{ color: "white", fontSize: "1rem", textAlign: "center", width: "100%", display: "block" }}>
                        {!showForgotPassword ? (
                          <span style={{ cursor: "pointer" }} onClick={handleToggle}>
                            {showSignup ? "Login" : "Sign Up"}
                          </span>
                        ) : (
                          <span style={{ cursor: "pointer" }} onClick={handleForgotPasswordToggle}>
                            Login
                          </span>
                        )}
                        &nbsp; | &nbsp;
                        <span style={{ color: "white", fontSize: "1rem", cursor: "pointer" }} onClick={handleForgotPasswordToggle}>
                          Forgot Password?
                        </span>
                      </Typography>
                      <Typography variant="p" align="center" style={{ color: "white", display: "block" }}></Typography>
                    </div>
               
                </CardContent>
              </Card>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default LoginPage;
