import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { REQUEST_RESET_PASSWORD } from '../../utils/mutations';
import { TextField, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: '#8e0b8a',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: purple[700],
  },
}));

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const [requestResetPassword] = useMutation(REQUEST_RESET_PASSWORD);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data } = await requestResetPassword({ variables: { email } });
      setMessage(data.requestResetPassword);
    } catch (error) {
      console.error(error);
      setMessage('We couldn\'t find that account');
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          className="form-input"
          label="Enter Your Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{ marginTop: '5px', marginBottom: '5px' }}
          InputLabelProps={{
            style: { color: 'rgba(255,255,255,0.8)' },
          }}
        />
        {message && <Typography>{message}</Typography>}
        <ColorButton
          sx={{
            mb: 2,
            mt: 1,
            pt: 2,
            pb: 2,
            width: '100%',
            borderRadius: '20px',
          }}
          type="submit"
          variant="contained"
        >
          Request Password Reset
        </ColorButton>
      </form>
      
    </>
  );
};

export default ForgotPasswordForm;
