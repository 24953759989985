import React, { useEffect, useState } from 'react';
import { Alert, AppBar, Avatar, Badge, Box, Fade, IconButton, Snackbar, Tooltip } from '@mui/material';
import { useSubscription, useQuery, useApolloClient } from '@apollo/client';
import { FRIEND_REQUEST_SENT_SUBSCRIPTION, MESSAGE_NOTIFICATION } from "../../utils/subscriptions";
import { GET_UNREAD_MESSAGE_COUNT } from "../../utils/queries";
import { AccountCircle, RecentActors, Woman, Man, AddAPhoto, Chat, Close, Settings } from '@mui/icons-material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './SecondaryNav.css'

function SecondaryNav({ avatar, onProfileButtonClick, onSettingsButtonClick, id, username, requests, role, onCloseChat }) {
  const location = useLocation();
  const [newFriendRequest, setNewFriendRequest] = useState(false);
  const [newMessage, setNewMessage] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const navigate = useNavigate(); // Use useNavigate for navigation
  const apolloClient = useApolloClient();
  const showDot = newFriendRequest || requests?.length > 0;
  const isAvatarEmpty = avatar?.every((str) => str === "");
  const [open, setOpen] = useState(isAvatarEmpty)
  

  const { data: friendRequestData } = useSubscription(
    FRIEND_REQUEST_SENT_SUBSCRIPTION,
    { variables: { userId: id } }
  );

  const { data: messageData } = useSubscription(
    MESSAGE_NOTIFICATION,
    { variables: { userId: id } }
  );

  const { data: messageCount } = useQuery(
    GET_UNREAD_MESSAGE_COUNT, {
    variables: { username: username }
  }
  );

  useEffect(() => {
    if (friendRequestData) {
      setNewFriendRequest(true);
      setOpenSnackbar(true);
      console.log(friendRequestData);
    }
  }, []);

  useEffect(() => {
    if (messageData) {
      setNewMessage(true);
      // setOpenSnackbar(true);
      setUnreadMessageCount(prevCount => prevCount + 1);
    }
  }, [messageData]);

  useEffect(() => {
    if (messageCount) {
      setUnreadMessageCount(messageCount.getUnreadMessageCount);
    }
  }, [messageCount]);

  

  // Handle navigation back to homepage
  const handleGoBackToHomepage = () => {
    navigate('/dashboard'); // Use navigate for navigation
    // Force a refetch of the message count query
    apolloClient.refetchQueries({
      include: ['getUnreadMessageCount'], // Name of the query to refetch
    });
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
    setNewFriendRequest(false);
  };


  const message = (
    <Link
      to="/inbox/1"
      onClick={handleCloseSnackbar}
      style={{ color: '#fff', textDecoration: 'none', display: 'flex', alignItems: 'center' }}
    >
      {`${friendRequestData?.friendRequestSent?.senderUsername} swiped you! Swipe back!`}
    </Link>
  );

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        sx={{ width: '5%' }}
        onClick={handleCloseSnackbar}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: 999,
        background: '#161625',
        top: 'auto',
        bottom: 0,
        left: 0,
        right: 0,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-start',
        '@media (min-width: 767px)': {
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: 'auto',
          right: 'auto',
          height: '100vh',
        },
      }}
    >
      <Snackbar
        message={message}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
      <Tooltip placement="right-start" title="Swipe Profiles">
        <IconButton onClick={handleGoBackToHomepage} sx={{ px: 3, py: 2 }} color={location.pathname === '/dashboard' ? 'primary' : 'inherit'} component={Link} to="/dashboard">
          <RecentActors  />
        </IconButton>
      </Tooltip>
      <Tooltip placement="right-start" title="Inbox">
        <IconButton sx={{ px: 3, py: 2 }} color={location.pathname === '/inbox' ? 'primary' : 'inherit'} component={Link} to="/inbox"   onClick={onCloseChat} // Add onClick to call onCloseBackdrop
>
          <Badge badgeContent={unreadMessageCount} color="error">
            <Badge invisible={!showDot} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} variant='dot' color="error">
              <Chat />
            </Badge>
          </Badge>
        </IconButton>
      </Tooltip>
      <Tooltip placement="right-start" title={isAvatarEmpty ? 'Click here to upload a photo' : 'Profile'}>
        <Box sx={{ position: 'relative' }}>
          <Box sx={{
            position: 'absolute',
            top: -80,
            left: '-42%',
            width: '252%',
            transform: 'translateX(-20%)',
            '@media (min-width: 767px)': {
              right: -250,
              top: 5,
              left: 'initial',
            }
          }}
          >
            <Fade in={open}>
              <Alert
                icon={<AddAPhoto fontSize="inherit" />}
                severity="warning"
                open={open}
                sx={{
                  width: '100%'
                }}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <Close fontSize="inherit" />
                  </IconButton>
                }
              >
                <span className="alert-triangle"></span>
                Upload a profile photo
              </Alert>
            </Fade>
          </Box>
          <IconButton sx={{ px: 3, py: 2, position: 'relative' }} color="inherit" onClick={onProfileButtonClick}>
            {avatar ? (
              <Avatar className={isAvatarEmpty && open ? 'pulse' : ''} sx={{ background: role === 'admirer' ? '#005b80' : '#9c27b0', width: 25, height: 25 }} src={avatar[0]}>
                {role === 'admirer' ? <Man sx={{ color: '#1785b2', mt: 4, fontSize: '50px' }} /> : <Woman sx={{ color: '#bd59ce', mt: 4, fontSize: '50px' }} />}
              </Avatar>
            ) : (
              <AccountCircle />
            )}
          </IconButton>
        </Box>
      </Tooltip>
      <Tooltip placement="right-start" title="Settings">
        <IconButton sx={{ px: 3, py: 2 }} color="inherit" onClick={onSettingsButtonClick}>
          <Settings />
        </IconButton>
      </Tooltip>
    </AppBar>
  );
}

export default SecondaryNav;
