import React, { useState, useEffect } from 'react';
import { storage } from '../../firebase';
import { ref, uploadBytes, listAll, getDownloadURL } from 'firebase/storage';
import { QUERY_ME } from '../../utils/queries';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { EDIT_USER } from '../../utils/mutations';
import { useMutation } from '@apollo/client';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Compressor from 'compressorjs';
import { v4 as uuidv4 } from 'uuid';

const FileUpload = ({ index , provideURL, setPhotoURL}) => {
  // eslint-disable-next-line
  const [imageUpload, setImageUpload] = useState(null);
  // eslint-disable-next-line
  const [currentImage, setCurrentImage] = useState(null);

  const { username: userParam } = useParams();
  const { loading, client } = useQuery(QUERY_ME, {
    variables: { username: userParam },
  });
  const imageListRef = ref(storage, 'images/');

  const [editUser] = useMutation(EDIT_USER, {
    onCompleted: (data) => {
      console.log(data);
    },
  });

  const handleChange = async (event) => {
    setImageUpload(event.target.files[0]);
    if (event.target.files[0]) {
      console.log('File selected and handleChange function triggered!');

      try {
        const file = event.target.files[0];
        console.log('Original image size:', file.size);
        const compressedFile = await new Promise((resolve) => {
          new Compressor(file, {
            quality: 0.6,
            maxWidth: 800,
            maxHeight: 600,
            success: (result) => {
              resolve(result);
            },
            error: (error) => {
              console.error(error);
            },
          });
        });
        console.log('Compressed image size:', compressedFile.size);
        const blob = new Blob([compressedFile], { type: compressedFile.type });
        const imageBase64 = await toBase64(blob);
        const response = await analyzeImage(imageBase64);
        const { safeSearchAnnotation } = response[0];
        const { adult, violence, medical, spoof } = safeSearchAnnotation;
        const isSafe =
          adult !== 'VERY_LIKELY' &&
          violence !== 'LIKELY' &&
          violence !== 'VERY_LIKELY' &&
          medical !== 'LIKELY' &&
          medical !== 'VERY_LIKELY' &&
          spoof !== 'LIKELY' &&
          spoof !== 'VERY_LIKELY';
        console.log('isSafe:', isSafe);

        if (!isSafe) {
          alert('The uploaded image is not safe for work. Please upload a different image.');
          return;
        }

        const imageRef = ref(storage, `images/${userParam}/${uuidv4()}`);
        const upload = await uploadBytes(imageRef, blob);
        const getURL = await getDownloadURL(upload.ref);
        setCurrentImage(getURL);
        setPhotoURL(getURL);
        provideURL(getURL);

        let currentPhotoURLs;
        try {
          const { data } = await client.query({
            query: QUERY_ME,
          });
          currentPhotoURLs = data.me.photoURL;
        } catch {
          currentPhotoURLs = ["", "", "", ""];
        }
        const updatedPhotoURLs = [...currentPhotoURLs];
        updatedPhotoURLs[index] = getURL;
        await editUser({
          variables: { photoURL: updatedPhotoURLs },
        });

      } catch (error) {
        console.error(error);
      }
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      if (!(file instanceof Blob)) {
        console.error("Parameter is not a Blob");
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const analyzeImage = async (base64Image) => {
    const apiKey = 'AIzaSyDlIIEq2P1pw0EwR_2Q2xa1N19EGXPgXRs';
    const apiEndpoint = `https://vision.googleapis.com/v1/images:annotate?key=${apiKey}`;
    const requestBody = {
      requests: [
        {
          image: {
            content: base64Image.replace(/^data:image\/(png|jpg|jpeg);base64,/, ''),
          },
          features: [
            {
              type: 'SAFE_SEARCH_DETECTION',
            },
          ],
        },
      ],
    };
    try {
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();
      console.log(data);
      if (response.ok) {
        return data.responses;
      } else {
        console.error(`Google Vision API returned status code ${response.status}:`, data.error);
        return null;
      }
    } catch (error) {
      console.error('Error calling Google Vision API', error);
      return null;
    }
  };

  useEffect(() => {
    listAll(imageListRef).then((response) => {
      response.items.forEach((item) => {
        getDownloadURL(item).then((url) => { });
      });
    });
  }, [imageListRef]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // height: '5vh',
        marginLeft: 'auto',
      }}
    >
      <IconButton sx={{ background: 'rgba(0,0,0,0.5)' }} color='primary' aria-label='upload picture' component='label'>
        <input
          hidden
          accept='image/*'
          id={`photoURL${index}`}
          type='file'
          onChange={handleChange}
        />
        <PhotoCamera />
      </IconButton>
    </div>
  );
}

export default FileUpload;
