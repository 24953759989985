import React from "react";
import femswipeLogo from '../../images/femswipe-logo.svg';
import './styles.css';

const Logo = () => {
  return (
    <>
      <img className="logo" alt='FemSwipe' src={femswipeLogo} />
      <h1 className="typography">
        FEMSWIPE <sup style={{fontSize: '0.5em'}}>BETA</sup>
      </h1>
    </>
  )
}

export default Logo