import React from 'react';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';


function Donation() {

    const goFundMeLink = "https://www.gofundme.com/f/w9xbvq-femswipe-covering-server-cost?utm_source=customer&utm_medium=copy_link&utm_campaign=p_lico+share-sheet";


    
    return (
        <Container
            maxWidth="sm"
            style={{
                minHeight: '100vh',
                background: 'rgba(0, 0, 0, 0.87)',
                color: 'white',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '20px',
            }}
        >
            <Button
                variant="outlined"
                onClick={() => window.history.back()}
                style={{ color: 'white', borderColor: 'white', marginBottom: '20px' }}
            >
                Back
            </Button>
            <div>
                <p style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px', textAlign: 'center' }}>
                Donate to cover server cost and development using GoFundMe
                </p>
            </div>
            <div>
            <Button
                variant="contained"
                color="primary"
                onClick={() => window.open(goFundMeLink, '_blank')}
            >
                Donate
            </Button>
        </div>
        
        </Container>
    );
}

export default Donation;
