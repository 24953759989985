import React, { useState, useEffect, useRef } from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Paper } from '@mui/material';
import TransgenderIcon from '@mui/icons-material/Transgender';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'transparent',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: 'white',
  width: '100%',
}));

const RowContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  justifyContent: 'center',
});

const CenteredGrid = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
});

const ChipWrapper = styled('div')(({ theme }) => ({
  backgroundColor: '#005b80',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: 'white',
  cursor: 'pointer',
  height: '5vh',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '16px',
}));

const ListWrapper = styled('div')({
  position: 'absolute',
  top: '100%',
  left: 0,
  padding: '8px',
  paddingTop: '0px',
  // backgroundColor: '#fff',
  zIndex: 2,
  width: '100%',
  maxHeight: '200px',
  overflow: 'auto',
  boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
});

const ListItem = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  cursor: 'pointer',
  color: 'black',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
}));

const Genders = [
  "Male",
  "Female",
  "Sissy",
  "Crossdresser",
  "Trans Woman",
  "Non Binary"
];

const Gender = ({ formState, handleChange }) => {
  const [isListOpen, setIsListOpen] = useState(false);
  const [selectedGender, setSelectedGender] = useState(formState.gender || '');
  const listRef = useRef(null);
  const chipRef = useRef(null);

  const handleOpenList = () => {
    setIsListOpen(!isListOpen);
  };

  const handleCloseList = () => {
    setIsListOpen(false);
  };

  const handleSelectChange = (value) => {
    setSelectedGender(value);
    handleChange({ target: { name: 'gender', value: value } });
    handleCloseList();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (listRef.current && !listRef.current.contains(event.target)) {
        // Check if the click target is not the ChipWrapper itself
        if (chipRef.current && !chipRef.current.contains(event.target)) {
          setIsListOpen(false);
        }
      }
    };

    if (isListOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isListOpen]);

  return (
    <CenteredGrid style={{ width: '100%' }} item xs={12}>
      <Item elevation={0}>
        <RowContainer>
          <ChipWrapper onClick={handleOpenList} ref={chipRef}>
            <TransgenderIcon style={{ color: 'white', marginRight: '8px' }} />
            Identity: {selectedGender || ''}
          </ChipWrapper>
        </RowContainer>
      </Item>
      {isListOpen && (
        <ListWrapper ref={listRef}>
          <div style={{ background: 'white', borderRadius: '10px' }}>
            <h6 style={{ textAlign: 'center', color: 'black' }}>Select an Identity:</h6>
            {Genders.map((gender) => (
              <ListItem key={gender} onClick={() => handleSelectChange(gender)}>
                {gender}
              </ListItem>
            ))}
          </div>
        </ListWrapper>
      )}
    </CenteredGrid>
  );
};

export default Gender;
