import React, {useState} from 'react';
import { Avatar, Box, IconButton } from "@mui/material";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import UserInfo from '../UserCard/userInfo';
import UserIdentity from '../UserCard/userIdentity';
import UserKinks from '../UserCard/userKinks'
import { ThumbDown, Favorite, Chat, Woman, Man } from '@mui/icons-material';
import { useMutation } from "@apollo/client";
import { ADD_FRIEND, SEND_FRIEND_REQUEST, BLOCK_USER, UNBLOCK_USER, ADD_DISLIKED_USER } from "../../utils/mutations";

const UserProfileCard = (props) => {
  const [isPhotoModalOpen, setIsPhotoModalOpen] = useState(false);
  const [lastDirection, setLastDirection] = useState();
  const [unblockUser] = useMutation(UNBLOCK_USER);
  const [blockUser] = useMutation(BLOCK_USER);
  const [addFriend] = useMutation(ADD_FRIEND);
  const [addDislikedUser] = useMutation(ADD_DISLIKED_USER);
  const [sendFriendRequest] = useMutation(SEND_FRIEND_REQUEST);
  const user = props.user;
  const swiped = async (direction, nameToDelete, id) => {
    props.onSwipe();
    setLastDirection(direction);
    if (direction === "right") {
      await addFriend({ variables: { friendId: id } });
      await sendFriendRequest({ variables: { friendId: id } });
    } else {
      await addDislikedUser({ variables: { dislikedUserId: id } });
    }
  };

  return (
    <Box width="100%" height="115%">
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <DragHandleIcon
          variant="contained"
          style={{
            width: 30,
            height: 30,
            margin: "-5 auto",
          }}
        />
      </Box>
      <Box sx={{ display: 'flex', px: 0, alignItems: 'center', justifyContent: 'space-between' }}>
        <UserInfo user={user} />
        <Avatar src={user.photoURL[0]} sx={{ mr: 2 }}></Avatar>
      </Box>
      <UserKinks user={user} />
      <UserIdentity user={user} setIsPhotoModalOpen={setIsPhotoModalOpen} />
    </Box>
  )
}

export default UserProfileCard;